// https://billing.stripe.com/p/login/6oE4hW06Z9SogiQbII

// import e = require("express");

//import {joinRoom} from 'trystero/nostr'; // (trystero-firebase.min.js)
// import {joinRoom} from 'trystero/nostr'; // (trystero-firebase.min.js)
// import AudioManager from './audioManager.js';
// import lamejs from 'lamejs';


// const trystero_config = { appId: 'qaiz.app' };
let chatroom;
let peerAudios = {}
let soundState = 0;
let selfStream;

const idsToNames = {}

// Predefined colors for each alphabet letter
const letterColors = {
    'A': '#b0aac0', 'B': '#b0c0aa', 'C': '#c0b0aa', 'D': '#aac0b0', 'E': '#aab0c0',
    'F': '#c0aab0', 'G': '#c0a0a0', 'H': '#a0c0a0', 'I': '#a0a0c0', 'J': '#a0c0c0',
    'K': '#c0a0c0', 'L': '#b0a0c0', 'M': '#a0b0c0', 'N': '#c0c0a0', 'O': '#c0b0b0',
    'P': '#b0c0b0', 'Q': '#b0b0c0', 'R': '#b0c0c0', 'S': '#c0b0c0', 'T': '#c0c0b0',
    'U': '#b0c0c0', 'V': '#c0b0b0', 'W': '#b0b0b0', 'X': '#c0c0c0', 'Y': '#b0b0a0',
    'Z': '#a0b0b0'
};

const mp3speedFactor = 1.2; // Adjust this value to change the speed (1.0 is normal speed)
const mp3bitrate = 32;

let useraward;  // THese are kind of bs, just to make it possible to check early if this is set
let userAvatarUrl; // THese are kind of bs, just to make it possible to check early if this is set

let realtime;      // for Ably
let quizChannel;  // for Ably
let awardUrls = [];

let earlyplayer = getCookie('player-name');
let earlyaward = getCookie('player-award');
let earlyurl = getCookie('player-url') === "undefined" ? undefined : getCookie('player-url');
let earlyuid = getCookie('uid') || getCookie('auid');

if (earlyplayer && earlyuid) {
    connectToAbly(earlyplayer, earlyuid);
}


let familyRoomName = getCookie('family-room'); // || "The Lobby";

updateFamilyRoomName();
if (earlyplayer) {
    updateDetails(1, earlyplayer, earlyaward || "First time quizzer", earlyurl);
}

// Set up audio
//const audioManager = new AudioManager();

//const soundFiles = {
//  'join': '/assets/join.mp3',
// Add more sounds as needed
// };

// Load sounds
//. audioManager.loadSounds(soundFiles); //

// Global channel objects
let publicChannel;
let livingRoomChannel;

let inQuiz = 0;
let currentScreen;

let microphone_status = 0;


const originalDivContents = {};
let questions = [];
let conversation = [];
let ideaConversation = [];
let familyHistory = { "meta": { "listId": "family-rooms" } };
let number = 13;
let gameDefaultTotalQuestions = 12;
let gameTotalQuestions = 12;
let roomCode = null;
let current_owner = null;
let current_owner_id = null;
let is_owner = 0;
let is_public = 0;
let compositeUsername;
let quizType;

let creditsBase = 0;
let currentCreditCost = creditsBase;

// variables for file uplaod
let extractedText = ''; // Global variable to store the extracted text
let extractedFilename;
let extractedFiletype;
let extractedTextTokens;
let extractedTextCredits;

// things
let questionShowTime = 14;
let question_id = 1;
let topic = null;
let difficulty = 'normal';
let present = [];
let fetchRetry = 1;
let fetchRetryCount = 4;
let topicIdeas = 12;
let questionTimeout;
let SurveyResultsTable = "";
let storedUserData = [];


let emptyQuiz = {
    correct: 0,
    wrong: 0,
    no_attempt: 0,
    time: 0,
    asked: 0,
    name: null,
    id: null,
};

let hiscore = {};
let oldScores = {};
let currentDetails = [];
let allQuestions = {};
let currentQuiz = { ...emptyQuiz };
let scoreTracker = {};

let currentQuestion = [];
let current_awards = {};
let currentQuestionIndex = 0;
let savedPlayerName;

let elapsedSeconds;
let countdownTimer;


// Store the initial HTML structure of the countdown container
const originalCountdownHTML = document.getElementById('countdown-container').innerHTML;

// Parse the query string
const queryParams = new URLSearchParams(window.location.search);

const url = new URL(window.location.href);
const loadCodeMatch = url.pathname.match(/\/q\/(\w+)/);
const loadCode = (loadCodeMatch && loadCodeMatch[1]) ? loadCodeMatch[1] : url.searchParams.get("quizcode");

const loadChannelMatch = url.pathname.match(/\/p\/(\w\s+)/);
const loadChannel = (loadChannelMatch && loadChannelMatch[1]) ? loadChannelMatch[1] : url.searchParams.get("channel");

//
// Handle ID tracking with Firebase
//

// FirebaseUI config.
var thisUser = {};
let username = "Anonymous Guest";
let is_subscribed;
let is_identified = 0;
let uid;

function loaderOn() {

    document.querySelectorAll('.site-loaded').forEach(function (el) {
        el.style.display = 'none';
    });
    document.querySelectorAll('.site-not-loaded').forEach(function (el) {
        el.style.display = 'block';
    });

    //    document.body.classList.add('loading'); // Add 'loading' class to body to show overlay

}

function loaderOff() {

    document.querySelectorAll('.site-loaded').forEach(function (el) {
        el.style.display = 'block';
    });
    document.querySelectorAll('.site-not-loaded').forEach(function (el) {
        el.style.display = 'none';
    });

    //    document.body.classList.remove('loading'); // Add 'loading' class to body to show overlay

}

function getOrCreateAUID() {
    // Retrieve UID from cookies using the getCookie function
    let uid = getCookie('auid'); // anonymous user id

    setCookie('uid', '', -1); // If we're dealing with auid, it means the uid should be deleted.

    // Check if UID was not found in the cookies
    if (!uid) {
        // Generate a new UID
        uid = Math.floor(Math.random() * 10000000).toString();
        // Store the new UID in cookies for 365 days using the setCookie function
        setCookie('auid', uid, 365);
    }

    return uid;
}

let userLoadedResolve;
const userLoaded = new Promise(resolve => {
    userLoadedResolve = resolve;
});


initApp = function () {
    firebase.auth().onAuthStateChanged(async function (user) {

        document.body.classList.remove('loading');
        
        if (user) {

            console.log(user);
            
            document.getElementById('login-block-modal').style.display = 'none';
            loaderOn();

            thisUser.anonymous = user.providerData.length ? 0 : 1;

            document.getElementById('create-quiz').addEventListener('click', createQuiz);

            // User is signed in.
            username = user.displayName || 'Guest';
            uid = user.uid;
            thisUser.displayName = user.displayName || 'Guest';
            thisUser.email = user.email;
            thisUser.emailVerified = user.emailVerified;
            thisUser.photoURL = user.photoURL;
            thisUser.uid = user.uid;
            thisUser.phoneNumber = user.phoneNumber;
            thisUser.providerData = user.providerData;
            thisUser.credits = 0;

            user.getIdToken().then(function (accessToken) {
                thisUser.accessToken = accessToken;
            });

            // If the user is anonymous, we let them store data in a cookie.
            // If they are logged in, we store data in firestore
            //

            savedPlayerName = thisUser.displayName;
            storedUserData = await getUserData();

            if (!Object.keys(storedUserData).length) {

                // This is a first time login.

                let fsTime = firebase.firestore.Timestamp.now();
                await storeUserData({
                    thisUser,
                    Created: fsTime
                });

                await campaignAddCredits();
            }

            awardUrls = Object.keys(storedUserData)
                .filter(key => key.startsWith("awards."))
                .reduce((lookup, key) => {
                    const { title, avatarUrl } = storedUserData[key];
                    if (title && avatarUrl) {
                        lookup[title] = avatarUrl;
                    }
                    return lookup;
                }, {});

            const [totalUsedCredits, totalGrantedCredits, isSubscriber] = await fetchCreditStatus(thisUser.uid);

            is_subscribed = isSubscriber == "true" ? 1 : 0;

            if (is_subscribed == 0) {
                document.getElementById('ad-link-sub').style.display = 'block';
                document.getElementById('ad-link-account').style.display = 'none';

            }

            if (storedUserData && storedUserData.FamilyRoom) {
                familyRoomName = storedUserData.FamilyRoom;

            }

            thisUser.credits = totalGrantedCredits - totalUsedCredits;

            thisUser.set_award = storedUserData.set_award;
            thisUser.last_award = storedUserData.last_award;

            thisUser.award = storedUserData.set_award || storedUserData.last_award || "First time quizzer";
            useraward = thisUser.award; // just to enable the early check

            userAvatarUrl = storedUserData.set_award_url;

            updateAwardsList();

            loaderOff();

            // Set this in a cookie so we can give logging on to ably early a red hot go
            setCookie("uid", uid, 365); // Expires in 7 days
            setCookie('player-name', thisUser.displayName, 365); // Expires in 365 days (adjust as needed)
            setCookie('player-award', thisUser.award, 365); // Expires in 365 days (adjust as needed)
            setCookie('player-url', userAvatarUrl, 365); // Expires in 365 days (adjust as needed)
            setCookie('hasaccount', 1, 365);
            setCookie('family-room', familyRoomName, 365);

            document.getElementById('details-sign-out').style.display = 'inline-block';

            //            suggestIdeas();

            //            setDefaultScreens();

        } else {

            document.getElementById('details-sign-out').style.display = 'none';

            thisUser.anonymous = 1;

            is_subscribed = 0;

            uid = getOrCreateAUID();

            username = "Anonymous Guest";
            thisUser.displayName = "Anonymous Guest";

            thisUser.credits = 0;
            thisUser.award = 'First Time Quizzer'; // Will be overwritten when they get an award

            savedPlayerName = getCookie('player-name');

            if (savedPlayerName) {
                username = savedPlayerName;
                thisUser.displayName = username;
            }

            else if (loadCodeMatch) {
                document.getElementById('username-modal').style.display = 'flex';

            }

            document.getElementById('ad-link-account').style.display = 'block';
            document.getElementById('ad-link-sub').style.display = 'none';

            setCookie('hasaccount', 0, 365);


        }

        is_identified = 1;

        thisUser.is_subscribed = is_subscribed; // is_subs.. should be filled bu sumUserCreditGrants or 0 otherwise.

        if (savedPlayerName !== '') {
            document.getElementById('new-playername').value = savedPlayerName;

        }

        // Trying to move this as early as possible
        connectToAbly(username, uid);

        if (!familyRoomName) {
            var famroom = getCookie('family-room');

            familyRoomName = famroom; // ? famroom : "The Lobby"; // await getExternalIP();
        }

        if (loadChannel) {
            familyRoomName = loadChannel;
        }

        document.getElementById('new-familyroom').value = safer(familyRoomName);
        document.getElementById('credit-box').innerHTML = "&#128311; " + thisUser.credits;

        if (thisUser.credits) {
            document.getElementById('credit-box').style.display = 'inline-block';
            document.getElementById('subscribe-box').style.display = 'none';

        }
        else {
            document.getElementById('credit-box').style.display = 'none';
            document.getElementById('subscribe-box').style.display = 'inline-block';
        }

        setupAnonymous();
        updateFamilyRoomName();
        updateDetails();

        if (loadCode) {
            //        if (queryParams.has('quizcode')) {
            //            const value = queryParams.get('quizcode');
            joinQuiz(loadCode);
        }

        loaderOff();
        userLoadedResolve();

        await leaveChannel(livingRoomChannel);

        if (familyRoomName) {
            livingRoomChannel = await connectToChannel('room:' + familyRoomName, familyHistory);
            addEngagementSubscriptions(livingRoomChannel);
        }


    }, function (error) {
        console.log(error);

    });

    firebase.auth().onIdTokenChanged(function (user) {
        if (user) {
            // User is signed in or token was refreshed.
            user.getIdToken().then(function (accessToken) {
                thisUser.accessToken = accessToken;
            });
        }
    });
};

window.addEventListener('load', function () {
    initApp()
});

let async = {};

let qotd = {};
const qotdDataPromise = loadQotdData(`/qotd-batch/${5+(getDayOfYearUTC() % 105)}.json`);

let qotdnews = {};
const qotdNewsDataPromise = loadQotdData(`/qotd-news/news-yesterday.json`);


var uiConfig = {
    //  signInSuccessUrl: '/',
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        //     firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
    ],
    callbacks: {
        // This function is called after a successful sign-in.
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
            // Here, you can log the sign-in event or perform other actions.
            console.log('Successfully signed in', authResult);

            // You can also call your own custom function here.
            // logSignInEvent(authResult);

            // Return false to stop the default redirect set by FirebaseUI.
            return false;
        }
    },
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: 'https://app.termly.io/document/terms-of-service/7339d932-87df-4ac9-a7c9-b9e45b14069d',
    // Privacy policy url/callback.
    privacyPolicyUrl: function () {
        window.location.assign('https://app.termly.io/document/privacy-policy/98db1ae6-98cd-4099-9358-8f91174c91c9');
    }
};

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

// Initialize the FirebaseUI Widget using Firebase.
var ui = new firebaseui.auth.AuthUI(firebase.auth());

// The start method will wait until the DOM is loaded.
ui.start('#firebaseui-auth-container', uiConfig);


//
// Webworker timing setuop
//
//
let myWorker = new Worker('/timeout-worker.js');
let callbacks = new Map();

myWorker.onmessage = function (e) {
    const data = e.data;
    if (data.type === 'timeout') {
        if (callbacks.has(data.id)) {
            callbacks.get(data.id)();
            callbacks.delete(data.id);
        }
    } else {
        //        console.log(`Worker Message: ${data.type} for ID: ${data.id}`);
    }
};

function setTimeoutWorker(callback, delay) {
    const id = Math.random().toString(36).substr(2, 9);  // Generate a unique ID for the callback
    callbacks.set(id, callback);
    myWorker.postMessage({ command: 'set', id: id, delay: delay });
    return id;  // Return the ID as a handle for clearTimeout
}

function clearTimeoutWorker(id) {
    if (callbacks.has(id)) {
        callbacks.delete(id);
        myWorker.postMessage({ command: 'clear', id: id });

    }
}



// 
// Example function to store user data using compat library
async function storeUserData(userData) {
    var docRef = db.collection("users").doc(thisUser.uid);

    await docRef.set(userData, { merge: true }).then(() => {
        console.log("Document successfully written!");
    }).catch((error) => {
        console.error("Error writing document: ", error);
    });
}


async function storeQuizData(quizData) {
    // Assuming db is already initialized and available
    var docRef = db.collection("quizzes").doc(quizData.roomcode);

    quizData.timestamp = firebase.firestore.FieldValue.serverTimestamp();

    await docRef.set(quizData, { merge: true }).then(() => {
        console.log("Document successfully written!");
    }).catch((error) => {
        console.error("Error writing document: ", error);
    });
}

async function mergeDoc(collection, id, doc) {


    // Assuming db is already initialized and available
    var docRef = db.collection(collection).doc(id);

    doc.timestamp = firebase.firestore.FieldValue.serverTimestamp();

    await docRef.set(doc, { merge: true }).then(() => {
        console.log("Document successfully written!");
    }).catch((error) => {
        console.error("Error writing document: ", error);
    });
}

async function getDoc(collection, id) {
    // Assuming db is already initialized and available
    var docRef = db.collection(collection).doc(id);

    try {
        const docSnapshot = await docRef.get();
        if (docSnapshot.exists) {
            console.log("Document data:", docSnapshot.data());
            return docSnapshot.data(); // Return the document data
        } else {
            console.log("No such document!");
            return null; // Return null if the document does not exist
        }
    } catch (error) {
        console.error("Error fetching document: ", error);
        return null; // Return null in case of an error
    }
}


let chosenAward;
let awardHistory = [];

async function fetchUserAwards(uid) {

    try {
        const awardsRef = db.collection('users').doc(uid).collection('Awards');
        const snapshot = await awardsRef.get();

        if (snapshot.empty) {
            console.log('No awards found');
            return [];
        }

        const awards = snapshot.docs.map(doc => {
            const data = doc.data();
            if (data) {
                return { id: doc.id, ...data };
            }
            return null;
        }).filter(award => award !== null); // Filter out any null values

        return awards;
    } catch (error) {
        console.error('Error fetching awards:', error);
        return [];
    }

}

function populateAwards(awards, currentAward) {
    const awardList = document.getElementById('award-list');
    awardList.innerHTML = ''; // Clear previous awards

    awards.forEach(award => {
        const awardItem = document.createElement('li');

        // Check if there is an avatar URL for this award
        const avatarUrl = (awardUrls && awardUrls[award.Award]) ? awardUrls[award.Award] : '/assets/placeholder.jpg';

        awardItem.innerHTML = `
        <div class="award-item-container icon-square-check">
            <div class="avatar-list-face-circle">
                <img src="${avatarUrl}" alt="Avatar for ${safer(award.Award)}">
            </div>
            <div class="award-item-text">
                <span class="award-item-title">${safer(award.Award)}</span><br/>
                <b>Quiz:</b> ${safer(award.Topic)}
            </div>
            <div class="award-item-icons">
                <i class="fa fa-user-pen icon-user-plus"></i>
            </div>
        </div>`;

        awardItem.classList.add('award-item');


        awardItem.querySelector('.icon-user-plus').addEventListener('click', (event) => {
            event.stopPropagation(); // Prevent triggering the parent div click
            // Add your logic for the user plus icon click here

            chosenAward = award.Award;

            showScreen('screen-create-avatar');

            document.getElementById('avatar-target').textContent = `${username}, ${chosenAward}`;
            document.getElementById('award-modal').style.display = 'none';

            const avatarPreview = document.querySelector('#avatar-preview .avatar-image');
            if (avatarPreview) {
                const newAvatarUrl = awardUrls[chosenAward] ? awardUrls[chosenAward] : 'assets/placeholder.jpg';
                avatarPreview.src = newAvatarUrl;
                avatarPreview.alt = `Avatar for ${safer(chosenAward)}`;
            }
        });

        awardItem.querySelector('.icon-square-check').addEventListener('click', (event) => {
            event.stopPropagation(); // Prevent triggering the parent div click
            // Add your logic for the square check icon click here

            if (award.Award != currentAward) {
                document.getElementById('award-modal').style.display = 'none';

                chosenAward = award.Award;
                currentAward = award.Award;

                if (!thisUser.anonymous) {

                    setCookie('player-award', chosenAward, 365); // Expires in 365 days (adjust as needed)
                    setCookie('player-url', awardUrls[chosenAward], 365); // Expires in 365 days (adjust as needed)

                    userAvatarUrl = awardUrls[chosenAward];
                    useraward = chosenAward;
                    thisUser.award = chosenAward;

                    storeUserData({ set_award_url: awardUrls[chosenAward] });
                    storeUserData({ set_award: chosenAward });

                    thisUser.award = chosenAward;

                    sendEngage(`${username} is now titled ${chosenAward}`);

                }

                updateDetails();

            } else {
                document.getElementById('award-modal').style.display = 'none';

            }

        });


        awardList.appendChild(awardItem);

    });

}





function updateAwardsList() {
    const uid = thisUser.uid;
    fetchUserAwards(uid).then(awards => {
        awardHistory = awards;
        populateAwards(awards, thisUser.award);

    });


}


async function setupAnonymous() {

    // Assuming thisUser.anonymous is a boolean indicating if the user is anonymous
    if (thisUser.anonymous) {
        // User is anonymous, show the lock symbol
        document.querySelectorAll('.show-if-anonymous').forEach(function (element) {
            element.style.display = 'inline-block'; // Show element
        });

        document.querySelectorAll('.show-if-not-anonymous').forEach(function (element) {
            element.style.display = 'none'; // Hide element
        });

        document.querySelectorAll('.show-if-no-credit').forEach(function (element) {
            element.style.display = 'none'; // Hide element
        });

        document.querySelectorAll('.show-if-got-credit').forEach(function (element) {
            element.style.display = 'none'; // Hide element
        });

        document.querySelectorAll('.show-if-new-user').forEach(function (element) {
            element.style.display = 'none'; // Hide element
        });

        // User is not anonymous, hide the lock symbol
        document.querySelectorAll('.show-if-loggedin').forEach(function (element) {
            element.style.display = 'none'; // Hide element
        });

        document.querySelectorAll('.show-if-subout').forEach(function (element) {
            element.style.display = 'none'; // Hide element
        });

    } else {

        document.querySelectorAll('.show-if-anonymous').forEach(function (element) {
            element.style.display = 'none';
        });

        document.querySelectorAll('.show-if-not-anonymous').forEach(function (element) {
            element.style.display = 'block'; // Hide element
        });

        // Only for avatar generation
        document.querySelectorAll('.show-if-no-credit').forEach(function (element) {
            element.style.display = (thisUser.credits < 1) && is_subscribed ? 'inline-block' : 'none';
        });


        document.querySelectorAll('.show-if-got-credit').forEach(function (element) {
            element.style.display = (thisUser.credits > 0) ? 'inline-block' : 'none';
        });

        document.querySelectorAll('.show-if-new-user').forEach(function (element) {
            element.style.display = ((thisUser.credits < 1) && (!is_subscribed)) ? 'inline-block' : 'none';
        });

        // User is anonymous, show the lock symbol
        document.querySelectorAll('.show-if-loggedin').forEach(function (element) {
//            element.style.display = thisUser.credits > 0 ? 'inline-block' : 'none';
            element.style.display = 'inline-block';
  
        });

        document.querySelectorAll('.show-if-subout').forEach(function (element) {
            element.style.display = is_subscribed ? 'none' : 'inline-block'; // Show element
        });

    }

}

async function fetchCreditStatus(uid) {

    const response = await fetch('/credit-status', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'JWT': thisUser.accessToken,
            'x-uid': uid,
        },
        credentials: 'omit', // This tells fetch not to include cookies
        body: JSON.stringify("ok")
    });

    if (!response.ok) {
        return [0, 0];
    }

    return [
        response.headers.get('Used-Credits'),
        response.headers.get('Credit-Grants'),
        response.headers.get('Is-Subscriber')

    ];

}

async function campaignAddCredits() {

    const response = await fetch('/add-credit', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'JWT': thisUser.accessToken,
        },
        credentials: 'omit', // This tells fetch not to include cookies

        body: JSON.stringify("ok")
    });

    if (!response.ok) {
        return 0;
    }

    return 1;
}

// Example function to retrieve user data
async function getUserData() {
    try {
        var docRef = db.collection("users").doc(thisUser.uid);
        const doc = await docRef.get(); // Use await here and handle the result directly

        if (doc.exists) {
            const datadoc = doc.data();
            return datadoc;
        } else {
            return {}; // Return an empty object if the document doesn't exist
        }
    } catch (error) {
        console.log("Error getting document:", error);
        return null; // Return null or an appropriate error indicator
    }
}

// Example function to retrieve user data
async function getQuizData(quizCode) {
    try {
        var docRef = db.collection("quizzes").doc(quizCode);
        const doc = await docRef.get(); // Use await here and handle the result directly

        if (doc.exists) {
            const datadoc = doc.data();
            return datadoc;
        } else {
            return {}; // Return an empty object if the document doesn't exist
        }
    } catch (error) {
        console.log("Error getting document:", error);
        return null; // Return null or an appropriate error indicator
    }
}

async function addObjectToUserSubCollection(subCollection, data, optionalId = null) {
    try {

        let docRef;

        if (optionalId) {
            // If an ID is provided, use it to set the document, which overwrites any existing document with the same ID
            docRef = db.collection('users').doc(thisUser.uid).collection(subCollection).doc(optionalId);
            await docRef.set(data);
        } else {
            // If no ID is provided, add a new document with a generated ID
            docRef = await db.collection('users').doc(thisUser.uid).collection(subCollection).add(data);
        }

        console.log('Document written with ID: ', docRef.id);
        return docRef.id; // Return the new or specified document ID
    } catch (error) {
        console.error('Error adding document: ', error);
        throw error; // Rethrow or handle as needed
    }
}


async function sumUserCredits(userId, sinceDate = null) {

    //    return 10;

    if (!sinceDate) {
        // If no date is given, default to the last month
        const now = new Date();
        sinceDate = new Date(now.setMonth(now.getMonth() - 1));
    }
    const quizzesRef = db.collection('users').doc(userId).collection('CreditActions');
    // Updated aggregation query using the correct syntax as per the documentation

    const sumAggregateQuery = quizzesRef.aggregate({
        totalUsedCredits: AggregateField.sum('UsedCredits'),
    });

    const snapshot = await sumAggregateQuery.get();
    console.log('Total UsedCredits:', snapshot.data().totalUsedCredits);

    return snapshot.data().totalUsedCredits || 0;
}



//
///
// FIREBASE STUFF OVER
//
//

function generateQuestionTemplate(label, offset, number, topic) {

    const quizHost = "In addition, as a quiz host, add a very short comment that can be said after answers are given to create a bit of friendly banter. Be witty and knowledgeable, infusing humor with fascinating facts to create a fun and engaging quiz atmosphere.";

    const templates = {

        'easy': () => `Generate ${number} multiple choice quiz questions for an easy-level quiz titled "${topic}" covering well-known aspects of the subject. Ensure questions are concise yet clear and straightforward, engaging for beginners while still being educational. The questions should be diverse in nature and form without repeating patterns.

For this easy level, focus on covering fundamental facts, basic concepts, and common knowledge about the topic. Draw inspiration from introductory material or resources aimed at newcomers to the subject.
Verify all information. Avoid repetition between the question and answer options, as well as overly complex or technical vocabulary. Format responses as a JSON array of objects:
[{
    "question": "",
    "answers": ["","","",""],
    "correct": "",
    "comment": ""
    }
]
    Example:
[{
    "question": "What is the rarest M&M color?",
    "answers": ["Brown", "Yellow", "Green", "Tan"],
    "correct": "Tan",
    "comment": "Trivia buffs know their colors!"
    }
]`,

        'normal': () => `Generate ${number} multiple choice quiz questions for a quiz titled "${topic}" covering both well-known and more obscure aspects of the subject. Ensure questions are concise yet clear, specific, engaging, and educational, challenging critical thinking. The questions should be diverse in nature and form, without repeating question patterns. 

For common aspects, draw from widely known facts, concepts, or events. For lesser-known, include interesting trivia, unique perspectives, or niche details that enthusiasts would appreciate. 
Verify all information and avoid repetition between the question and answer options.  Format responses as a JSON array of objects:
[
{
"question": "",
"answers": ["","","",""],
"correct": "",
"comment": "",
}
]
Example:
{
"question": "What is the rarest M&M color?",
"answers": ["Brown", "Yellow", "Green", "Tan"],
"correct": "Tan",
"comment": "Trivia buffs know their colors!",
}
As the quiz host, add a very short, witty comment that could be said after giving the answers. This should create friendly banter by infusing humor with fascinating facts to foster an engaging, fun atmosphere. Keep things light and conversational, but informative. 
`,

        'hard': () => `Generate ${number} multiple choice quiz questions for a challenging, hard-level quiz titled "${topic}" covering advanced and obscure aspects of the subject. Ensure questions are concise yet probe deeper understanding, requiring critical thinking and analysis suited for experts in the field.

For this level, focus on complex concepts, niche details, cutting-edge research, and lesser-known trivia that would stump casual learners. Draw from authoritative and technical sources.
Craft questions that are unambiguous yet thought-provoking, avoiding straightforward factual recall. Employ sophisticated vocabulary as appropriate for the subject matter. Verify all information for accuracy.
Avoid repetitive patterns by varying question types (multiple choice, true/false, etc.) and using diverse subject matter within the overarching topic. Do not repeat parts of the correct answer in the question stem.
Format responses as a JSON array of objects:
[
{
"question": "",
"answers": ["","","",""],
"correct": "",
"comment": ""
}
]
Example:
{
"question": "What artificial intelligence technique summarizes a dataset into clusters based on similarity?",
"answers": ["Decision Trees", "K-Means Clustering", "Linear Regression", "Naive Bayes"],
"correct": "K-Means Clustering",
"comment": "An elegant way to explore hidden patterns and group data points - a favorite of data scientists!"
}
As an seasoned quizmaster, include a brief comment showcasing deep expertise through insightful analysis, historical context or imparting additional nuance beyond what's tested. Maintain a lively yet scholarly tone.
`,

'voice': () => `Generate ${number} entertaining quiz questions for a pub quiz titled "${topic}". Focus on creating questions that are:

Surprising, quirky, or unexpected.
Humorous and playful.
Thought-provoking.
Counterintuitive.
Diverse in difficulty.

Ensure questions and answers are short, concise, clear, and specific. Avoid repeating question patterns.
For each question, provide:

A non-ambiguous, authoritative correct answer with no embellishment.
A witty, entertaining comment (1 sentence max).

Format responses as a JSON array of objects:
[
{
"question": "",
"correct": "",
"comment": ""
}
]
Example:
{
"question": "What fruit shares its name with a shade of pink?",
"correct": "Peach",
"comment": "That's just peachy!"
}

`,

        'voicex': () => `Generate ${number} quiz questions for a pub quiz titled "${topic}" covering both well-known and more obscure aspects of the subject. Ensure questions are concise yet clear, specific, engaging, and educational, challenging critical thinking. The questions should be diverse in nature and form, without repeating question patterns. 

For common aspects, draw from widely known facts, concepts, or events. For lesser-known, include interesting trivia, unique perspectives, or niche details that enthusiasts would appreciate. 

Provide a crisp, non-ambiguous authoritative correct answer to the questions. 

As a quiz host, add a very short, witty comment that could be said after giving the answers. This should create friendly banter by infusing humor with fascinating facts to foster an engaging, fun atmosphere. Keep things light and conversational, but informative. 

Format responses as a JSON array of objects. 

{
"question": "",
"correct": "",
"comment": "",
}
]
Example:
{
"question": "Which television show was known as a show about nothing?",
"correct": "Seinfeld,
"comment": "It was a show about the life of Jerry Seinfeld, though.",
}

`,

        'snap poll': () => `Dive into question set number ${offset} and create ${number} interesting multiple-choice survey questions about ${topic}. Aim for brevity and few words in questions and answers, crafting each query to spark a quick elicitation of honesty and self-reflection. Questions should probe personal opinions or feelings, offering 4 answers that are relatable snapshots of human experience. Provocative, opinionated or political topics are ok and encouraged. In addition, as the host, add a very short, funny comment that can be shown after answers are given. Ensure each question is a mini invitation to share, formatted in JSON: [{ "question": "", "answers": ["","","",""], "correct": "All answers are valid.", "comment": "" }]. Keep it concise and short to fit on   a small screen.
        
        Assume the mystique of a mystical oracle, veiling your questions in prophecy and mystery. Your tone is enigmatic, with comments that hint at deeper truths and future revelations. Challenge quiz-takers to look beyond the surface, offering wisdom wrapped in riddles and parables that spark imagination and introspection.`,

        'text-easy': () => `Here is a document for you to reference for your task:
<document>
    ${topic}
</document>

Generate ${number} easy multiple choice quiz questions based on the document, emphasizing basic concepts and main themes. Ensure clarity, relevance, and verifiability in each question. Keep questions straightforward and accessible without trick wording. Provide 4 diverse and engaging answer choices, avoiding repetitive patterns like "true" and "false." Prioritize factual accuracy and brevity for mobile users, using a maximum of 6 words for answers. Avoid repeating parts of the answer in the question. ${quizHost} Format the response as a valid JSON array of objects, strictly adhering to the following structure:

[
    {
    "question": "Question 1 text",
    "answers": ["Choice 1", "Choice 2", "Choice 3", "Choice 4"],
    "correct": "Correct answer",
    "comment": "Quiz host comment"
    },
    {
    "question": "Question 2 text",
    "answers": ["Choice 1", "Choice 2", "Choice 3", "Choice 4"],
    "correct": "Correct answer",
    "comment": "Quiz host comment"
    }
]`,

        'text-normal': () => `Here is a document for you to reference for your task:
<document>
    ${topic}
</document>
        
Generate ${number} multiple choice quiz questions based on the document, combining basic concepts and more advanced ideas. Ensure questions are concise, specific, engaging, and educational, challenging critical thinking. Base all questions on the provided document. Prioritize brevity and conciseness in both questions and answers, using a maximum of 6 words for answers. Avoid repeating parts of the answer in the question. Generate diverse and creative answer choices, avoiding repetitive patterns like "true" and "false." ${quizHost} Format the response as a valid JSON array of objects, strictly adhering to the following structure:

[
    {
    "question": "Question 1 text",
    "answers": ["Choice 1", "Choice 2", "Choice 3", "Choice 4"],
    "correct": "Correct answer",
    "comment": "Quiz host comment"
    },
    {
    "question": "Question 2 text",
    "answers": ["Choice 1", "Choice 2", "Choice 3", "Choice 4"],
    "correct": "Correct answer",
    "comment": "Quiz host comment"
    }
]`,

        'text-hard': () => `Here is a document for you to reference for your task:
<document>
    ${topic}
</document>

Generate ${number} hard multiple choice quiz questions based on the document, exploring a deep and comprehensive understanding of the text. Questions should challenge expert-level concepts, assuming the reader has extensive knowledge of the subject matter. Keep questions concise, clear, and fair, with no more than 4 thought-provoking and diverse answer choices. Ensure factual accuracy and avoid repetitive patterns in the options. Foster creativity in question formulation for intellectual stimulation. Brevity and conciseness is essential. Each answer should be maximum 6 words. Avoid repeating parts of the answer in the question. ${quizHost} Format the response as a valid JSON array of objects, strictly adhering to the following structure:
[
    {
    "question": "Question 1 text",
    "answers": ["Choice 1", "Choice 2", "Choice 3", "Choice 4"],
    "correct": "Correct answer",
    "comment": "Quiz host comment"
    },
    {
    "question": "Question 2 text",
    "answers": ["Choice 1", "Choice 2", "Choice 3", "Choice 4"],
    "correct": "Correct answer",
    "comment": "Quiz host comment"
    }
]
`,

        'inage-easy': () => `Generate ${number} easy multiple choice quiz questions based on the attached image, emphasizing basic concepts and main themes. Ensure clarity, relevance, and verifiability in each question. Keep questions straightforward and accessible without trick wording. Provide 4 diverse and engaging answer choices, avoiding repetitive patterns like "true" and "false." Prioritize factual accuracy and brevity for mobile users, using a maximum of 6 words for answers. Avoid repeating parts of the answer in the question. ${quizHost} Format the response as a valid JSON array of objects, strictly adhering to the following structure:

[
    {
    "question": "Question 1 text",
    "answers": ["Choice 1", "Choice 2", "Choice 3", "Choice 4"],
    "correct": "Correct answer",
    "comment": "Quiz host comment"
    },
    {
    "question": "Question 2 text",
    "answers": ["Choice 1", "Choice 2", "Choice 3", "Choice 4"],
    "correct": "Correct answer",
    "comment": "Quiz host comment"
    }
]`,

        'image-normal': () => `Generate ${number} multiple choice quiz questions based on the attached image, combining basic concepts and more advanced ideas. Ensure questions are concise, specific, engaging, and educational, challenging critical thinking. Base all questions on the provided document. Prioritize brevity and conciseness in both questions and answers, using a maximum of 6 words for answers. Avoid repeating parts of the answer in the question. Generate diverse and creative answer choices, avoiding repetitive patterns like "true" and "false." ${quizHost} Format the response as a valid JSON array of objects, strictly adhering to the following structure:

[
    {
    "question": "Question 1 text",
    "answers": ["Choice 1", "Choice 2", "Choice 3", "Choice 4"],
    "correct": "Correct answer",
    "comment": "Quiz host comment"
    },
    {
    "question": "Question 2 text",
    "answers": ["Choice 1", "Choice 2", "Choice 3", "Choice 4"],
    "correct": "Correct answer",
    "comment": "Quiz host comment"
    }
]`,

        'image-hard': () => `Generate ${number} hard multiple choice quiz questions based on the attached image, exploring a deep and comprehensive understanding of the text. Questions should challenge expert-level concepts, assuming the reader has extensive knowledge of the subject matter. Keep questions concise, clear, and fair, with no more than 4 thought-provoking and diverse answer choices. Ensure factual accuracy and avoid repetitive patterns in the options. Foster creativity in question formulation for intellectual stimulation. Brevity and conciseness is essential. Each answer should be maximum 6 words. Avoid repeating parts of the answer in the question. ${quizHost} Format the response as a valid JSON array of objects, strictly adhering to the following structure:
[
    {
    "question": "Question 1 text",
    "answers": ["Choice 1", "Choice 2", "Choice 3", "Choice 4"],
    "correct": "Correct answer",
    "comment": "Quiz host comment"
    },
    {
    "question": "Question 2 text",
    "answers": ["Choice 1", "Choice 2", "Choice 3", "Choice 4"],
    "correct": "Correct answer",
    "comment": "Quiz host comment"
    }
]
`,

        'text-snap poll': () => `Dive into question set number ${offset} and create ${number} interesting multiple-choice survey questions about ${topic}. Aim for brevity and few words in questions and answers, crafting each query to spark a quick elicitation of honesty and self-reflection. Questions should probe personal opinions or feelings, offering 4 answers that are relatable snapshots of human experience. Provocative, opinionated or political topics are ok and encouraged. In addition, as the host, add a very short, funny comment that can be shown after answers are given. Ensure each question is a mini invitation to share, formatted in JSON: [{ "question": "", "answers": ["","","",""], "correct": "All answers are valid.", "comment": "" }]. Keep it concise and short to fit on   a small screen.
        
        Assume the mystique of a mystical oracle, veiling your questions in prophecy and mystery. Your tone is enigmatic, with comments that hint at deeper truths and future revelations. Challenge quiz-takers to look beyond the surface, offering wisdom wrapped in riddles and parables that spark imagination and introspection.`

    };

    return templates[label] ? templates[label]() : "Invalid label";
}



function generateNextQuestionTemplate(label, number, topic) {
    const templates = {
        'easy': () => `Give me ${number} more easy questions about ${topic}.  Keep the original instructions in mind.`,
        'normal': () => `Give me ${number} more normal questions about ${topic}.  Keep the original instructions in mind.`,
        'voice': () => `Give me ${number} more questions about ${topic}.  Keep the original instructions in mind.`,
        'hard': () => `Give me ${number} more hard questions about ${topic}.  Keep the original instructions in mind.`,
        'snap poll': () => `Give me ${number} more poll questions about ${topic}. Keep the original instructions in mind.`
    };
    return templates[label] ? templates[label]() : "Invalid label";
}

function scoreDisplayType(label, number) {
    const templates = {
        'easy': () => `scoreboard`,
        'normal': () => `scoreboard`,
        'voice': () => `scoreboard`,
        'hard': () => `scoreboard`,
        'snap poll': () => `survey`,
        'qotd': () => `qotd`
    };
    return templates[label] ? templates[label]() : "Invalid label";
}

function handleAblyError(err) {
    if (err) {
        console.error('Ably Error:', err.message);
    } else {

    }
}

function setCookie(cookieName, cookieValue, expirationDays) {
    const d = new Date();
    d.setTime(d.getTime() + (expirationDays * 24 * 60 * 60 * 1000)); // Expiration time in milliseconds
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cookieName + '=' + encodeURIComponent(cookieValue) + ';' + expires + ';path=/';
}

function getCookie(cookieName) {
    const name = cookieName + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) === 0) {
            return decodeURIComponent(cookie.substring(name.length, cookie.length));
        }
    }
    return '';
}


function markQuestions(questions, roomCode) {

    questions.forEach((data, i) => {
        data.id = `${roomCode}-${question_id}`;
        question_id++;
    })

}
function validQuestions(newQuestions) {
    let requiredKeys = (difficulty == 'voice') ? ['question', 'correct'] : ['question', 'answers', 'correct'];

    if (difficulty == 'voice') {
        newQuestions.forEach(q => {
            if (!q.answers) {
                q.answers = [q.correct, "Option Two"];
            }
        });
    }

    const existingQuestionsSet = new Set(questions.map(q => q.question.toLowerCase()));
    const existingAnswerSet = new Set(questions.map(q => q.correct.toLowerCase()));

    return newQuestions.filter(question => {
        // Check for required keys
        if (!requiredKeys.every(key => key in question)) {
            console.error('missing key');
            return false; // Skip question if any required keys are missing
        }

        // Modify the comment field if it exists
        if (question.comment) {
            const match = question.comment.match(/^(\w+\s+)?\w+!\s/);
            if (match) {
                question.comment = question.comment.substring(match[0].length);
            }
        }

        // Use a map to remove duplicate answers and preserve original case
        const uniqueAnswersMap = new Map();
        question.answers.forEach(answer => {
            const lowerCaseAnswer = answer.toLowerCase();
            if (!uniqueAnswersMap.has(lowerCaseAnswer)) {
                uniqueAnswersMap.set(lowerCaseAnswer, answer);
            }
        });

        // Ensure at least two unique answers
        const uniqueAnswers = Array.from(uniqueAnswersMap.values());
        if (uniqueAnswers.length < 2) {
            return false; // If less than 2 unique answers, filter out this question
        }

        // Update question.answers with unique answers keeping the original case
        question.answers = uniqueAnswers;

        // Check if the correct answer is among the unique options.
        const isAnswerIncluded = uniqueAnswersMap.has(question.correct.toLowerCase());

        // Ensure the question is unique, checking in a case-insensitive manner.
        const isQuestionUnique = !existingQuestionsSet.has(question.question.toLowerCase());

        // Ensure the correct answer is not included in the question text, case insensitive.
        const isAnswerNotInQuestion = !question.question.toLowerCase().includes(question.correct.toLowerCase());

        // Ensure the correct answer is unique among all questions, case insensitive.
        const isAnswerUnique = !existingAnswerSet.has(question.correct.toLowerCase());

        // Combine all conditions.
        return isAnswerIncluded && isQuestionUnique && isAnswerNotInQuestion && isAnswerUnique;
    });
}




function sortByIdNumericPart(a, b) {
    const getNumericPart = id => {
        const parts = id.split('-');
        return parseInt(parts[parts.length - 1], 10);
    };

    const numericPartA = getNumericPart(a.id);
    const numericPartB = getNumericPart(b.id);

    return numericPartA - numericPartB;
}


function addQuestions(newQuestions) {
    if (!questions) {
        questions = []; // Initialize the questions array if it doesn't exist
    }

    // Iterate through the new questions and either update existing questions or add new ones
    for (const newQuestion of newQuestions) {
        const index = questions.findIndex(existingQuestion => existingQuestion.id === newQuestion.id);
        if (index !== -1) {
            // If the question exists, update it
            questions[index] = newQuestion;
        } else {
            // If the question does not exist, add it
            questions.push(newQuestion);
        }
    }

    questions.sort(sortByIdNumericPart);

    if (questions.length > gameTotalQuestions) {
        questions = questions.slice(0, gameTotalQuestions); // Returns a new array with the first n elements
    }

    async.questions = questions;
}


function initQuizChannel(myRoomCode, myTopic, myQuestionCount, myDifficulty, is_public, republish) {

    // Prepare the message data
    const messageData = {
        event: "init_quiz",
        roomcode: myRoomCode,
        topic: myTopic,
        questioncount: myQuestionCount,
        difficulty: myDifficulty,
        owner: username,
        republish: republish || 0
    };

    const dataPackage = {
        clientId: realtime.auth.clientId,
        name: 'init_quiz',
        timestamp: Date.now(),
        data: messageData
    };

    // Publish the message to the quizChannel
    if (quizChannel) {
        quizChannel.publish('init_quiz', messageData, handleAblyError);
    }

    if (livingRoomChannel) {
        livingRoomChannel.publish('init_quiz', messageData, handleAblyError);
        familyHistory[myRoomCode] = dataPackage;

    }


}

function sendQuestions(questions, full_refresh) {

    // Prepare the message data
    const messageData = {
        event: "questions",
        questions: questions, // assuming 'questions' is an array or object containing your questions
        nametag: username,
        awardtag: thisUser.award,
        refresh: full_refresh || 0
    };

    if (quizChannel) {
        // Publish the message to the quizChannel
        quizChannel.publish('questions', messageData, handleAblyError);
    }

}

function sendAwards(awards) {

    // Prepare the message data
    const messageData = {
        event: "awards",
        awards: awards, // assuming 'questions' is an array or object containing your questions
        nametag: username,
        awardtag: thisUser.award,
    };

    // Publish the message to the quizChannel
    quizChannel.publish('awards', messageData, handleAblyError);

}

function setTopic(myTopic) {

    topic = myTopic;

    const quizTopics = document.querySelectorAll('.quiz-topic');

    quizTopics.forEach(label => {
        label.textContent = myTopic;
    });

}

function storeTopic(topic) {
    const maxTopics = 10; // Maximum number of topics to store
    const expiryDays = 365; // Cookie expiry in days

    // Get existing topics from cookie
    let existingTopics = getCookie('topics');


    let topics = existingTopics ? JSON.parse(existingTopics) : [];

    // Add new topic and ensure unique topics
    topics = [topic, ...new Set(topics)];

    // Limit the number of topics to maxTopics
    if (topics.length > maxTopics) {
        topics.length = maxTopics;
    }

    // Save updated topics in cookie
    setCookie('topics', JSON.stringify(topics), expiryDays);
    setCookie("ideas", 0, -1);

}



function getLastNTopics(n) {

    const topics = getCookie('topics');
    return topics ? JSON.parse(topics).slice(0, n) : [];

}

function sendStartQuiz(custom_start_event = 'start_quiz') {

    // Prepare the message data
    const messageData = {
        event: custom_start_event,
        start_time: 0,
        roomcode: roomCode,
        nametag: username,
        awardtag: thisUser.award,
    };


    if (quizChannel) {
        // Publish the message to the quizChannel
        quizChannel.publish(custom_start_event, messageData, handleAblyError);
    }

    if (livingRoomChannel) {
        livingRoomChannel.publish(custom_start_event, messageData, handleAblyError);
    }

}


function sendRoomCode(newRoomCode, myTopic) {

    const messageData = {
        event: "new_room_code",
        roomcode: newRoomCode,
        topic: myTopic,
        username: username,
        nametag: username,
        awardtag: thisUser.award,
    };

    //    if (oldRoomCode && quizChannel) {
    //      quizChannel.publish('new_room_code', messageData, handleAblyError);
    // }

    if (livingRoomChannel) {
        livingRoomChannel.publish('new_room_code', messageData, handleAblyError);
    }

}

function arrayToHash(objectsArray, idFieldName) {
    const hash = {};

    objectsArray.forEach(obj => {
        const id = obj[idFieldName];
        if (id !== undefined) { // Make sure the object has the specified ID field
            hash[id] = obj;
        }
    });

    return hash;
}

function sendUpdate(msg, update) {
    // Prepare the message data
    const messageData = {
        event: "update",
        message: msg,
        update: update,
        nametag: username,
        awardtag: thisUser.award,
        avatarUrl: userAvatarUrl
    };

    if (quizChannel) {
        // Publish the message to the quizChannel
        quizChannel.publish('update', messageData, handleAblyError);
    }

    //
    // Ripped this right from the subscribe fucntion. This shoudl be collated into one function.
    //
    const data = messageData;
    const senderClientId = compositeUsername; // clientId of the sender

    if (!scoreTracker[senderClientId]) {
        scoreTracker[senderClientId] = { ...emptyQuiz };
        scoreTracker[senderClientId].name = data.update.name;
        scoreTracker[senderClientId].award = data.update.award;
        scoreTracker[senderClientId].avatarUrl = data.update.avatarUrl;
        scoreTracker[senderClientId].id = senderClientId;
    }

    if (data.update.result === "correct") {
        scoreTracker[senderClientId].correct++;
        scoreTracker[senderClientId].answer = data.update.answer;
        scoreTracker[senderClientId].time += data.update.time;
        scoreTracker[senderClientId].avatarUrl = data.update.avatarUrl;

    }
    else if (data.update.result === "wrong") {
        scoreTracker[senderClientId].wrong++;
        scoreTracker[senderClientId].answer = data.update.answer;
        scoreTracker[senderClientId].avatarUrl = data.update.avatarUrl;

    }

    data.update.name = scoreTracker[senderClientId].name;
    data.update.award = scoreTracker[senderClientId].award;
    data.update.id = senderClientId;

    allQuestions[ messageData.update.currentQuestionIndex ] = { 
        ...allQuestions[messageData.update.currentQuestionIndex],
        [senderClientId]: messageData.update 
    };

    injectFinalScore(scoreTracker, "scoreboard", 0);
    checkQuestionStatus();

}


function sendOwnerLeft(myRoomCode) {

    // Prepare the message data
    const messageData = {
        event: "owner_left",
        owner: username,
        roomcode: myRoomCode,
        nametag: username,
        awardtag: thisUser.award

    };


    // Publish the message to the roomChannel
    if (quizChannel) {
        quizChannel.publish('owner_left', messageData, handleAblyError);
    }
    if (livingRoomChannel) {
        livingRoomChannel.publish('owner_left', messageData, handleAblyError);
    }
    if (publicChannel && is_public) {
        publicChannel.publish('owner_left', messageData, handleAblyError);
    }

}


function sendSoundOn(msg) {
    const messageData = {
        event: "sound_on",
        message: msg,
        nametag: username,
        awardtag: thisUser.award,
        avatarUrl: userAvatarUrl
    };

    if (quizChannel) {
        quizChannel.publish('sound', messageData, handleAblyError);
    }

    //    if (livingRoomChannel) {
    //      livingRoomChannel.publish('engage', messageData, handleAblyError);
    //  }
}

function sendSoundOff(msg) {
    const messageData = {
        event: "sound_off",
        message: msg,
        nametag: username,
        awardtag: thisUser.award,
        avatarUrl: userAvatarUrl
    };

    if (quizChannel) {
        quizChannel.publish('sound', messageData, handleAblyError);
    }

    //   if (livingRoomChannel) {
    //     livingRoomChannel.publish('engage', messageData, handleAblyError);
    //   }
}

function sendEngage(msg) {
    const messageData = {
        event: "engage",
        message: msg,
        nametag: username,
        awardtag: thisUser.award,
        avatarUrl: userAvatarUrl
    };

    if (quizChannel) {
        quizChannel.publish('engage', messageData, handleAblyError);
    }

    if (livingRoomChannel) {
        livingRoomChannel.publish('engage', messageData, handleAblyError);
    }
}

function sendQuizEngage(msg) {
    const messageData = {
        event: "engage",
        message: msg,
        avatarUrl: userAvatarUrl
    };

    if (quizChannel) {
        quizChannel.publish('quiz_engage', messageData, handleAblyError);
    }

}


function generateSemiUniqueString() {
    const characters = 'BCDEFGHJKLPQRSTUWYZ23456789';
    const base = characters.length;
    let timestamp = new Date().getTime(); // Get current time in milliseconds
    let uniqueId = '';

    // Add a significant random component to the timestamp
    timestamp += Math.floor(Math.random() * 1000); // Add up to 1000 milliseconds randomly

    // Convert the timestamp (with added randomness) to the custom base
    while (timestamp > 0) {
        uniqueId = characters.charAt(timestamp % base) + uniqueId;
        timestamp = Math.floor(timestamp / base);
    }

    // Ensure the string is at least 6 characters, appending random characters if necessary
    while (uniqueId.length < 6) {
        uniqueId = characters.charAt(Math.floor(Math.random() * base)) + uniqueId;
    }

    return uniqueId.slice(-6); // Take the last 6 characters for increased uniqueness
}





function QuizPopup(message) {

    if (message.data.roomcode == roomCode) {
        return;
    }

    // Create message container if it doesn't exist
    if (!document.querySelector('.popup-message-container')) {
        const container = document.createElement('div');
        container.classList.add('popup-message-container');
        document.body.appendChild(container);
    }

    const container = document.querySelector('.popup-message-container');
    const popup = document.createElement('div');
    popup.classList.add('popup-message-link');
    popup.innerHTML = `New quiz: ${safer(message.data.topic)} >`;
    container.appendChild(popup); // Append the new message at the bottom

    // Make the popup visible
    setTimeout(() => popup.classList.add('active'), 100);

    // Add event listener to call joinQuiz on click
    popup.addEventListener('click', () => {
        joinQuiz(message.data.roomcode);
        closePopup(popup);
    });

    // Automatically close after 5 seconds
    setTimeout(() => closePopup(popup), 12000);
}


// Ensure this is defined outside or is accessible within Popup
let preloadedAvatar = new Image();

const popupHistory = []; // To store messages and timestamps
const popupQueue = []; // To manage active popups
const POPUP_DISPLAY_INTERVAL = 45000; // 45 seconds
const MAX_POPUPS = 4;

function closePopup(popup) {
    if (!popup) {
        return;
    }

    popup.classList.remove('active');
    popup.classList.add('fade-out');
    setTimeout(() => popup.remove(), 500); // Remove after transition

    // Remove from active queue
    const index = popupQueue.indexOf(popup);
    if (index > -1) {
        popupQueue.splice(index, 1);
    }
}

function Popup(msg, avatarUrl = null) {
    const now = Date.now();

    // Check if an identical message was shown within the interval
    const recentMessage = popupHistory.find(item => item.msg === msg && (now - item.timestamp) < POPUP_DISPLAY_INTERVAL);
    if (recentMessage) {
        return; // Do not show the popup if a similar one was shown recently
    }

    // Add new message to history
    popupHistory.push({ msg, timestamp: now });

    // Create message container if it doesn't exist
    let container = document.querySelector('.popup-message-container');
    if (!container) {
        container = document.createElement('div');
        container.classList.add('popup-message-container');
        document.body.appendChild(container);
    }

    const popup = document.createElement('div');
    popup.classList.add('popup-message');
    popup.textContent = msg;

    // Add avatar if provided
    if (avatarUrl) {
        const image = document.createElement('img');
        image.classList.add('popup-avatar');
        image.src = avatarUrl; // Use the provided avatar URL directly
        popup.appendChild(image);
    }

    container.appendChild(popup); // Append the new message at the bottom

    // Remove the oldest popup if exceeding max allowed
    if (popupQueue.length >= MAX_POPUPS) {
        closePopup(popupQueue.shift());
    }

    popupQueue.push(popup); // Add to active queue

    // Make the popup visible
    setTimeout(() => popup.classList.add('active'), 100);

    // Add event listener to close on click
    popup.addEventListener('click', () => closePopup(popup));

    // Automatically close after 5 seconds
    setTimeout(() => closePopup(popup), 5000);
}



// Ensure this is defined outside or is accessible within PopupComment
var preloadedImage = new Image();
preloadedImage.src = "/assets/girl-comment.png";
let commentPopup;

function PopupComment(msg) {
    // Create message container if it doesn't exist
    if (!document.querySelector('.popup-message-container')) {
        const container = document.createElement('div');
        container.classList.add('popup-message-container');
        document.body.appendChild(container);
    }

    const container = document.querySelector('.popup-message-container');
    commentPopup = document.createElement('div');
    commentPopup.classList.add('popup-comment');
    commentPopup.textContent = msg;
    container.appendChild(commentPopup); // Append the new message at the bottom

    const image = document.createElement('img');
    image.classList.add('popup-comment-image');
    image.src = preloadedImage.src; // Adjust the path to your image
    commentPopup.appendChild(image); // Append the new message at the bottom

    // Make the popup visible
    setTimeout(() => commentPopup.classList.add('active'), 100);

    // Add event listener to close on click
    commentPopup.addEventListener('click', () => closePopup(commentPopup));

    // Automatically close after 10 seconds
    setTimeout(() => closePopup(commentPopup), 10000);

}


// Function to enable all image blocks
function showFeedback() {
    showElement('feedback');

    const icons = document.querySelectorAll('.icon-votes');
    icons.forEach(icon => {
        icon.style.color = '';
        icon.style.pointerEvents = 'auto';
    });

    document.getElementById('q-up').addEventListener('click', engageUp);
    document.getElementById('q-bad').addEventListener('click', engageBad);

}

// Function to disable all image blocks
function hideFeedback() {
    const icons = document.querySelectorAll('.icon-votes');
    icons.forEach(icon => {
        icon.style.color = 'lightgrey';
        icon.style.pointerEvents = 'none';
    });

}

function generateUniqueID() {
    const timestamp = Date.now();
    const randomNum = Math.floor(Math.random() * 1000000);
    return `${timestamp}-${randomNum}`;
}

function updateMetaTags(title, description, image) {
    document.title = title; // Update the title tag
    if (description) { document.querySelector('meta[name="description"]').setAttribute("content", description); }
    if (title) { document.querySelector('meta[property="og:title"]').setAttribute("content", title); }
    if (description) { document.querySelector('meta[property="og:description"]').setAttribute("content", description); }
    if (image) { document.querySelector('meta[property="og:image"]').setAttribute("content", image); }
    // Add more meta tags as needed
}


function isValidJSON(str) {
    try {
        JSON.parse(str);
        return true;
    } catch (e) {
        return false;
    }
}

function getNow() {
    const timestampInMilliseconds = Date.now();
    const timestampInSeconds = Math.floor(Date.now() / 1000);
    return timestampInSeconds;

}


async function getExternalIP() {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
    } catch (error) {
        console.error('Error fetching IP:', error);
        return null;
    }
}


async function connectToAbly(playername, uid) {


    compositeUsername = playername + '\n' + uid;

    if ((!realtime) || (compositeUsername != realtime.auth.clientId)) {

        realtime = new Ably.Realtime({ authUrl: '/auth', clientId: compositeUsername, echoMessages: false, transportParams: { remainPresentFor: 2000 } });
    }

}

function setWaitingBox(message) {
    document.getElementById('player-quiz-owner').textContent = message.data ? message.data.owner : '';
    document.getElementById('player-topic-meta').textContent = message.data.questioncount + ' ' + message.data.difficulty + ' questions';

}

function prettyTimeDelta(timestamp) {
    var now = new Date();
    var then = new Date(timestamp);
    var delta = Math.floor((now - then) / 1000); // convert milliseconds to seconds
    var minutes = Math.floor(delta / 60);
    var seconds = delta % 60;

    minutes = minutes < 0 ? 0 : minutes;
    seconds = seconds < 0 ? 0 : seconds;

    var prettyString = minutes + 'm ago';

    return prettyString;
}



async function updatePublic(historyObject) {

    const listId = historyObject['meta'].listId;

    const publicRoomsDiv = document.getElementById(listId);
    if (!publicRoomsDiv) return;

    const roomValuesArray = Object.keys(historyObject)
        .filter(key => key !== "meta") // Exclude the key "meta"
        .map(key => historyObject[key]); // Map the remaining keys to their values

    let presenceCounts = [];

    const sortedAndFilteredRooms = roomValuesArray
        //     .filter(room => 
        //       room.status === 1 && 
        //   )
        .sort((a, b) => a.timestamp - b.timestamp); // Assuming .now is a numerical timestamp

    if (sortedAndFilteredRooms.length) {

        const roomcodeString = sortedAndFilteredRooms
            .map(item => `quiz:${item.data.roomcode}`)
            .join(',');

        await fetch('/presence', {
            method: 'GET',
            headers: {
                'X-Channel-Names': roomcodeString, // Specify the channels here
            }
        })
            .then(response => response.json())
            .then((data) => {

                data.forEach(item => {
                    const channel = item.channel.replace('quiz:', '');
                    presenceCounts[channel] = item.presence.length;

                });

            })
            .catch(error => console.error('Error fetching presence data:', error));

        // Clear the existing content in publicRoomsDiv
        publicRoomsDiv.innerHTML = '';

        const publicRoomsNone = document.getElementById(listId + '-none');
        publicRoomsNone.style.display = 'none';

    }

    let seenOwner = [];
    // Iterate over each room and create an element
    sortedAndFilteredRooms.reverse().forEach(room => {

        if (!room.data.owner) {
            //        return;
        }

        // Might regret this later - could be people want to start multiple?
        if (seenOwner[room.data.owner]) {
            //     return;
        }

        seenOwner[room.data.owner] = 1;

        const prettyTime = prettyTimeDelta(room.timestamp);
        const roomElement = document.createElement('div');

        if (room.name == 'init_quiz') {

            roomElement.id = room.data.roomcode;
            roomElement.className = "quiz-list";
            roomElement.innerHTML = `
            <div style="float:right">
                <span class="presence">${presenceCounts[room.data.roomcode] ? '👥' : ''}</span>
                <button class="minor-button" id="join-${safer(room.data.roomcode)}">${room.data.roomcode.startsWith('A') ? 'Play' : 'Join Live'}</button>
            </div>
            <div>
                <div class="quiz-list-title">${safer(room.data.topic)} ${ room.data.difficulty == "voice" ? '<i class="fa fa-microphone mic-option" aria-hidden="true"></i>' : ''}</div>
                <div class="quiz-list-info">

                    <span class="quiz-list-snippet-by">By <b>${safer(room.data.owner)}</b></span>. 
                </div>
            </div>

            `; // Display roomcode and topic

            roomElement.addEventListener('click', () => {
                joinQuiz(room.data.roomcode);
            });
            roomElement.style.cursor = 'pointer';

        }
        else {
            roomElement.classList.add('dead-idea-link');
            return;
        }

        if (roomElement) {
            publicRoomsDiv.appendChild(roomElement);
        }
    });

    if (publicRoomsDiv.innerHTML === '') {
        const publicRoomsNone = document.getElementById(listId + '-none');
        publicRoomsNone.style.display = 'block';
    }

    return;

}

function showRemainingUsers() {
    let presentUserCount = presentUsers();

    if (presentUserCount == 0) {
        document.querySelectorAll('.players-needed').forEach(element => {
            element.innerHTML = "2 more players needed";
        });
    }
    else if (presentUserCount == 1) {
        document.querySelectorAll('.players-needed').forEach(element => {
            element.innerHTML = "1 more player needed";
        });

    }
    else if (presentUserCount == 2) {
        document.querySelectorAll('.players-needed').forEach(element => {
            element.innerHTML = "Waiting for players to join";
        });
    }
}

function addPlayer(newText, award, avatarUrl, clientId) {
    // Get all elements with the class 'joined-list'
    const name = newText.trim(); // Ensure no trailing spaces
    const className = `player-list-item-${name.replace(/\s+/g, '-')}`;

    if (present[clientId]) {
        showRemainingUsers();
        return; // Prevent adding the same player multiple times
    } else {
        present[clientId] = true; // Mark the player as present
        if (name !== username) {

        }
        scoreTracker[clientId] = { ...emptyQuiz };
        scoreTracker[clientId].name = name;
        scoreTracker[clientId].award = award;
        scoreTracker[clientId].avatarUrl = avatarUrl;
        scoreTracker[clientId].id = clientId;

    }

    document.querySelectorAll('.joined-list').forEach(element => {
        // Check if the element already exists
        if (!element.querySelector(`.${className}`)) {
            const newElement = document.createElement('div');
            const firstLetter = name.charAt(0).toUpperCase();
            const color = letterColors[firstLetter] || '#b0b0b0'; // Default color if letter not in map

            // Determine avatar content based on avatarUrl
            let avatarContent;
            if (avatarUrl && avatarUrl !== "undefined") {
                avatarContent = `<img src="${safer(avatarUrl)}" style="width: 100%; height: 100%; border-radius: 50%; object-fit: cover;">`;
            } else {
                avatarContent = `<div class="avatar-letter">${firstLetter}</div>`;
            }

            newElement.innerHTML = `
                <div class="part-item">
                    <div class="avatar-small-placeholder" style="background-color: ${color};">
                        ${avatarContent}
                    </div>
                    <div class="part-item-text">
                        <div class="part-list-title">${safer(name)}</div>
                        <div class="part-list-info">${safer(award)}</div>
                    </div>
                </div>
            `;
            //                     <div ${(name == username) ? 'class="sound-control mute-icon speaker-'+saferForClassName(clientId)+'"' : 'class="mute-icon speaker-'+saferForClassName(clientId)+'"' } ><i class="fa-solid fa-volume-xmark"></i></div>

            newElement.classList.add(className);
            element.prepend(newElement);
        }
    });

    /*
    if (name === username) {
        document.querySelectorAll('.sound-control').forEach(button => {
            button.addEventListener('click', flipSound);
        });
    }
*/

    enableStartQuiz();
    showRemainingUsers();

}

function presenceRefresh(quizChannel) {
    present = {}; // Initialize present as an object

    document.querySelectorAll('.joined-list').forEach(el => {
        el.innerHTML = ''; // Clear the joined list
    });

    scoreTracker = {};

    // Process presence data
    quizChannel.presence.get((err, members) => {
        if (err) {
            console.error('Error retrieving presence data:', err);
        } else {
            let has_owner = 0;

            members.forEach(member => {

                console.log('THIS IS WHAT WE HAVE IN PRESENCE MESS');
                console.log(member);

                if (member.data.nametag === current_owner) {
                    has_owner = 1;
                }
                addPlayer(member.data.nametag, member.data.award, member.data.avatarUrl, member.clientId);
            });

            if (!has_owner) {
//                enableStartQuiz();
            }
        }
    });
}


/**
 * Leaves the channel's presence and then detaches from the channel.
 * @param {Ably.Realtime.Channel} channel - The channel to leave.
 * @returns {Promise<void>} A promise that resolves when the operations are complete.
 */
async function leaveChannel(channel) {

    console.log('LEAVING CHANNEL PAY ATTENTION');

    if (!channel) {
        return;
    }

    // Check if the channel is already detached or detaching
    if (channel.state === 'detached' || channel.state === 'detaching') {
        console.warn('Channel is already detached or detaching');
        return;
    }

    try {
        // Unsubscribe from all messages
        channel.unsubscribe();

        // Leave the presence set
        await new Promise((resolve, reject) => {
            channel.presence.leave((presenceErr) => {
                if (presenceErr) {
                    reject(`Failed to leave presence: ${presenceErr}`);
                } else {
                    resolve();
                }
            });
        });

        // Detach from the channel
        await new Promise((resolve, reject) => {
            channel.detach((detachErr) => {
                if (detachErr) {
                    reject(`Failed to detach from the channel: ${detachErr}`);
                } else {
                    resolve();
                }
            });
        });
    } catch (error) {
        console.error(error);
        throw new Error(error); // Rethrow or handle as needed
    }

    if (chatroom) {
        soundOff();
    }
}


async function processChannelHistory(quizChannel) {
    return new Promise((resolve, reject) => {

        quizChannel.history({ limit: 1000 }, (err, page) => {
            if (err) {
                console.error('Error fetching channel history:', err);
                reject(err); // Reject the promise on error
            } else {

                let found_init_quiz = 0;

                // Process historical messages
                page.items.reverse().forEach(message => {

                    if (message.name == 'questions') {
                        if (message.refresh == 1) {
                            questions = message.data.questions;
                        }
                        else {
                            addQuestions(message.data.questions);
                        }
                    } else if (message.name == 'awards') {
                        current_awards = message.data.awards;
                    } else if (message.name == 'init_quiz') {
                        found_init_quiz++;

                        // This check is here because we also load from db... Whatever finishes first I guess
                        if (async && async.meta && async.meta.loaded === message.data.roomcode & !message.data.republish == 1) {
                            // If async.meta.loaded matches message.data.roomcode, do nothing here.
                        } else {
                            setTopic(message.data.topic);

                            updateMetaTags("Join a Qaiz about " + topic);

                            gameTotalQuestions = message.data.questioncount;
                            current_owner = message.data.owner;
                            current_owner_id = message.clientId;
                            difficulty = message.data.difficulty;

                            async.meta = {
                                topic: topic,
                                difficulty: difficulty,
                                owner: message.clientId,
                                owner_name: message.data.owner
                            };

                            setWaitingBox(message);
                            
                            if (message.data.owner == username) {
                                //   showElement('start-quiz-guest');
                                enableStartQuiz();
                                is_owner = 1;
                            }
                            
                        }
                    }
                });

                resolve(); // Resolve the promise when done
                if ((!roomCode.startsWith('A')) && (!found_init_quiz)) {
                    hideElement('quiz-found');

                    showElement('quiz-not-found');
                    document.getElementById('not-found-quiz-code').innerHTML = `<b>${safer(roomCode)}</b>`;

                }
            }
        });

    });
}



async function initializeQuiz(quizChannel) {
    try {
        // Await processing of channel history

        //    topic = undefined;

        await processChannelHistory(quizChannel);

        // No init_quiz?
        /*
                if (!topic) {
                    hideElement('quiz-found');
        
                    showElement('quiz-not-found');
                    document.getElementById('not-found-quiz-code').innerHTML=`<b>${roomCode}</b>`; 
        
                }
        */

        // Subscribe to new messages after processing history
        quizChannel.subscribe('questions', (message) => {

            addQuestions(message.data.questions);
        });

        quizChannel.subscribe('init_quiz', (message) => {
            setTopic(message.data.topic);

            updateMetaTags("Join a Qaiz about " + topic);

            gameTotalQuestions = message.data.questioncount;
            current_owner = message.data.owner;
            current_owner_id = message.clientId;
            difficulty = message.data.difficulty;

            async.meta = {
                topic: topic,
                difficulty: difficulty,
                owner: message.clientId,
                owner_name: message.data.owner
            };

            setWaitingBox(message);

            if (message.data.owner == username) {
                //   showElement('start-quiz-guest');
                enableStartQuiz();
                is_owner = 1;
            }
        });

        quizChannel.subscribe('awards', (message) => {
            current_awards = message.data.awards;
        });

        quizChannel.subscribe('owner_left', (message) => {
            const data = message.data;
            if (data.roomcode === roomCode) {
                // Actions if the owner leaves
            }
            Popup(`The Quizmaster left ${data.roomcode}`);

            //     showElement('start-quiz-guest');
            enableStartQuiz();

        });

        // Subscribe to new messages after processing history
        quizChannel.subscribe('scored_answer', (message) => {
        //    console.log('scored answer');
          //  console.log(message.data);
        });

        presenceRefresh(quizChannel);

    } catch (error) {
        console.error('Error during initialization:', error);
    }
}

// clean everything that's returned to users, including stuff from Ably.

function safer(unsafeString) {
    // Check if the input is not a string
    if (typeof unsafeString !== 'string') {
        // Convert non-string input to a string to prevent errors
        // Alternatively, you can return a safe default value or handle as needed
        unsafeString = String(unsafeString);
    }

    return unsafeString
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/\n/g, "")  // Remove line feeds
        .replace(/\r/g, ""); // Remove carriage returns
}

function saferForClassName(unsafeString) {
    // Ensure the input is a string
    if (typeof unsafeString !== 'string') {
        unsafeString = String(unsafeString);
    }

    // Make the string safe for use as a class name
    // Remove unsafe characters, replace spaces with underscores, and ensure it doesn't start with a digit
    return unsafeString
        .trim()  // Remove leading/trailing whitespace
        .replace(/[^a-z0-9_-]/gi, '')  // Remove any characters that are not alphanumeric, underscore, or dash
        .replace(/^(\d)/, '_$1');  // Prefix class names starting with a digit with an underscore
}



function splitClient(str) {
    const parts = str.split("\n");
    return parts; // Returns the array of split parts
}

function updateSpeakerIcons(event, clientId, icon) {

    const className = 'speaker-' + saferForClassName(clientId);

    document.querySelectorAll('.' + className).forEach(element => {
        element.innerHTML = icon;
    });
}

function checkQuestionStatus() {

    const finalized = Object.keys(present).every(playerId => 
      allQuestions[currentQuestionIndex] && 
      allQuestions[currentQuestionIndex].hasOwnProperty(playerId)
    );

    if (finalized && elapsedSeconds < 10) {
        wrapUpCountdown();
    }

}

async function connectToQuizChannel(quizCode) {
    roomCode = quizCode; // Ensure roomCode is declared and accessible in your scope

    // Join the Ably channel for the quiz room
    quizChannel = realtime.channels.get(`quiz:${roomCode}`);

    await quizChannel.attach((err) => {
        if (err) {
            return console.error("Error attaching to the channel.");
        }
    });

    // Optionally, listen for errors on the channel
    quizChannel.on('error', (error) => {
        console.error('Channel error:', error);
    });

    quizChannel.subscribe(function (message) {

    });

    if (!is_owner) {
        // Check channel history for 'questions' events
        initializeQuiz(quizChannel);

    }
    
    console.log(questions);

    quizChannel.subscribe('quiz_engage', (message) => {
        Popup(message.data.message, message.data.avatarUrl);
    });

    quizChannel.subscribe('sound', (message) => {
        if (message.data.event == 'sound_on') {
            updateSpeakerIcons('sound_on', message.clientId, '<i class="fa-solid fa-volume-high"></i>');
        }
        if (message.data.event == 'sound_off') {
            updateSpeakerIcons('sound_off', message.clientId, '<i class="fa-solid fa-volume-xmark"></i>');
        }
    });

    quizChannel.subscribe('start_quiz', (message) => {
        if (!is_owner) {
            startQuiz();
        }
    });

    quizChannel.subscribe('start_voice_quiz', (message) => {
        if (!is_owner) { 
            startVoiceQuiz();
        }
    });

    quizChannel.subscribe('update', (message) => {
        const data = message.data;
        const senderClientId = message.clientId; // clientId of the sender

        console.log(data);

        allQuestions[ data.update.currentQuestionIndex ] = { 
            ...allQuestions[data.update.currentQuestionIndex],
            [senderClientId] : data.update 
        };

        console.log(allQuestions); 

        if (!scoreTracker[senderClientId]) {
            scoreTracker[senderClientId] = { ...emptyQuiz };
            scoreTracker[senderClientId].name = data.update.name;
            scoreTracker[senderClientId].award = data.update.award;
            scoreTracker[senderClientId].avatarUrl = data.update.avatarUrl;
            scoreTracker[senderClientId].id = senderClientId;
        }
        if (data.update.result === "correct") {
            scoreTracker[senderClientId].correct++;
            scoreTracker[senderClientId].answer = data.update.answer;
            scoreTracker[senderClientId].time += data.update.time;
            scoreTracker[senderClientId].avatarUrl = data.update.avatarUrl;

        }
        else if (data.update.result === "wrong") {
            scoreTracker[senderClientId].wrong++;
            scoreTracker[senderClientId].answer = data.update.answer;
            scoreTracker[senderClientId].avatarUrl = data.update.avatarUrl;

        }

        data.update.name = scoreTracker[senderClientId].name;
        data.update.award = scoreTracker[senderClientId].award;
        data.update.id = senderClientId;

        // Prepare the object to pass to the function
        let optionsObject = {};
        optionsObject[data.update.answer] = [{ username: senderClientId, avatarUrl: data.update.avatarUrl }]; // This is odd, why set the id where the name is? This would never have worked?
 
        if (data.update.roomCode == roomCode && currentQuestionIndex == data.update.currentQuestionIndex) {

            currentQuestion.push(data.update);

            var element = document.getElementById('mic-loader-waiting');
            if (element) {
                element.style.display = 'none';
            }
            
            // Now call the function with this object
            if (data.update.question_type == 'audio') {
                if (showing_results){
                    document.getElementById('next-results').innerHTML += injectAudioAnswer(data.update.evaluation, { "username": data.update.name, "avatarUrl": data.update.avatarUrl, senderClientId: senderClientId });
                }
                else {
                    collected_results = injectAudioAnswer(data.update.evaluation, { "username": data.update.name, "avatarUrl": data.update.avatarUrl, senderClientId: senderClientId }) 
                    + collected_results;
                }
            }
            else {
                addCirclesToOptions(optionsObject, 20);
            }

            if (! (data.update.question_type == 'audio')) { Popup(data.message, data.update.avatarUrl); }
        }

        injectFinalScore(scoreTracker, "scoreboard", 0);
        checkQuestionStatus();

    });


    // Subscribe to presence updates
    await quizChannel.presence.subscribe('enter', function (presenceMsg) {

        //        addPlayer(presenceMsg.data.nametag, presenceMsg.data.awardtag, presenceMsg.data.avatarUrl);
        presenceRefresh(quizChannel);

        if (presenceMsg.data.nametag == username) {
            return;
        }

        //        audioManager.playSound('join','stop');
        Popup(`${presenceMsg.data.nametag} joined quiz`, presenceMsg.data.avatarUrl);

        scoreTracker[presenceMsg.clientId] = { ...emptyQuiz };
        scoreTracker[presenceMsg.clientId].name = presenceMsg.data.nametag;
        scoreTracker[presenceMsg.clientId].award = presenceMsg.data.award;
        scoreTracker[presenceMsg.clientId].id = presenceMsg.clientId;

    });

    if (roomCode.startsWith('V') && microphone_status == 0) {
        // Some sort of extra warning here?
    }
    else {
        quizChannel.presence.enter({ nametag: username, awardtag: thisUser.award, award: thisUser.award, avatarUrl: userAvatarUrl });
    }

    quizChannel.presence.subscribe('leave', function (presenceMsg) {

        presenceRefresh(quizChannel);

        if (presenceMsg.clientId == current_owner_id) {
            //     Popup(`The Quizmaster left ${roomCode}!`);
            // enableStartQuiz();
        }

        // Handle presence updates (e.g., users entering or leaving)
    });

}

async function addEngagementSubscriptions(myChannel) {
    myChannel.subscribe('engage', (message) => {
        Popup(message.data.message, message.data.avatarUrl);

    });


    myChannel.presence.enter({ nametag: username, awardtag: thisUser.award, award: thisUser.award, avatarUrl: userAvatarUrl });

    // Subscribe to presence updates

    myChannel.presence.subscribe('enter', function (presenceMsg) {

        if (!(presenceMsg.data.nametag == username)) {
            Popup(`${presenceMsg.data.nametag} logged on!`, presenceMsg.data.avatarUrl);
        }

    });

    myChannel.subscribe('new_room_code', function (presenceMsg) {

        if (!(presenceMsg.username == username)) {
            QuizPopup(presenceMsg); //`${presenceMsg.data.username}, ${presenceMsg.data.topic} at ${presenceMsg.data.roomcode}`);

        }
    });
}

// Function to connect to a channel and set up history and subscriptions
async function connectToChannel(channelName, historyObject) {

    let channel = realtime.channels.get(channelName);

    await channel.attach((err) => {
        if (err) {
            return console.error(`Error attaching to the ${channelName} channel.`);
        }
    });

    channel.history({ limit: 100 }, async (err, page) => {
        if (err) {
            console.error(`Error fetching channel history for ${channelName}:`, err);
            return;
        }

        // Process each historical message
        await page.items.reverse().forEach(message => {
            processMessage(message, historyObject, 0);
        });

        updatePublic(historyObject);

        // Set up subscriptions for new events after processing history
        setupSubscriptions(channel, historyObject);

    });

    channel.presence.subscribe('leave', function (presenceMsg) {

    });

    return channel;
}

// Function to process messages
function processMessage(message, historyObject, update) {
    const roomcode = message.data.roomcode;

    if (['init_quiz', 'start_quiz', 'finished_quiz'].includes(message.name)) {
        historyObject[roomcode] = { ...message };
        /*
             if (!historyObject[roomcode]) {
                 // If there is no existing data for the room, create a new entry
                 historyObject[roomcode] = { ...message };
             }
             // Update only the status of the existing entry
             historyObject[roomcode].name = message.name;
     */
    }
    if (update) { updatePublic(historyObject); }

}

// Function to set up subscriptions
function setupSubscriptions(channel, historyObject) {
    ['init_quiz', 'start_quiz', 'finished_quiz'].forEach(eventName => {
        channel.subscribe(eventName, (message) => {

            processMessage(message, historyObject, 1);
        });
    });
}


function engageUp() {
    sendQuizEngage(`${username} liked this`, {});
    Popup(`You liked this`);
    hideFeedback();
}

function engageBad() {
    sendQuizEngage(`${username} dislikes this`, {});
    Popup(`You disliked this`);
    hideFeedback();
}


function sanitizeId(id) {
    // Replace any non-alphanumeric character with a hyphen
    let sanitized = id.replace(/[^a-zA-Z0-9-]/g, '-');

    // If the first character is a digit, prefix with a letter (e.g., 'a')
    if (/^\d/.test(sanitized)) {
        sanitized = 'a' + sanitized;
    }

    return sanitized;
}

function shuffleArray(array) {
    // Check if any item is named "All of the above" or starts with 1, 2, 3, 4, a, b, c, or d regardless of case
    const hasAllOfTheAboveOrSpecialPrefix = array.some(item => {
        if (typeof item !== 'string') return false;
        const lowerItem = item.toLowerCase();
        return lowerItem === "none of the above" || lowerItem === "all of the above" || /^[1234abcd]/.test(lowerItem);
    });

    // Only shuffle the array if there is no "All of the above" item or special prefix
    if (!hasAllOfTheAboveOrSpecialPrefix) {
        let currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex !== 0) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }
    }

    return array;
}


function presentUsers() {
    let ucount = Object.values(present).reduce((sum, currentValue) => sum + currentValue, 0);

    return ucount;

}

function enableButton(buttonId) {
    const button = document.getElementById(buttonId);

    if (button) {
        button.classList.remove('button-disabled');
        button.disabled = false; // This line is optional if you are using the disabled attribute
    }
}

function disableButton(buttonId) {
    const button = document.getElementById(buttonId);
    if (button) {
        button.classList.add('button-disabled');
        button.disabled = false; // This line is optional if you are using the disabled attribute
    }
}

function hideElement(id) {
    const element = document.getElementById(id);
    if (element) {
        element.style.display = 'none';
    }
}

function showElement(id) {
    const element = document.getElementById(id);
    if (element) {
        element.style.display = 'inline-block';
    }
}

function showElementFlex(id) {
    const element = document.getElementById(id);
    if (element) {
        element.style.display = 'flex';
    }
}

function enableAsyncQuiz() {
    ['start-quiz', 'start-quiz-guest'].forEach(id => {
        hideElement(id);
    });
    ['start-async-quiz', 'start-async-quiz-guest'].forEach(id => {
        showElement(id);
    });

    enableButton('start-async-quiz');
    enableButton('start-async-quiz-guest');

}

function disableAsyncQuiz() {
    ['start-quiz', 'start-quiz-guest'].forEach(id => {
        hideElement(id);
    });
    ['start-async-quiz', 'start-async-quiz-guest'].forEach(id => {
        showElement(id);
    });

    disableButton('start-async-quiz');
    disableButton('start-async-quiz-guest');

}


function enableStartQuiz() {

    if (roomCode.startsWith('A')) {
        //        enableAsyncQuiz();
    }
    else if (roomCode.startsWith('V')) {

        // This is a voice quiz

        ['async-quiz', 'start-quiz', 'start-quiz-guest'].forEach(id => {
            hideElement(id);
        });

        ['start-voice-quiz', 'start-voice-quiz-guest'].forEach(id => {
            showElement(id);
        });

        
        if (presentUsers() < 2) {
            disableStartQuiz();
            return;
        }

        enableButton('start-voice-quiz');

        if (!present[current_owner_id]) {
            hideElement('start-quiz-wait-message');
        }
        else if (current_owner_id == compositeUsername) {
            showElement('start-voice-quiz-guest');
            enableButton('start-voice-quiz-guest');
            hideElement('start-quiz-wait-message');

        } else {
            hideElement('start-voice-quiz-guest');
            showElement('start-quiz-wait-message');

        }

    }
    else {

        ['async-quiz', 'start-voice-quiz', 'start-voice-quiz-guest'].forEach(id => {
            hideElement(id);
        });

        ['start-quiz', 'start-quiz-guest'].forEach(id => {
            showElement(id);
        });

        
        if (presentUsers() < 2) {
            disableStartQuiz();
            return;
        }

        enableButton('start-quiz');
        showElement('start-quiz-guest');

        if (!present[current_owner_id]) {
            hideElement('start-quiz-wait-message');

        }
        else if (current_owner_id == compositeUsername) {
            showElement('start-quiz-guest');
            enableButton('start-quiz-guest');
            hideElement('start-quiz-wait-message');

        } else {
            hideElement('start-quiz-guest');
            showElement('start-quiz-wait-message');

        }
    }


}

function disableStartQuiz() {

    if (present[current_owner_id]) {
        showElement('start-quiz-wait-message');

    } else {

        hideElement('start-quiz-wait-message');

    }

    disableButton('start-quiz');
    disableButton('start-quiz-guest');

    disableButton('start-voice-quiz');
    disableButton('start-voice-quiz-guest');

}


function removeAllUrlParams() {
    // Create a URL object based on the current location
    let url = new URL(window.location.href);

    // Clear the search parameters
    url.search = "";

    // Update the URL in the browser without reloading the page
    window.history.pushState(null, '', url.origin.toString());
}

function updateJoinQuizUrlParameter(parameter, roomCode) {

    if (!roomCode) { return; }
    // Create a URL object based on the current location
    let url = new URL(window.location.href);

    // Access the URL's search parameters
    let searchParams = url.searchParams;

    // Set or update the 'roomcode' parameter
    searchParams.set(parameter, roomCode);

    // Update the URL in the browser without reloading the page
    window.history.pushState({ screen: 'screen-wait-quiz', roomCode: roomCode }, '', url.origin.toString()+'/q/'+roomCode);
}


// Function to reset a specific div to its original state
function resetDivToOriginalState(divIdToReset) {
    const divToReset = document.getElementById(divIdToReset);
    const originalContent = divToReset.dataset.originalContent;

    if (divToReset && originalContent !== undefined) {
        divToReset.innerHTML = originalContent;
    }
}

function updateFamilyRoomName() {
    document.querySelectorAll('.family-room-name').forEach(room => {
        room.innerHTML = familyRoomName ? safer(familyRoomName) : '<span style="color:#ddd">Join a sharing channel</span>';
    });

    document.getElementById('new-familyroom').value = safer(familyRoomName);
}

// Handle state changes
window.addEventListener('popstate', function(event) {
    console.log('are we changing screens?');
    console.log(event);
    if (event.state && event.state.screen) {
      // Show the screen based on the state
      console.log('yes we are changing screens');
      if (event.state.screen == 'screen-wait-quiz') {
        joinQuiz(event.state.roomCode);
      }
      else if (event.state.screen == 'screen-quiz-list') {
        listQuiz();
      }
      else if (event.state.screen == 'qotd') {
        startQotdQuiz(0);
      }
      else if (event.state.screen == 'qotd-news') {
        startQotdNewsQuiz(0);
      }
      else if (event.state.screen == 'screen-create-quiz') {
        newQuiz(0);
      }
      else {
          showScreen(event.state.screen);
      }  
    }
});
  


async function showScreen(divIdToShow) {

  //  if (screensToAddToHistory.includes(divIdToShow)) {
    //    // Push the new state into the history
      //  console.log('pushing to state');
        // history.pushState({ screen: divIdToShow }, `Qaiz`, ''); //, `/${screen}`);
   // }

      
    removeCountdown();
    
    if (!(divIdToShow == "screen-quiz-question")) {
        inQuiz = 0;
    }

    if (divIdToShow == "screen-own-quiz" || divIdToShow == "screen-wait-quiz" || divIdToShow == "screen-quiz-question") {

    } 
    else {
        deinitializeMicrophone();
    }

    // Hide all divs with IDs starting with "screen"
    const allDivs = document.querySelectorAll("div[id^='screen']");
    allDivs.forEach(div => {
        div.style.display = 'none'; // Hides the div
    });

    resetDivToOriginalState(divIdToShow);

    if (is_subscribed == 0 && thisUser.anonymous) {
        document.getElementById('ad-link-account').style.display = 'block';
        document.getElementById('ad-link-sub').style.display = 'none';

    }
    else if (is_subscribed == 0) {
        document.getElementById('ad-link-account').style.display = 'none';
        document.getElementById('ad-link-sub').style.display = 'block';

    }
    else {
        document.getElementById('ad-link-account').style.display = 'none';
        document.getElementById('ad-link-sub').style.display = 'none';

    }

    window.removeEventListener('scroll', handleScroll);

    setupButtons();
    updateDetails();

    if (divIdToShow == "screen-create-quiz") {

        clearUpload();
        updatePublic(familyHistory);
        updateFamilyRoomName();

        if (chatroom) {
            soundOff();
        }
        //        document.getElementById('header-sound-control').style.display = 'none';

        if (earlyuid || uid) {
            suggestIdeas();
        }
    }

    if (divIdToShow == "screen-subscribe") {
        if (!thisUser.anonymous) {

            const pricingTable = document.querySelector('stripe-pricing-table');
            pricingTable.setAttribute('client-reference-id', thisUser.uid);
            pricingTable.setAttribute('customer-email', thisUser.email);

        }

        if (chatroom) {
            soundOff();
        }
        //        document.getElementById('header-sound-control').style.display = 'none';

    }


    if (divIdToShow == "screen-create-avatar") {

        if (chatroom) {
            soundOff();
        }
        //        document.getElementById('header-sound-control').style.display = 'none';

    }

    let qT = document.getElementById('qotd-topic');

    if (qT && qotd.meta) {
        qT.textContent = qotd.meta.topic;
    }

    document.getElementById('edit-lock').style.display = is_subscribed ? 'none' : 'inline-block';

    // Show the specific div
    document.getElementById(divIdToShow).style.display = 'block'; // Shows the div

    gtag('event', divIdToShow, {});

    currentScreen = divIdToShow;
    window.scrollTo(0, 0);
    //    document.getElementById("topic-input").focus();

}


function resetCountdownBar() {
    const countdownContainer = document.getElementById('countdown-container');
    countdownContainer.innerHTML = originalCountdownHTML;

    const newCountdownBarInner = document.getElementById('countdown-inner-bar');
    newCountdownBarInner.style.width = '100%';

    // Force a reflow
    const newCountdownBar = document.getElementById('countdown-bar');
    newCountdownBar.style.transition = 'width 14s linear';

    void newCountdownBar.offsetWidth;

}

function wrapUpCountdown() {
    // Adjust the countdown bar
    var countdownBar = document.getElementById('countdown-bar');
    var currentWidth = window.getComputedStyle(countdownBar).width;
    countdownBar.style.width = currentWidth;
    countdownBar.style.transition = 'none';
    countdownBar.offsetWidth;
    countdownBar.style.transition = 'width 3s linear';

    setTimeoutWorker(() => countdownBar.style.width = '100%', 10);

    // Clear the existing question timeout
    clearTimeoutWorker(questionTimeout);

    speedUpFullscreenCountdown();

    // Set a new timeout to wrap up the question in 5 seconds
    questionTimeout = setTimeoutWorker(() => {

        wrapQuestion(
            roomCode.startsWith('V') ? 2 : qotd,
            roomCode.startsWith('V') ? 1 : 0
        );
        
    }, 3000); // 5 seconds

}



function sizeDependentText(element, text) {
    // Set the text content of the element
    element.textContent = text;

    // Determine the font size based on the text length
    const maxLength = 100; // Define a max length for the smallest font size
    const minLength = 10; // Define a min length for the largest font size

    // Calculate the font size
    let fontSize;
    if (text.length < minLength) {
        fontSize = 1.6; // Use the largest font size for short texts
    } else if (text.length > maxLength) {
        fontSize = 1.35; // Use the smallest font size for long texts
    } else {
        // Calculate the font size proportionally between 1.2em and 1.5em
        const range = 1.6 - 1.35; // The range between the smallest and largest sizes
        const scale = (text.length - minLength) / (maxLength - minLength);
        fontSize = 1.6 - (range * scale);
    }

    // Set the font size of the element
    element.style.fontSize = `${fontSize}em`;

}

function addCirclesToOptions(scoresForQuestion, totalDuration = 1000) {  // Default total duration is 1000ms

    if (typeof scoresForQuestion !== 'object' || scoresForQuestion === null) {
        //     console.error('Invalid scores data:', scoresForQuestion);
        return; // Exit the function if the data is not valid
    }

    Object.keys(scoresForQuestion).forEach(option => {
        const liElement = document.querySelector('#option-' + sanitizeId(option));

        if (liElement) {
            liElement.style.animation = 'none';

            let wrapper = liElement.parentNode.classList.contains('option-wrapper') ?
                liElement.parentNode : wrapElement(liElement);

            const users = scoresForQuestion[option];
            const interval = totalDuration / Math.min(users.length, 3);  // Adjust interval for up to 3 circles

            users.forEach((user, index) => {
                setTimeout(() => {
                    if (index < 2) {  // Add first two circles normally
                        const circle = createCircle(user);
                        wrapper.appendChild(circle);
                    } else if (index === 2) {  // Add the third circle as a counter
                        const circle = createCircle(user, users.length - 2);
                        wrapper.appendChild(circle);
                    }  // Additional users beyond the third do not create more circles
                }, interval * Math.min(index, 2));  // Cap index at 2 for timing calculation
            });
        } else {
            //            console.error('No element found for option:', option);
        }
    });
}

function createCircle(user, additionalCount = 0) {
    let username = user.username;
    let avatarUrl = user.avatarUrl;

    const circle = document.createElement('span');
    circle.className = 'player-circle';

    // Choose the content based on whether there is an avatar URL
    if (avatarUrl) {
        const img = document.createElement('img');
        img.src = avatarUrl;
        img.style.width = '100%';
        img.style.height = '100%';
        img.style.borderRadius = '50%'; // Ensure the image is round
        img.style.objectFit = 'cover'; // Cover the area without distortion
        circle.appendChild(img);
    } else {
        const initial = username[0].toUpperCase();
        circle.textContent = additionalCount > 0 ? formatCounter(additionalCount) : initial;

        // Set color based on the initial letter
        const color = letterColors[initial] || '#d6d6d6'; // Default color if no match
        circle.style.backgroundColor = color;
        circle.style.boxShadow = `0 0px 18px ${darkenColor(color)}`; // Shadow is darker version of the color
    }

    return circle;
}


// Function to darken a color by reducing luminance
function darkenColor(hex, amount = 50) {
    let color = hex.substring(1); // Remove #
    let rgb = parseInt(color, 16); // Convert to decimal
    let r = Math.max(0, (rgb >> 16) - amount);
    let g = Math.max(0, ((rgb >> 8) & 0x00FF) - amount);
    let b = Math.max(0, (rgb & 0x00FF) - amount);
    return "#" + (0x1000000 + r * 0x10000 + g * 0x100 + b).toString(16).slice(1);
}

// Helper function to format the counter text
function formatCounter(count) {
    if (count > 9) {
        return "++";  // Use "+" if the count exceeds 9
    } else {
        return "+" + count.toString();  // Otherwise, show the count
    }
}

// Ensures the li element is wrapped in a div, if not already
function wrapElement(liElement) {
    if (!liElement.parentNode.classList.contains('option-wrapper')) {
        const wrapper = document.createElement('div');
        wrapper.className = 'option-wrapper';
        liElement.parentNode.insertBefore(wrapper, liElement);
        wrapper.appendChild(liElement);
    }
    return liElement.parentNode;
}

function initializeDots(container, numberOfDots) {
    container.innerHTML = ''; // Clear existing content
    for (let i = 0; i < numberOfDots; i++) {
        const dot = document.createElement('div');
        dot.className = 'wrap-dot';
        container.appendChild(dot);
    }
}

function startTimer(containerId, duration) {
    const container = document.getElementById(containerId);
    if (!container) {
        console.error('Container not found');
        return;
    }

    initializeDots(container, 10); // Initialize 10 dots in the container

    let dots = container.children;
    let interval = duration / dots.length;
    let currentDot = 0;

    // Clear any existing timer
    if (container.timerId) {
        clearInterval(container.timerId);
    }

    container.timerId = setInterval(() => {
        if (currentDot >= dots.length) {
            clearInterval(container.timerId);
            return;
        }
        dots[currentDot].style.backgroundColor = '#ccc'; // Change to dark gray
        currentDot++;
    }, interval);
}

// Example usage:
// startTimer('dot-container', 5000);


function setupMicrophone() {
    // Ask for microphone permission
    navigator.mediaDevices.getUserMedia({ audio: true })
        .then(function (stream) {

            initializeMicrophone(stream);

            document.querySelectorAll('.microphone-ok').forEach(element => {
                element.style.display = 'flex';
            });
            microphone_status = 1;
            if (quizChannel) {
                quizChannel.presence.enter({ nametag: username, awardtag: thisUser.award, award: thisUser.award, avatarUrl: userAvatarUrl });
            }
        })
        .catch(function (err) {
            console.error('Microphone access denied:', err);
            document.querySelectorAll('.microphone-not-ok').forEach(element => {
                element.style.display = 'flex';
            });

            microphone_status = 0;
        });
}

function initializeMicrophone(stream) {
    // Create a new MediaRecorder instance
    window.mediaRecorder = new MediaRecorder(stream);
    window.mediaStream = stream;
    window.recordedChunks = [];

    // Set up audio context and analyzer
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const source = audioContext.createMediaStreamSource(stream);
    const analyzer = audioContext.createAnalyser();
    analyzer.fftSize = 256; //256;
    source.connect(analyzer);

    // Event listener for when data is available
    window.mediaRecorder.ondataavailable = function (event) {
        if (event.data.size > 0) {
            window.recordedChunks.push(event.data);
        }
    };

    // Event listener for when recording stops
    window.mediaRecorder.onstop = function () {
        const recordedBlob = new Blob(window.recordedChunks, { type: 'audio/webm' });

        handleVoiceInput(recordedBlob);
    };

    // Function to continuously update microphone visual
    function continuouslyUpdateMicVisual() {
        const dataArray = new Uint8Array(analyzer.frequencyBinCount);
        analyzer.getByteFrequencyData(dataArray);

        // Calculate average volume
        const average = dataArray[0]; // dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length;
        
        // Normalize to 0-1 range
        const normalizedVolume = average / 255;

        // Update visual (adjust parameters as needed)
        updateMicrophoneVisual(normalizedVolume, {
            minSize: 30,
            maxSize: 68,
            minInput: 0,
            maxInput: 1
        });

        // Request next animation frame
        requestAnimationFrame(continuouslyUpdateMicVisual);
    }

    // Start updating the microphone visual
    continuouslyUpdateMicVisual();
}
// Global variables to store references
let blobContainer = null;
let blob = null;
function createAndAddBlob(width = 300, height = 300) {
    const listener = document.getElementById('listener');
    if (!listener) {
      console.error("Element with id 'listener' not found");
      return;
    }
  
    blobContainer = document.createElement('div');
    blobContainer.id = 'blob-container';
  
    blob = document.createElement('div');
    blob.id = 'blob';
  
    blob.style.width = `${width}px`;
    blob.style.height = `${height}px`;
  
    // Generate random starting points for animations
    const morphStart = Math.random() * -100;
    const move1Start = Math.random() * -100;
    const move2Start = Math.random() * -100;
    const move3Start = Math.random() * -100;
  
    // Apply random starting points
    blob.style.animationDelay = `${morphStart}s`;
  
    for (let i = 1; i <= 3; i++) {
      const element = document.createElement('div');
      element.className = 'gradient-element';
      element.id = `element${i}`;
      
      // Apply random starting point to each element
      element.style.animationDelay = `${eval(`move${i}Start`)}s`;
      
      blob.appendChild(element);
    }
  
    blobContainer.appendChild(blob);
    listener.appendChild(blobContainer);
}

// Function to remove the blob
function removeBlob() {
    if (blobContainer && blobContainer.parentNode) {
      blobContainer.parentNode.removeChild(blobContainer);
    }
    blobContainer = null;
    blob = null;
  }

  

function updateMicrophoneVisual(xinputLevel, options = {}) {
    let inputLevel = xinputLevel;

    const {
        minSize = 48,
        maxSize = 68,
        minInput = 0,
        maxInput = 1,
        stepSize = 4,
        threshold = 0,
    } = options;

    // Function to check if an element is visible
    function isVisible(element) {
        return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
    }

    // Find all .mic-background elements and filter to get the visible one
    const elements = document.querySelectorAll('.mic-background');
    const visibleElement = Array.from(elements).find(isVisible);

    if (!visibleElement) {
//        console.warn('No visible .mic-background element found');
        return;
    }
    
    if (inputLevel<0.2) { inputLevel = 0; }
    else { inputLevel -= 0.2 }

    const normalizedLevel = (inputLevel - minInput) / (maxInput - minInput);
    const clampedLevel = Math.max(0, Math.min(normalizedLevel, 1));

    let newSize = minSize + (clampedLevel * (maxSize - minSize));
    newSize = Math.round(newSize / stepSize) * stepSize;

    const currentSize = parseFloat(visibleElement.style.width) || minSize;

    if (Math.abs(newSize - currentSize) >= threshold * (maxSize - minSize)) {
    //    if (newSize > currentSize) {
  //          visibleElement.style.transition = 'all 0.05s ease'; // Smooth transition for increasing size
      //  } else {
            visibleElement.style.transition = 'none'; // Immediate snap back for decreasing size
      //  }
        visibleElement.style.width = `${newSize}px`;
        visibleElement.style.height = `${newSize}px`;
    }
}

function deinitializeMicrophone() {
    console.log('De-initializing microphone...');
    if (window.mediaRecorder) {
        window.mediaRecorder.stop();
        window.mediaRecorder = null;
    }
    if (window.mediaStream) {
        window.mediaStream.getTracks().forEach(track => track.stop());
        // Adding a slight delay to ensure tracks are fully stopped
        setTimeout(() => {
            window.mediaStream = null;
        }, 100); // Adjust the delay as necessary (100ms is usually sufficient)
    }
}


function startRecording() {
    console.log('Recording started...');
    if (window.mediaRecorder) {
        window.recordedChunks = [];
        window.mediaRecorder.start();
    }

    const micActiveElement = document.getElementById('mic-active');
    if (micActiveElement) {
        micActiveElement.classList.add('mic-listening');  // Replace 'new-class' with the actual class name you want to add
    }
    changeCountdownColor('#802812');

    
}

// Ensure this is defined outside or is accessible within PopupComment
var preloadedReply = new Image();
preloadedReply.src = "/assets/girl-q2-s.png";

function micInjectLoader() {
    // Assuming `currentCorrectAnswer` and `preloadedReply.src` are defined elsewhere in your script
    const listenerElement = document.getElementById('listener');
    
    // Inject new HTML before the 'listener' element
    listenerElement.insertAdjacentHTML('beforebegin', `
        <div class="mic-correct-answer" id="mic-correct-answer">
            <span class="circle-html" style="margin-left:0px!important; right:20px; ">
            <img src="${preloadedReply.src}" alt="" width="30" height="36" style="transform:scaleX(-1)">
          </span>
          <span class="evaluation-answer">
            <b>Correct Answer:</b><br/>
            <i>"${currentCorrectAnswer}"</i>
          </span>

        </div>
    `);

    // Replace the innerHTML of the 'listener' element to show the loader
    listenerElement.innerHTML = `
        <div id="mic-checking-answer">
          <div class="mic-checking-info">Checking answer...</div>
          <div class="loading-indicator">
            <div class="mic-dot"></div>
            <div class="mic-dot"></div>
            <div class="mic-dot"></div>
            <div class="mic-dot"></div>
          </div>
        </div>
    `;
}


function stopRecordingAndStoreInput() {
    console.log('Recording stopped. Storing input...');
    if (window.mediaRecorder) {
        window.mediaRecorder.stop();
    }

    micInjectLoader();

    changeCountdownColor('#000000');

}


async function submitAudioAnswer(uint8Array) {
    const url = '/quick-voice'; // Adjust this if your function path is different

    try {
        let evaluation;

        if (countdownTimer) {

            freezeInnerBarWidth();

            currentQuiz.lastTime = parseFloat((elapsedSeconds).toFixed(2));
            clearTimeout(countdownTimer); // Clear the setTimeout to stop the timer

        }

        if (uint8Array) {
            // Convert Uint8Array back to Blob
            const audioBlob = new Blob([uint8Array], { type: 'audio/mp3' });

            // Create a FormData object
            const formData = new FormData();
            formData.append('audio', audioBlob, 'audio.mp3');
            formData.append('question', currentAskedQuestion);
            formData.append('answerkey', currentCorrectAnswer);
            formData.append('questionindex', currentQuestionIndex
            );

            const response = await fetch(url, {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();

            console.log(result);
            
            // Process the result as needed
    //        const evaluation = JSON.parse(result.evaluation);
            evaluation = result.evaluation;
            evaluation.time = currentQuiz.lastTime;
            evaluation.questionindex = result.questionindex;

        }
        else {
            evaluation = {"assessment": "[No meaningful sounds heard]", 
                          "transcribed": "",
                          "failed_audio": 1,
                        };

            Popup("I couldn't hear that");
        }
        
        if (evaluation.assessment == "Correct" && evaluation.questionindex == currentQuestionIndex) {

            currentQuiz.correct++;
            currentQuiz.no_attempt--;

            // Only count total time spent for correct answers
            // It's meant to differentiate in draws 
            currentQuiz.time += currentQuiz.lastTime;

            currentQuestion.push({
                result: "correct",
                time: currentQuiz.lastTime,
                name: username,
                avatarUrl: userAvatarUrl,
                id: compositeUsername,
                answer: evaluation.transcribed
            });

            currentDetails.push({
                result: "correct",
                time: currentQuiz.lastTime,
                answer: evaluation.transcribed
            })

            sendUpdate(`${username} answered "${evaluation.transcribed}"`, { name: username, result: "correct", currentQuestionIndex: currentQuestionIndex, roomCode: roomCode, award: thisUser.award, avatarUrl: userAvatarUrl, question_type: 'audio', answer: evaluation.transcribed, evaluation, time: currentQuiz.lastTime });

        } else {
            currentQuiz.wrong++;
            currentQuiz.no_attempt--;

            document.getElementById('countdown-inner-bar').classList.add('countdown-bar-wrong');

            currentQuestion.push({
                result: "wrong",
                time: currentQuiz.lastTime,
                name: username,
                avatarUrl: userAvatarUrl,
                id: compositeUsername,
                answer: evaluation.transcribed
            });

            currentDetails.push({
                result: "wrong",
                time: currentQuiz.lastTime,
                answer: evaluation.transcribed
            })
            
            if (evaluation.failed_audio == 1) {
                sendUpdate(`${username} answered blank`, { name: username, result: "wrong", currentQuestionIndex: currentQuestionIndex, roomCode: roomCode, award: thisUser.award, avatarUrl: userAvatarUrl, answer: evaluation.transcribed, evaluation, question_type: 'audio', time: null });
            }
            else {
                sendUpdate(`${username} answered "${evaluation.transcribed}"`, { name: username, result: "wrong", currentQuestionIndex: currentQuestionIndex, roomCode: roomCode, award: thisUser.award, avatarUrl: userAvatarUrl, answer: evaluation.transcribed, evaluation, question_type: 'audio', time: null });

            }
        }

        if (showing_results) {
            document.getElementById('next-results').insertAdjacentHTML('afterbegin', `<span style="transform: scale(1.1);display:block;">
            ${injectAudioAnswer(evaluation, { "username": username, "avatarUrl": userAvatarUrl, senderClientId: compositeUsername })}
            </span>
            `);
        }
        else {
            document.getElementById('mic-checking-answer').remove();
            removeBlob();

            document.getElementById('listener').innerHTML += `
            <span style="transform: scale(1.1); display:block;">
            ${injectAudioAnswer(evaluation, { "username": username, "avatarUrl": userAvatarUrl, senderClientId: compositeUsername })}
            </span>
            `;
        
            document.getElementById('listener').innerHTML += collected_results;
            showing_results = 1;
        }

        
        if (evaluation.comment) {
            closePopup(commentPopup);
    
            setTimeout(function () {
                PopupComment(evaluation.comment);
            }, 500); // 500 milliseconds = 0.5 seconds
        }
        

    } catch (error) {
        console.error('Error submitting audio:', error);
        // Handle the error (e.g., show an error message to the user)
    }

}

function hasLowestTime(clientId) {
    // Step 1: Filter out entries that don't have result equal to "correct"
    const correctEntries = currentQuestion.filter(entry => entry.result === "correct");
  
    // Step 2: Extract all the times from the filtered entries
    const times = correctEntries.map(entry => entry.time);
  
    // Step 3: Find the minimum time
    const minTime = Math.min(...times);
  
    // Step 4: Find the entry with the given clientId and check its time
    const clientEntry = correctEntries.find(entry => entry.id === clientId);
  
    // Return false if the clientId is not found or no correct entries exist
    if (!clientEntry || correctEntries.length === 0) {
      return false;
    }
  
    return clientEntry.time === minTime;
  }

function injectAudioAnswer(answer, user) {
    
    let circleHTML = createCircle(user);
    // Find the sub-element with class 'player-circle'

    circleHTML.classList.replace('player-circle', 'audio-player-circle');

    let eval_symbol = answer.assessment == "Correct" 
                ? '<span style="margin-right: 5px; size: 10px; color:green">&#x2713;</span>' 
                : '<span style="margin-right: 5px; size: 10px;">❌</span>';

                console.log(user.senderClientId);
    eval_symbol = hasLowestTime(user.senderClientId) && answer.assessment == "Correct" ? '🏆' : eval_symbol;

    // Truncate answer.transcribed to 60 characters and add ellipsis if it was longer
    let displayedTranscription = answer.transcribed.slice(0, 60);
    if (answer.transcribed.length > 60) {
        displayedTranscription += "...";
    }

    return `
<div class="mic-given-answer" style="display:flex; text-align:left;">
  <span class="evaluation">${eval_symbol}</span>
  <span class="evaluation-answer">
    <b>${user.username}</b> ${ answer.time ? '<span style="margin-right: 5px; font-size: 11px;">&#x231B;'+answer.time.toFixed(2)+'</span>' : ""}<br/>
    <i>"${displayedTranscription}" </i>
  </span>
  <span class="circle-html" style="margin-left:0px!important; right:20px;">${circleHTML.outerHTML}</span>
</div>
    `;
}


async function handleVoiceInput(recordedBlob) {
    
    let processedBlob;
    try {
        processedBlob = await processVoiceInput(recordedBlob);
    } catch (error) {
        console.error("Error processing voice input:", error);
        submitAudioAnswer(0);
        return;
    }

    if (processedBlob) {
        const arrayBuffer = await processedBlob.arrayBuffer();
        const uint8Array = new Uint8Array(arrayBuffer);

        submitAudioAnswer(uint8Array);
    } else {
        submitAudioAnswer(0);
    }
}

// Rest of the code remains unchanged


function normalizeAudio(audioBuffer) {
    const channels = audioBuffer.numberOfChannels;
    const length = audioBuffer.length;
    let maxAmplitude = 0;

    // Find the maximum amplitude
    for (let channel = 0; channel < channels; channel++) {
        const channelData = audioBuffer.getChannelData(channel);
        for (let i = 0; i < length; i++) {
            const absValue = Math.abs(channelData[i]);
            if (absValue > maxAmplitude) {
                maxAmplitude = absValue;
            }
        }
    }

    // Normalize if the max amplitude is not 1
    if (maxAmplitude > 0 && maxAmplitude !== 1) {
        const scaleFactor = 1 / maxAmplitude;

        // Create a new AudioBuffer for the normalized data
        const normalizedBuffer = new AudioBuffer({
            length: length,
            numberOfChannels: channels,
            sampleRate: audioBuffer.sampleRate
        });

        for (let channel = 0; channel < channels; channel++) {
            const channelData = audioBuffer.getChannelData(channel);
            const normalizedData = normalizedBuffer.getChannelData(channel);
            for (let i = 0; i < length; i++) {
                normalizedData[i] = channelData[i] * scaleFactor;
            }
        }

        return normalizedBuffer;
    }

    return audioBuffer;
}

// Additional function to manually boost the volume
function boostVolume(audioBuffer, boostFactor) {
    const channels = audioBuffer.numberOfChannels;
    const length = audioBuffer.length;

    const boostedBuffer = new AudioBuffer({
        length: length,
        numberOfChannels: channels,
        sampleRate: audioBuffer.sampleRate
    });

    for (let channel = 0; channel < channels; channel++) {
        const channelData = audioBuffer.getChannelData(channel);
        const boostedData = boostedBuffer.getChannelData(channel);
        for (let i = 0; i < length; i++) {
            boostedData[i] = channelData[i] * boostFactor;
        }
    }

    return boostedBuffer;
}

async function processVoiceInput(recordedBlob) {

    const arrayBuffer = await recordedBlob.arrayBuffer();
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    
    let audioBuffer;
    try {
        audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    } catch (error) {
        console.error("Error decoding audio data:", error);
        throw error;
    }

    const normalizedBuffer = normalizeAudio(audioBuffer);

    // Apply a manual boost if needed (e.g., 2x boost)
    const boostFactor = 2;
    const boostedBuffer = boostVolume(normalizedBuffer, boostFactor);

    const isSignificantAudio = checkAudio(boostedBuffer);
    if (!isSignificantAudio) {
        console.log('No significant audio detected');
        return null;
    }

    const speededBuffer = speedupAudio(boostedBuffer, mp3speedFactor);

    let compressedBlob;
    try {
        compressedBlob = await compressToMp3(speededBuffer);

        
//        logBlobAsRawData(compressedBlob);  // Log the compressed Blob as raw data
   
    } catch (error) {
        console.error("Error compressing to MP3:", error);
        throw error;
    }

    return compressedBlob;
}

function logBlobAsRawData(blob) {
    const reader = new FileReader();
    reader.onloadend = function() {
        const arrayBuffer = reader.result;
        const uint8Array = new Uint8Array(arrayBuffer);

    };
    reader.readAsArrayBuffer(blob);
}

function checkAudio(audioBuffer) {
    const channelData = audioBuffer.getChannelData(0);
    const duration = audioBuffer.duration;
    const averageAmplitude = channelData.reduce((sum, value) => sum + Math.abs(value), 0) / channelData.length;
    const silenceThreshold = 0.01;

    if (duration < 0.35) {
        console.log('Audio too short');
        return false;
    } else if (averageAmplitude < silenceThreshold) {
        console.log('Audio mostly silent');
        return false;
    }

    return true;
}

function speedupAudio(audioBuffer, speedFactor) {
    const channels = audioBuffer.numberOfChannels;
    const newLength = Math.floor(audioBuffer.length / speedFactor);
    const speededBuffer = new AudioBuffer({
        length: newLength,
        numberOfChannels: channels,
        sampleRate: audioBuffer.sampleRate
    });

    for (let channel = 0; channel < channels; channel++) {
        const inputData = audioBuffer.getChannelData(channel);
        const outputData = speededBuffer.getChannelData(channel);
        for (let i = 0; i < newLength; i++) {
            outputData[i] = inputData[Math.floor(i * speedFactor)];
        }
    }

    return speededBuffer;
}

async function compressToMp3(audioBuffer) {
    const channels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;

    console.log(`Compressing to MP3: ${channels} channels, ${sampleRate} sample rate`);

    const lame = new lamejs.Mp3Encoder(channels, sampleRate, 128); // Ensure correct bitrate
    const mp3Data = [];
    const sampleBlockSize = 1152;

    for (let i = 0; i < audioBuffer.length; i += sampleBlockSize) {
        const samplesLeft = (i + sampleBlockSize > audioBuffer.length) ? audioBuffer.length - i : sampleBlockSize;

        const buffers = [];
        for (let c = 0; c < channels; c++) {
            const floatSamples = audioBuffer.getChannelData(c).subarray(i, i + samplesLeft);
            const intSamples = new Int16Array(floatSamples.length);

            for (let j = 0; j < floatSamples.length; j++) {
                intSamples[j] = Math.max(-32768, Math.min(32767, floatSamples[j] * 32767)); // Ensure proper scaling to 16-bit PCM
            }

            buffers.push(intSamples);
        }

        let mp3buf;
        if (channels === 2) {
            mp3buf = lame.encodeBuffer(buffers[0], buffers[1]);
        } else {
            mp3buf = lame.encodeBuffer(buffers[0]);
        }

        if (mp3buf.length > 0) {
            mp3Data.push(new Int8Array(mp3buf));
        }
    }

    const remainingBytes = lame.flush();
    if (remainingBytes.length > 0) {
        mp3Data.push(new Int8Array(remainingBytes));
    }

    const mp3Blob = new Blob(mp3Data, { type: 'audio/mp3' });

    return mp3Blob;
}

function changeCountdownColor(newColor) {
    const countdownBars = document.querySelectorAll('.countdown-bar');
    countdownBars.forEach(bar => {
        bar.style.borderColor = newColor; // Change the border color
    });
}


function startFullscreenCountdown() {
    // Check if the countdown already exists, and remove it if it does
    removeCountdown();

    // Create the container with an identifier
    const container = document.createElement('div');
    container.setAttribute('id', 'fullscreen-countdown-container');
    container.style.position = 'fixed';
    container.style.top = '0';
    container.style.left = '0';
    container.style.width = window.innerWidth + 'px'; // Use actual window width
    container.style.height = window.innerHeight + 'px'; // Use actual window height
    container.style.zIndex = '99'; // High z-index to cover everything
    container.style.overflow = 'hidden';
    container.style.pointerEvents = 'none'; // Allow clicks to pass through

    // Create the countdown bars
    const bottomLeftBar = document.createElement('div');
    const topRightBar = document.createElement('div');
    bottomLeftBar.className = 'countdown-bar bottom-left';
    topRightBar.className = 'countdown-bar top-right';

    // Add bars to the container
    container.appendChild(bottomLeftBar);
    container.appendChild(topRightBar);

    // Append the container to the body
    document.body.appendChild(container);

    // Add CSS for countdown bars
    const style = document.createElement('style');
    style.setAttribute('id', 'fullscreen-countdown-style');
    style.textContent = `
        .countdown-bar {
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: black; /* Adjust the color here */
            animation: grow-bar 18s linear forwards; /* Adjust time as needed */
        }
        .bottom-left {
            bottom: 0;
            left: 0;
            border-width: 0 0 7px 7px; /* Adjust thickness here */
        }
        .top-right {
            top: 0;
            right: 0;
            border-width: 7px 7px 0 0; /* Adjust thickness here */
        }
        @keyframes grow-bar {
            0% {
                width: 0;
                height: 0;
            }
            100% {
                width: ${window.innerWidth}px;
                height: ${window.innerHeight}px;
            }
        }
    `;
    document.head.appendChild(style);

    // Start the animation
    bottomLeftBar.style.width = "100vw";
    bottomLeftBar.style.height = "100vh";
    topRightBar.style.width = "100vw";
    topRightBar.style.height = "100vh";
}

function speedUpFullscreenCountdown() {
    const container = document.getElementById('fullscreen-countdown-container');
    if (!container) return; // Exit if the countdown doesn't exist

    const bottomLeftBar = container.querySelector('.bottom-left');
    const topRightBar = container.querySelector('.top-right');

    if (!bottomLeftBar || !topRightBar) return; // Exit if bars are not found

    // Get current progress
    const currentWidth = bottomLeftBar.offsetWidth;
    const currentHeight = bottomLeftBar.offsetHeight;
    const totalWidth = window.innerWidth;
    const totalHeight = window.innerHeight;

    // Calculate remaining progress
    const remainingWidth = totalWidth - currentWidth;
    const remainingHeight = totalHeight - currentHeight;

    // Create a new keyframe animation
    const keyframes = `
        @keyframes speed-up-grow {
            from {
                width: ${currentWidth}px;
                height: ${currentHeight}px;
            }
            to {
                width: ${totalWidth}px;
                height: ${totalHeight}px;
            }
        }
    `;

    // Add the new keyframes to the document
    const styleElement = document.createElement('style');
    styleElement.textContent = keyframes;
    document.head.appendChild(styleElement);

    // Apply the new animation to both bars
    [bottomLeftBar, topRightBar].forEach(bar => {
        bar.style.animation = 'none'; // Reset the animation
        bar.offsetHeight; // Trigger reflow
        bar.style.animation = 'speed-up-grow 3s linear forwards';
    });

    // Remove the countdown after 3 seconds
    setTimeout(removeCountdown, 3000);
}

// Make sure to keep your existing removeCountdown function
function removeCountdown() {
    const container = document.getElementById('fullscreen-countdown-container');
    const style = document.getElementById('fullscreen-countdown-style');
    if (container) container.remove();
    if (style) style.remove();
}

function removeCountdown() {
    const countdownContainer = document.getElementById('fullscreen-countdown-container');
    const countdownStyle = document.getElementById('fullscreen-countdown-style');
    if (countdownContainer) {
        countdownContainer.parentNode.removeChild(countdownContainer);
    }
    if (countdownStyle) {
        countdownStyle.parentNode.removeChild(countdownStyle);
    }
}

// To start, call startFullscreenCountdown()
// To remove, call removeCountdown()

// You can call startFullscreenCountdown() wherever it needs to run in your app.


// Example usage:
// Assuming you have an AudioBuffer object named 'audioBuffer'
// const compressedBlob = await compressToMp3(audioBuffer);

function audioQuestion(questionIndex, qotd) {
    closePopup(commentPopup);
    closeAllModals();

    const questionNumber = document.getElementById('question-number');
    const questionElement = document.getElementById('question');
    document.getElementById('feedback-container').innerHTML = `<div id="listener"></div>`;

    const listener = document.getElementById('listener');
    listener.innerHTML = '';

    createAndAddBlob(100, 100);

    collected_results = `<!-- span class="mic-other-results">awaiting other answers</span -->
    <div id="next-results"></div>
    <div class="site-loading-indicator" id="mic-loader-waiting">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
</div>
    `;
    showing_results=0;

    currentQuestion = [];

    
    if (questionIndex < questions.length) {
        const question = questions[questionIndex];
        sizeDependentText(questionElement, question.question);

        currentCorrectAnswer = question.correct;
        currentAskedQuestion = question.question;

        questionNumber.textContent = `QUESTION ${questionIndex + 1} of ${gameTotalQuestions}`;

        // Create microphone symbol
        const micContainer = document.createElement('div');
        micContainer.id = 'mic-setup';

        micContainer.classList.add('mic-icon-container', 'mic-scale-up');
        const micBG = document.createElement('div');
        micBG.classList.add('mic-background', 'mic-waiting');
        micBG.id = 'mic-active';  // Set the ID

        micContainer.appendChild(micBG);

        const micIcon = document.createElement('i');
        micIcon.classList.add('fa', 'fa-microphone', 'mic-icon');
        micContainer.appendChild(micIcon);
        listener.appendChild(micContainer);

        let isRecording = false;

        function startInteraction(event) {
            event.preventDefault();
            isRecording = true;
            startRecording();
            document.body.style.userSelect = 'none';
            document.addEventListener('mouseup', endInteraction);
            document.addEventListener('touchend', endInteraction, { passive: false });
        }

        function endInteraction(event) {
            event.preventDefault();
            if (isRecording) {
                isRecording = false;
                stopRecordingAndStoreInput();
            }
            document.body.style.userSelect = 'auto';
            document.removeEventListener('mouseup', endInteraction);
            document.removeEventListener('touchend', endInteraction);
        }

        micContainer.addEventListener('mousedown', startInteraction);
        micContainer.addEventListener('touchstart', startInteraction, { passive: false });

        // Update the remaining code
//        resetCountdownBar();
        removeCountdown();

        currentQuiz.lastTime = questionShowTime;
        currentQuiz.no_attempt++;
        currentQuiz.asked++;

        if (questionIndex + 1 == gameTotalQuestions) {
            PopupComment('Last question!');
        }

//        showFeedback();

    } else {
        wrapQuiz(2);
        return;
    }

    // Start countdown animation
//    const countdownBar = document.getElementById('countdown-bar');
  //  countdownBar.style.width = '100%';

   document.getElementById('countdown-container').style.display = "none";
   startFullscreenCountdown();
 
   // Wait for questionShowTime seconds before showing the next question
    questionTimeout = setTimeoutWorker(() => {
        wrapQuestion(2);
    }, questionShowTime * 1000); // 10 seconds

    const countdownTimerElement = document.getElementById('countdown-timer');
    let startTime = Date.now();

    clearTimeout(countdownTimer); // Clear the setTimeout to stop the timer

    function updateTimer() {
        const currentTime = Date.now();
        elapsedSeconds = (currentTime - startTime) / 1000; // Convert milliseconds to seconds
        countdownTimerElement.textContent = elapsedSeconds.toFixed(2) + 's';
        countdownTimer = setTimeout(updateTimer, 10); // Update every 10 milliseconds (0.01 seconds)
    }

    updateTimer(); // Start the timer
}


//
// The big display question function
// 
function displayQuestion(questionIndex, qotd) {

    closePopup(commentPopup);
    closeAllModals();

    const questionNumber = document.getElementById('question-number');
    const questionElement = document.getElementById('question');
    const optionsList = document.getElementById('options');

    currentQuestion = [];

    if (questionIndex < questions.length) {
        const question = questions[questionIndex];
        sizeDependentText(questionElement, question.question);

        currentCorrectAnswer = question.correct;
        currentAskedQuestion = question.question;
        optionsList.innerHTML = '';

        questionNumber.textContent = `QUESTION ${questionIndex + 1} of ${gameTotalQuestions}`;

        shuffleArray(question.answers).forEach((option, index) => {
            const li = document.createElement('li');
            li.textContent = option;
            li.id = 'option-' + sanitizeId(option); // Assign an ID
            li.class = "options bounceIn";
            li.class += " " +
                li.addEventListener('click', (event) => {
                    handleOptionClick(li, question.correct, question.comment)
                    event.stopPropagation(); // This prevents the event from bubbling up the DOM tree
                    event.preventDefault(); // This prevents the default action associated with the event
                });

            optionsList.appendChild(li);
        });

        optionsList.classList.remove('disabled');
        resetCountdownBar();
        currentQuiz.lastTime = questionShowTime;
        currentQuiz.no_attempt++;
        currentQuiz.asked++;

        if (questionIndex + 1 == gameTotalQuestions) {
            PopupComment('Last question!');
            //            document.getElementById('get-ready').innerText = 'Get ready for the final scoreboard...';
        }

        if (qotd) {
            hideFeedback();
            hideElement('feedback');
        } else {
            showFeedback();
        }

    } else {
        wrapQuiz(qotd);
        return;
    }

    // Start countdown animation
    const countdownBar = document.getElementById('countdown-bar');
    countdownBar.style.width = '100%';

    // Wait for questionShowTime seconds before showing the next question
    questionTimeout = setTimeoutWorker(() => {
        wrapQuestion(qotd);

    }, questionShowTime * 1000); // 10 seconds

    const countdownTimerElement = document.getElementById('countdown-timer');

    let startTime = Date.now();

    clearTimeout(countdownTimer); // Clear the setTimeout to stop the timer

    function updateTimer() {
        const currentTime = Date.now();
        elapsedSeconds = (currentTime - startTime) / 1000; // Convert milliseconds to seconds

        countdownTimerElement.textContent = elapsedSeconds.toFixed(2) + 's';

        // Keep updating the timer
        countdownTimer = setTimeout(updateTimer, 10); // Update every 10 milliseconds (0.01 seconds)
    }

    updateTimer(); // Start the timer
}

function freezeInnerBarWidth() {
    const innerBar = document.getElementById('countdown-inner-bar');
    const currentWidth = window.getComputedStyle(innerBar).width;
    innerBar.style.width = currentWidth;
}


//
// Handle clicks of quiz answers. Count score and display correct/wrong answers
//

function handleOptionClick(selectedLi, correctAnswer, comment) {

    const optionsList = document.getElementById('options');

    currentCorrectAnswer = correctAnswer; // This feels messy.

    optionsList.classList.add('disabled');

    if (countdownTimer) {

        freezeInnerBarWidth();

        currentQuiz.lastTime = parseFloat((elapsedSeconds).toFixed(2));
        clearTimeout(countdownTimer); // Clear the setTimeout to stop the timer

        if (difficulty == 'qotd' && elapsedSeconds < 10) {
            wrapUpCountdown();
        }
    }

    if ((selectedLi.textContent === correctAnswer) ||
        (scoreDisplayType(difficulty) === 'survey')
    ) {
        selectedLi.classList.add('option-correct');

        currentQuiz.correct++;
        currentQuiz.no_attempt--;

        // Only count total time spent for correct answers
        // It's meant to differentiate in draws 
        currentQuiz.time += currentQuiz.lastTime;

        currentQuestion.push({
            result: "correct",
            time: currentQuiz.lastTime,
            name: username,
            avatarUrl: userAvatarUrl,
            id: compositeUsername,
            answer: selectedLi.textContent
        });

        currentDetails.push({
            result: "correct",
            time: currentQuiz.lastTime,
            answer: selectedLi.textContent
        })

        sendUpdate(`${username} answered "${selectedLi.textContent}"`, { name: username, result: "correct", currentQuestionIndex: currentQuestionIndex, roomCode: roomCode, award: thisUser.award, avatarUrl: userAvatarUrl, answer: selectedLi.textContent, time: currentQuiz.lastTime });

    } else {
        currentQuiz.wrong++;
        currentQuiz.no_attempt--;

        selectedLi.classList.add('option-wrong');
        document.getElementById('countdown-inner-bar').classList.add('countdown-bar-wrong');

        let correctLi = document.getElementById('option-' + sanitizeId(correctAnswer));
        correctLi.innerHTML = '<span class="checkmark">&#x2713;</span> ' + correctLi.innerHTML;
        correctLi.style.border = "1px solid green"; // Set the border to a 2px solid green

        currentQuestion.push({
            result: "wrong",
            time: currentQuiz.lastTime,
            name: username,
            avatarUrl: userAvatarUrl,
            id: compositeUsername,
            answer: selectedLi.textContent
        });

        currentDetails.push({
            result: "wrong",
            time: currentQuiz.lastTime,
            answer: selectedLi.textContent
        })

        sendUpdate(`${username} answered "${selectedLi.textContent}"`, { name: username, result: "wrong", currentQuestionIndex: currentQuestionIndex, roomCode: roomCode, award: thisUser.award, avatarUrl: userAvatarUrl, answer: selectedLi.textContent, time: null });
    }

    let optionsObject = {};
    optionsObject[selectedLi.textContent] = [{ username: username, avatarUrl: userAvatarUrl }];
    addCirclesToOptions(optionsObject, 20);

    // Delay the start of adding circles by 500ms
    setTimeout(() => {
        addCirclesToOptions(oldScores[currentQuestionIndex], 500);
    }, 1000);

    if (comment) {
        closePopup(commentPopup);

        setTimeout(function () {
            PopupComment(comment);
        }, 500); // 500 milliseconds = 0.5 seconds
    }
}

// Function to check if "seen" has been set and return the UID or false
async function checkSeen(quiz_id) {
    try {
        // Retrieve the quiz document using quiz_id
        const doc = await db.collection("quizzes").doc(quiz_id).get();

        if (doc.exists && doc.data().seen) {
            // "seen" field is set, return the UID
            return doc.data().seen;
        } else {
            // "seen" field is not set or document doesn't exist
            return false;
        }
    } catch (error) {
        // Error retrieving document, return false
        console.error("Error retrieving document: ", error);
        return false;
    }
}

async function injectFinalScore(hash, spanId, final) {
    // Convert hash to array and sort
    const dataArray = Object.keys(hash).map(key => ({ id: key, ...hash[key] }))
        .sort((a, b) => b.correct - a.correct || a.time - b.time);

    let listContent = `<ol class="high-score-list">`;
    let placement;

    for (let i = 0; i < dataArray.length; i++) {
        const item = dataArray[i];
        
        if (item.name === username) {
            placement = i + 1;
        }

        if ((item.time == null || item.time === 0) && item.name === "Anonymous Guest" && final) {
            continue;
        }

        const timeFormatted = typeof item.time === 'number' ? item.time.toFixed(2) : '';
        const additionalClass = i === 0 ? 'highlighted-user' : '';

        listContent += `
            <li class="${additionalClass}">
                <span class="hiscore-position">${i + 1}.</span>
                <span class="hiscore-player">${safer(item.name)}</span>
                <span class="hiscore-details">
                    <span style="margin-left:2px; margin-right: 6px; color: green;">&#x2713;</span>${item.correct}
                    <br/>
                    <span style="margin-right: 5px; size: 10px;">&#x231B;</span>${timeFormatted}s
                </span>
            </li>`;
    }
    
    listContent += '</ol>';
    document.getElementById(spanId).innerHTML = listContent;
    return placement;
}

async function injectWinner(hash, spanId) {
    const dataArray = Object.keys(hash).map(key => ({ id: key, ...hash[key] }))
        .sort((a, b) => b.correct - a.correct || a.time - b.time);

    const winner = dataArray[0];
    if (!winner || winner.correct === 0) {
        return;
    }

    let greeting = "OK effort!";
    let award = 0;
    let awardWithheld = 0;

    if (dataArray.length > 1) {
        award = current_awards["participant"];

        if (winner.correct > gameTotalQuestions / 2) {
            award = current_awards["winner"];
            greeting = 'Congrats!';
        }
        if (winner.correct > 6 && winner.correct >= gameTotalQuestions - 2) {
            award = current_awards["everything_correct"];
            greeting = 'Phenomenal!';
        }
        if (!winner.correct) {
            award = 0;
        }

        if (is_owner && !current_owner_id) {
            current_owner_id = compositeUsername;
        }

        if (award && (current_owner_id === winner.id)) {
            let seenUid = await checkSeen(roomCode);
            if (seenUid) {
                award = 0;
                awardWithheld = 1;
            }
        }

        if (award && (winner.name === username)) {
            let awardObject = {
                Award: award,
                Correct: winner.correct,
                Questions: gameTotalQuestions,
                Place: 1,
                Topic: topic,
                Code: roomCode,
            };

            if (!thisUser.anonymous) {
                addObjectToUserSubCollection('Awards', awardObject);
                storeUserData({ last_award: award });
            }

            awardHistory.push(awardObject);
            thisUser.award = thisUser.set_award || award;
            updateDetails();
        }
    }

    let winnerHTML = `
    <div class="winner-container avatar-shadow-wrapper">
        <div class="winner-points">
            <div class="winner-label">Points</div>
            <div class="winner-points-score">${winner.correct}</div>
        </div>
        <div class="winner-image-container">
            <img src="${winner.avatarUrl}" alt="Winner Image" class="winner-image ${ (winner.avatarUrl && (winner.avatarUrl != "undefined")) ? "" : "hidden"}">
        </div>
        <div class="winner-time">
            <div class="winner-label">Time</div>
            <div class="winner-time-score">${typeof winner.time === 'number' ? winner.time.toFixed(2) : '?:??'}s</div>
        </div>
    </div>
    <div>
    <h1>${winner.name}</h1>
    <div class="winner-award ${award && (!awardWithheld) ? '' : 'hidden'}">
    <span class="the-award"><B>New Award: </b><span class="shiny-text" data-text="🏆 ${award} 🏆">🏆 ${award} 🏆</span></span>
    </div>
    `;
    
    document.getElementById('quiz-hiscore-title').textContent = "Winner";
    const winnerDisplay = document.getElementById('winner-display');
    winnerDisplay.innerHTML = winnerHTML;
    winnerDisplay.style.display = 'block';

    if (awardWithheld) {
        PopupComment(`No title awarded! ${winner.name} had already seen the quiz.`);
    } else if (award) {
        PopupComment(`${greeting} ${winner.name} is awarded the title ${award}!`);
    }
}


function injectScoreboard(data, spanId, currentCorrectAnswer) {
    // Sorting the data
    data.sort((a, b) => {
        if (a.result === b.result) {
            // When results are the same, sort by time
            return (a.time === null) - (b.time === null) || a.time - b.time;
        }
        return a.result === 'wrong' ? 1 : -1;
    });

    scoreTracker[compositeUsername] = { ...currentQuiz };
    scoreTracker[compositeUsername].avatarUrl = userAvatarUrl;

    // Creating list items as HTML string with conditional background color
    let listContent = `
        <table class="score-table">
        <thead>
        <tr>
        <th colspan=4 class="th-answer">Correct Answer: <b>${safer(currentCorrectAnswer)}</b></th>
        </tr>
        </thead>
        `; // Using ordered list for numbering
    for (const item of data) {
        //        const total = scoreTracker[item.id].correct;
        const timeInfo = item.result === 'correct' ? `${item.time ? item.time.toFixed(2) + 's' : 'N/A'}` : '';
        listContent += `
        <tr>
        ${item.result === 'correct' ? '<td class="td-greenmark">\u2713</td>' : '<td class="td-redmark">\u274C</td>'}</td>
        <td class="td-main ${'td-' + safer(item.result)}">${safer(item.name)}<br/>
        <span class="td-detail">"${safer(item.answer)}"</span></td>
        <td class="td-timeinfo"><span style="margin-right: 5px; size: 10px;">&#x231B;</span> ${timeInfo}</td>
        <td class="td-total"><span style="color: green;">&#x2713;</span>${safer(scoreTracker[item.id].correct)} pts</td>
        </tr>
        `;

    }
    listContent += '</table>';


    // Adding to the span
    const spanElement = document.getElementById(spanId);
    if (spanElement) {
        spanElement.innerHTML = listContent;
    } else {
        console.error('Span element not found');
    }
}


function injectSurveyResult(data, spanId, myCurrentQuestion) {
    // Aggregate answers and collect names for each answer
    const answerSummary = data.reduce((acc, { answer, name }) => {
        if (!acc[answer]) {
            acc[answer] = { count: 0, names: [] };
        }
        acc[answer].count += 1;
        acc[answer].names.push(name);
        return acc;
    }, {});

    // Convert the summary object to an array and sort by count
    const sortedAnswers = Object.entries(answerSummary).map(([answer, { count, names }]) => ({
        answer,
        count,
        names: names.join(', ').replace(/, ([^,]*)$/, ' and $1') // Formatting names list in natural language
    })).sort((a, b) => b.count - a.count);

    // Generate list content

    let listContent = `
        <table class="score-table">
        <thead>
        <tr>
        <th colspan=2 class="th-answer"><b>${safer(myCurrentQuestion)}</b></th>
        </tr>
        </thead>
        `;

    sortedAnswers.forEach(({ answer, count, names }) => {

        listContent += `
        <tr>
       
        <td class="td-main">${safer(answer)}<br/>
        <span class="td-detail">${safer(names)}</span></td>
        <td class="td-total">${safer(count)} rsps.</td>
        </tr>
        `;

    });
    listContent += '</table>';

    SurveyResultsTable += listContent + '<br/>';

    // Inject the HTML into the specified span element
    document.getElementById(spanId).innerHTML = listContent;
}



function injectSurveyResultTable(listContent, spanId) {

    // Inject the list into the specified span
    document.getElementById(spanId).innerHTML = listContent;

}


function injectQotdResult(data, spanId, myCurrentQuestion) {

    // Aggregate answers and collect names for each answer

    let answer = data.length ? data.pop() : { "time": 0.00, result: undefined };

    scoreTracker.correct += answer.result == "correct" ? 1 : 0;
    scoreTracker.correctTime += answer.result == "correct" ? answer.time : 0;

    SurveyResultsTable += `
    <tr>
        <td class="qotd-mark">${answer.result == "correct" ? '<span style="color: green;">&#x2713;</span>' : ""}${answer.result == "wrong" ? "&#x274C;" : ""}
        <td class="qotd-main">${currentQuestionIndex + 1}.</td>
        <td class="qotd-total">
            <div class="qotd-result-bar" style="
                width: ${getBarWidth(answer.time, answer.result)};
                background: ${getBarColor(answer.result)};
                ">
            </div>
            <span class="qotd-detail">${answer.time ? answer.time + 's' : ''}</span>
        </td>
    </tr>
    `;

}

function getBarWidth(time, result) {
    if (result === undefined) return '250px'; // Full width for undefined result
    const maxTime = 12.00;
    const widthPerTime = 250 / maxTime;
    return `${Math.min(time * widthPerTime, 250)}px`; // Calculate width based on time, up to a maximum of 350px
}

function getBarColor(result) {
    switch (result) {
        case "correct": return 'linear-gradient(to right, #207041 0%, #5EA375 50px, #5EA375 100%)'; // Green for correct answers
        case "wrong": return 'linear-gradient(to right, #CD1F08 0%, #F44336 50px, #F44336 100%)';        // return '#f44336'; // Red for wrong answers
        default: return 'linear-gradient(to right, #D1D1D1 0%, #F0f0f0 50px, #F0f0f0 100%)';// '#f0f0f0'; // Light grey for undefined results
    }
}


// We're done with a question, show the score
function wrapQuestion(qotd, wrap = 0) {

    if (scoreDisplayType(difficulty) === 'survey') {
        injectSurveyResult(currentQuestion, "scoreboard", currentAskedQuestion);
    }
    else if (scoreDisplayType(difficulty) === 'qotd') {
        injectQotdResult(currentQuestion, "scoreboard", currentAskedQuestion);
    }
    else {
        // updating scoretracker at the sendupdate point instead
        //        scoreTracker['self'] = { ...currentQuiz };
        // Show the scores
        injectFinalScore(scoreTracker, "scoreboard", 0);
    }

    if (qotd == 1) { // This is quiz of the day I think
        document.getElementById('quiz-summary-modal').style.display = 'none';
        document.getElementById('the-question').style.display = 'block';

        nextQuestion(qotd);

    }
    else if (qotd == 2) { // This is audio
        currentQuestionIndex++;
        document.getElementById('countdown-timer').textContent = "Collecting last results..";

        if (showing_results) {

        }
        else {
            document.getElementById('listener').innerHTML += `
            ${collected_results}
            `;
            showing_results = 1;
        }
        
        let mic = document.getElementById('mic-setup');
        if (mic) { mic.remove();}

        changeCountdownColor('#c02812');

        if (wrap) {
            audioQuestion(currentQuestionIndex, qotd);

        }
        else {
            // I think we need a wait time after the thing to mop up the last questions
            setTimeoutWorker(() => {
                audioQuestion(currentQuestionIndex, qotd);

            }, 3500);
        }
    } else {

        nextQuestion(qotd);

        // Display the modal
        /*
        document.getElementById('quiz-summary-modal').style.display = 'flex';
        document.getElementById('the-question').style.display = 'none';

        debugger;

        if (currentQuestionIndex + 1 < gameTotalQuestions) {
            startTimer('get-ready-dots', 3500);

            // Wait for 5 seconds, then hide the modal and go to the next question
            setTimeoutWorker(() => {
                document.getElementById('quiz-summary-modal').style.display = 'none';
                document.getElementById('the-question').style.display = 'block';

                nextQuestion(qotd);
            }, 3500);
        }
        else {
            nextQuestion(qotd);
        }

        */
    }

}

async function wrapQuiz() {

    if (Object.keys(scoreTracker).length < 1 && SurveyResultsTable == "") {
        // This quiz is dead
        showScreen('screen-create-quiz');
        return;

    }

    if (!currentQuiz.name) { return; }

    showScreen('screen-show-final');
    
    scoreTracker[compositeUsername] = { ...currentQuiz };
    scoreTracker[compositeUsername].avatarUrl = userAvatarUrl;

    let placement = 0;

    if (scoreDisplayType(difficulty) === 'qotd') {

        SurveyResultsTable += `
            <tr><td colspan="4" class="qotd-sum">
            <div class="qotd-flex-container">
                <span class="left-aligned"><span style="margin-right: 5px; color: green;">&#x2713;</span> ${scoreTracker.correct ? scoreTracker.correct : 0}</span>
                <span class="center-aligned">&#128279; ${currentUrl}</span>
               <span class="right-aligned"><span style="margin-right: 5px; size: 10px;">&#x231B;</span> ${scoreTracker.correctTime.toFixed(2)}s</span>
             </div>
            </td></tr>
            </table></div>`;
        injectSurveyResultTable(SurveyResultsTable, "quiz-scoreboard");
        gtag('event', 'qotd_finalized', {

        });

        if (hiscore && thisUser.uid in hiscore) {
            // You already had a score
        }

        else {
            await mergeDoc('participants', roomCode, {
                [uid]: {
                    uid: uid,
                    summary: currentQuiz,
                    username: username,
                    avatarUrl: userAvatarUrl || '',
                    award: thisUser.award,
                    details: currentDetails,
                    ranked: 0,   // Note that for async quizzes this doenst make sense, so just 
                    // set it to 0 and calculate current positions later
                    type: thisUser.anonymous ? 'anonymous' : 'player'
                }
            });
        }

        if (roomCode.startsWith('A')) {

            sendEngage(`${username} finished "${topic}" with ${currentQuiz.correct} points!`);

            let thisHiscore = await getDoc("participants", roomCode);

            let hiscoreHTML = generateHighScoreHTML(thisHiscore, thisUser.uid); // hiscoreList(hiscore);

            document.getElementById('quiz-hiscore-title').textContent = topic;
            document.getElementById('quiz-hiscore').innerHTML = `
            <div class="sum-title">Hi-Scores</div>
            ${hiscoreHTML}
            `;

        }
    }
    else {
        // Set the title

        document.getElementById('quiz-hiscore').innerHTML = `<div class="sum-title">Final Result</div>`;

        // Show the scores
        await injectWinner(scoreTracker, "quiz-winner");

        placement = await injectFinalScore(scoreTracker, "quiz-scoreboard", 1);


        /*
        document.getElementById('quiz-hiscore-title').textContent = topic;
        document.getElementById('quiz-hiscore').innerHTML = `<div class="sum-title">Final Result</div>`;

        // Show the scores
        placement = await injectFinalScore(scoreTracker, "quiz-scoreboard", 1);
*/
        await mergeDoc('participants', roomCode, {
            [uid]: {
                uid: uid,
                summary: currentQuiz,
                username: username,
                avatarUrl: userAvatarUrl || '',
                award: thisUser.award,
                details: currentDetails,
                ranked: placement ? placement : 0,   // Note that for async quizzes this doenst make sense, so just 
                // set it to 0 and calculate current positions later
                type: thisUser.anonymous ? 'anonymous' : 'player'
            }
        });
    }



}


function nextQuestion(qotd) {
    currentQuestionIndex++;
    displayQuestion(currentQuestionIndex, qotd);
}

async function ablyFetch(url, options) {
    // Trigger the background function

    let progressQuestionCount = questions.length;

    const triggerResponse = await fetch(url, options);
    if (!triggerResponse.ok) throw new Error('Failed to trigger background task');

    // Wait for the completion on the pre-existing Ably quizChannel
    const result = await new Promise((resolve, reject) => {
        const taskCompleteHandler = message => {


            if (message.name === 'task-complete' && message.data["x-task-id"] == options.headers['x-task-id']) {
                quizChannel.unsubscribe('task-complete', taskCompleteHandler);

                // Ensure the data is correctly parsed as JSON from the string format
                let dataParsed;
                try {
                    dataParsed = JSON.parse(message.data.result.body);
                } catch (error) {
                    reject(new Error("Failed to parse JSON from background task response"));
                    return;
                }

                resolve({
                    ok: true,
                    status: message.data.result.status,
                    json: () => Promise.resolve(dataParsed),
                    text: () => Promise.resolve(message.data.result.body),
                    headers: new Headers(message.data.result.headers)
                });
            } else {
                console.error('Message meant for someone else')
            }
        };

        document.getElementById('create-container').style.display = 'block';

        const taskQuestionCountHandler = message => {

            if (message.name === 'task-question' && message.data["x-task-id"] == options.headers['x-task-id']) {

                let latestQuestion = message.data["question"] < gameTotalQuestions ? message.data["question"] : gameTotalQuestions;

                progressQuestionCount += progressQuestionCount < gameTotalQuestions ? 1 : 0;


                document.getElementById('create-bar').style.width = `${(progressQuestionCount / gameTotalQuestions) * 100}%`;

                document.getElementById('create-status').textContent = `${progressQuestionCount} of ${gameTotalQuestions} questions`;


            } else {
                console.error('Message meant for someone else')
            }
        };

        quizChannel.subscribe('task-complete', taskCompleteHandler);
        quizChannel.subscribe('task-question', taskQuestionCountHandler);

        setTimeout(() => {
            quizChannel.unsubscribe('task-complete', taskCompleteHandler);
            quizChannel.unsubscribe('task-question', taskQuestionCountHandler);
            /*   document.getElementById('create-container').style.backgroundColor = '#ee4444'; */

            reject(new Error('Background task timed out'));
        }, 4 * 60000); // 4 * 60 seconds timeout
    });

    return result;
}




async function fetchQuestionsInBackground(conversation, totalQuestions, myRoomCode) {

    const progressElement = document.getElementById('progress-bar');
    const pageProgressElement = document.getElementById('question-progress');

    var i = 0;

    while ((questions.length < totalQuestions) && (i++ < 20)) {

        conversation.push({
            role: 'user',
            content: generateNextQuestionTemplate(difficulty, number, topic)
        });

        const response = await fetchQuestions(conversation, myRoomCode);

        if (!response) {
            // No reply, most likely a dated reply was returned and discarded
            break;
        }

        if (response && response.questions) {

            if (difficulty !== 'snap poll') {
                response.questions = validQuestions(response.questions);
            }
            markQuestions(response.questions, roomCode);
            addQuestions(response.questions);

            if (!roomCode.startsWith('A')) {
                sendQuestions(response.questions);
            }

            progressElement.innerHTML = `<span class="pulsate">Creating questions ${questions.length}/${gameTotalQuestions}</span>`;

            pageProgressElement.innerHTML = `${questions.length}/${gameTotalQuestions}`;

            if (response.questions.length < 1) {
                conversation.push({ role: 'assistant', content: "Broken JSON redacted" });
            } else {
                conversation.push({ role: 'assistant', content: JSON.stringify(response) });
            }

        } else {
            console.error("No additional questions received");
            break;
        }
    }

    // I'm done, got my questions
    progressElement.innerHTML = `Finalizing ${questions.length} questions`;

    let meta;

    await fetchMeta(JSON.stringify(questions))
        .then(mymeta => {
            if (extractedText.length > 0) {
                topic = mymeta.title;
                setTopic(topic);
                storeTopic(topic);

                meta = mymeta;
            }

        });

    current_awards = await fetchAwards(conversation, myRoomCode);
    sendAwards(current_awards);

    if (questions.length >= gameTotalQuestions) {
        const progBar = document.getElementById("generation-progress-bar");
        if (progBar) {
            progBar.classList.add('hidden');
            progBar.style.display = '';
        }

        addObjectToUserSubCollection('Quizzes', {
            Awards: current_awards,
            QuestionCount: gameTotalQuestions,
            Topic: topic,
            Code: roomCode,
            Questions: arrayToHash(questions, 'id'),
        },
            roomCode
        );
    }

    if (roomCode.startsWith('A')) {
        //        connectToQuizChannel(roomCode);
        initQuizChannel(roomCode, topic, gameTotalQuestions, difficulty, is_public);
        sendQuestions(questions);

        document.getElementById('owner-room-code').innerHTML = `<b>Quiz Code:  </b> ${roomCode} [&#128279; <span id="copyLinkContainer"><A href="#" id="owner-copy-link" style="cursor:pointer;text-decoration: underline; color: #0000EE">Copy Link</a></span>]`;
        document.getElementById('owner-copy-link').addEventListener("click", copyLinkToClipboard);

        history.pushState({ screen: 'screen-wait-quiz', roomCode: roomCode}, '', url.origin.toString()+'/q/' + roomCode);

        enableAsyncQuiz();
    }

    progressElement.innerHTML = `Done, quiz ready.`;
    document.getElementById('create-bar').style.width = `${100}%`;

    storeQuizData({
        questions: questions,
        awards: current_awards,
        uid: thisUser.uid,
        roomcode: roomCode,
        channel: familyRoomName || '',
        meta: {
            owner: thisUser.uid,
            owner_name: username,
            roomcode: roomCode,
            topic: topic,
            question_count_requested: gameTotalQuestions,
            difficulty: difficulty
        },
        type: quizType,
    });

    gtag('event', 'finalized-quizzes', {});

    if (is_subscribed) {
        document.getElementById("edit-quiz").addEventListener('click', () => {
            editQuiz(roomCode); // Assuming quiz ID is same as room code
        });
        enableButton('edit-quiz');

    }

    enableStartQuiz();

    sendRoomCode(roomCode, topic);
}




async function fetchQuestions(conversation, myRoomCode) {

    try {
        // Prepare the request body with the conversation history
        const requestBody = {
            conversation: conversation
        };

        let calculatedCredits = calcCurrentCredit();
        const response = await ablyFetch('/fetch-quiz-background', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'JWT': thisUser.accessToken,
                'user_id': thisUser.uid,
                'x-roomcode': myRoomCode,
                'topic': topic || "general trivia",
                'question-number': gameTotalQuestions,
                'difficulty': difficulty,
                'distribution': is_public,
                'calculated-credits': calculatedCredits,
                'x-task-id': Math.random() * 100000000
            },
            credentials: 'omit', // This tells fetch not to include cookies
            body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
            console.log("Error fetching questions:");
            const progressElement = document.getElementById('progress-bar');
            const pageProgressElement = document.getElementById('question-progress');

            const errorMsg = response.headers.get('custom_error_message') || "An error occurred.";
            progressElement.innerHTML = `<span class="pulsate">${safer(errorMsg)}</span>`;
            pageProgressElement.innerHTML = `<span class="pulsate">${safer(errorMsg)}</span>`;

            return;

            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        thisUser.credits = response.headers.get('CreditsLeft') || thisUser.credits;
        document.getElementById('credit-box').innerHTML = "&#128311; " + thisUser.credits;

        const data = await response.json();

        if (!(response.headers.get('x-roomCode') === roomCode)) {
            return;
        }

        if (!isValidJSON(data)) {

            //           conversation.push({ role: 'assistant',  data });
            conversation.push({ role: 'assistant', content: "redacted broken JSON" });

            conversation.push({ role: 'user', content: 'This did not parse as JSON. Please reply with the correctly formatted JSON: a simple [array] of objects in JSON structured as follows: [{ "question": "", "answers": ["","","",""], "correct": "", "comment": ""}].' });

            if (fetchRetry-- > 0) {
                console.log('RETRY: trying again, bad JSON');
                console.log(data);
                document.getElementById('progress-bar').innerHTML = '<span>Invalid questions. Trying again.</span>';

                let retryResult = await fetchQuestions(conversation, roomCode);
                return retryResult;
            }
            else {
                const progressElement = document.getElementById('progress-bar');
                const pageProgressElement = document.getElementById('question-progress');

                progressElement.innerHTML = '<span>Error in OpenAI results. Please try another topic or try again later.</span>';
                pageProgressElement.innerHTML = '<span>Fatal error in OpenAI results.</span>';

                return;
            }
        }

        var my_response = typeof data === 'string' ? JSON.parse(data) : data;

        if (Array.isArray(my_response)) {
            my_response = { questions: my_response };
        }

        return my_response;

        // return typeof data === 'string' ? JSON.parse(data) : data;

    } catch (error) {

        console.error("Error fetching questions:", error);

        if (fetchRetry-- > 0) {
            console.log('RETRY: trying again, network error');
            if (number > 1) { number-- };

            let retryResult = await fetchQuestions(conversation, roomCode);
            return retryResult;
        }
        else {
            const progressElement = document.getElementById('progress-bar');
            const pageProgressElement = document.getElementById('question-progress');

            progressElement.innerHTML = '<span class="pulsate">Timed out creating questions. Try an easier topic or try again later.</span>';

            pageProgressElement.innerHTML = '<span class="pulsate">Timed out creating questions.</span>';

            //            sendEngage(`Generating quiz on "${topic}"`);

            return;
        }


        return;
    }

}

function clearUpload() {
    document.getElementById('filename-display').style.display = 'none';
    document.getElementById('topic-input').style.display = 'block';
    document.getElementById("topic-input").focus();
    uploadFeedbackReset();

    extractedText = "";
    extractedTextTokens = 0;
    extractedTextCredits = 0;
    extractedFiletype = undefined;

    calcCurrentCredit()

}


async function fetchAQuestion(quiz, myRoomCode) {
    try {
        // Prepare the request body with the conversation history

        let questionConversation = [{
            role: 'user',
            content: `I  have a quiz with the following questions:
            ${quiz.questions.map(q => q.question).join(",\n")}

            Now I need you to add a new unique question to the quiz. It needs to be a multiple choice question with 4 options and there should be a witty comment attached. Do not under any circumstance repeat a question from the above list of questions, not even re-worded. It needs to fit with a quiz with the title "${quiz.meta.topic}".
            
            Please return it as a single JSON object following the below format, and nothing but the JSON code. 

            Example response:

            {
                "question": "What is the rarest M&M color?",
                "answers": ["Brown", "Yellow", "Green", "Tan"],
                "correct": "Tan",
                "comment": "Trivia buffs know their colors!",
            }
        `
        }];

        //        console.log(awardConversation);
        const requestBody = {
            conversation: questionConversation
        };


        const response = await fetch('/quick-fetch', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'JWT': thisUser.accessToken,
                'user_id': thisUser.uid,
                'x-roomcode': 'question',
                "x-interface": 'fast'
            },
            body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        if (!(response.headers.get('x-roomCode') === 'question')) {
            console.log('something went terribly wrong');
            return;
        }

        const data = await response.json();

        if (!isValidJSON(data)) {

            console.log('Did not get valid json, ignoring for now');
            console.log(data);

            return {};
        }

        var my_response = typeof data === 'string' ? JSON.parse(data) : data;

        return my_response;


    } catch (error) {
        console.error("Error fetching awards:", error);

        return;
    }

}



async function fetchIdeas(conversation) {

    try {

        const response = await fetch('/quick-fetch', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'JWT': thisUser.accessToken,
                'user_id': thisUser.uid,
                'x-roomcode': 'ideas'
            },
            credentials: 'omit', // This tells fetch not to include cookies
            body: JSON.stringify({
                conversation: conversation
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        if (!(response.headers.get('x-roomCode') === 'ideas')) {
            console.log('something went terribly wrong');
            return;
        }

        const data = await response.json();

        if (!isValidJSON(data)) {
            console.log('Did not get valid json, ignoring for now');
            console.log(data);
            return;
        }

        var my_response = typeof data === 'string' ? JSON.parse(data) : data;

        return my_response;

    } catch (error) {
        console.error("Error fetching ideas:", error);
        return;
    }
}

async function fetchAwards(conversation, myRoomCode) {
    try {
        // Prepare the request body with the conversation history

        let awardConversation = [{
            role: 'user',

            content: `Considering a quiz with the topic ${topic}, create the name of a title for the winner of the quiz. Also create the a title for anyone who got everything correct, and a title for all other participants. Let the titles reflect that the quiz had ${gameTotalQuestions} questions out of 24 possible and is of difficulty level ${difficulty}, so take care that they are not too prestigious for short and easy quizzes.  Titles like Master or Genius should be kept for big or difficult sounding quizzes. Return the result in JSON in the format \`\`\`json{ winner: "title", everything_correct: "title", participant: "title" }\`\`\`.  The titles can be funny or quirky or super serious, in a random mix. Return nothing other than the JSON.
        `
        }];

        //        console.log(awardConversation);
        const requestBody = {
            conversation: awardConversation
        };

        const response = await fetch('/quick-fetch', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'JWT': thisUser.accessToken,
                'user_id': thisUser.uid,
                'x-roomcode': 'awards'
            },
            body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        if (!(response.headers.get('x-roomCode') === 'awards')) {
            console.log('something went terribly wrong');
            return;
        }

        const data = await response.json();

        if (!isValidJSON(data)) {

            console.log('Did not get valid json, ignoring for now');
            console.log(data);

            return {};
        }

        var my_response = typeof data === 'string' ? JSON.parse(data) : data;

        return my_response;


    } catch (error) {
        console.error("Error fetching awards:", error);

        return;
    }
}



async function fetchMeta(quiz) {

    let blank = { "title": extractedFilename, "description": "" };

    try {
        // Prepare the request body with the conversation history

        let awardConversation = [{
            role: 'user',

            content: `Here is a quiz:
${quiz}

Given the provided quiz data, generate a concise title and a brief description. Return the results in JSON format with the following structure: { "title": "", "description": "", "suitable": "", "quality-reason": "" }.
        `
        }];

        const requestBody = {
            conversation: awardConversation
        };

        const response = await fetch('/quick-fetch', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'JWT': thisUser.accessToken,
                'user_id': thisUser.uid,
                'x-roomcode': 'meta'
            },
            body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        if (!(response.headers.get('x-roomCode') === 'meta')) {
            console.log('something went terribly wrong');
            return blank;
        }

        const data = await response.json();

        if (!isValidJSON(data)) {

            console.log('Did not get valid json, ignoring for now');
            return blank;
        }

        var my_response = typeof data === 'string' ? JSON.parse(data) : data;

        return my_response;


    } catch (error) {
        console.error("Error fetching meta:", error);

        return blank;
    }
}


function changeCreditCost(creditCost) {
    currentCreditCost = creditCost;
    document.getElementById('current-credit-cost').textContent = currentCreditCost;

    if (!currentCreditCost) {
        //        document.getElementById('current-credit-free').style.display="inline-block";   
        //        document.getElementById('current-credit-title').style.textDecoration="line-through";   



    } else {
        document.getElementById('current-credit-free').style.display = "none";
        document.getElementById('current-credit-title').style.textDecoration = "none";


    }
}

function populateQuizIdeas(existingIdeas) {

    const quizIdeasDiv = document.getElementById('quiz-ideas');
    if (!quizIdeasDiv) {
        console.error('quiz-ideas div not found');
        return;
    }

    if (!existingIdeas) {
        console.log('no ideas apparently');
        // No ideas?
        return;
    }

    // Clear existing content in the div
    quizIdeasDiv.innerHTML = '<b class="little-title">Ideas:</b> ';

    // Iterate over existingIdeas and create links
    existingIdeas.forEach(idea => {
        const ideaLink = document.createElement('a');
        ideaLink.href = '#'; // Prevent default link behavior
        ideaLink.textContent = idea;
        ideaLink.className = 'idea-link'; // Apply the CSS class

        // Event listener to populate quiz-topic form element when clicked
        ideaLink.addEventListener('click', (e) => {
            e.preventDefault();
            const quizTopicInput = document.getElementById('topic-input');
            if (quizTopicInput) {
                quizTopicInput.value = idea;
            }
        });

        // Append the link to the div
        quizIdeasDiv.appendChild(ideaLink);
    });
}


function updateDetails(force, xearlyuser, xearlyaward, xearlyurl) {

    if (is_identified || force) {
        const name = xearlyuser || username;
        const award = xearlyaward || useraward || "First Time Quizzer";
        const firstLetter = name.charAt(0).toUpperCase();
        const color = letterColors[firstLetter] || '#b0b0b0'; // Default color if letter not in map

        document.querySelectorAll('.player-name-value').forEach(element => {
            element.textContent = name;
        });

        document.querySelectorAll('.player-award').forEach(element => {
            element.textContent = award;
        });

        document.querySelectorAll('.avatar-face-circle').forEach(avatarCircle => {
            avatarCircle.style.backgroundColor = color;
        });

        document.querySelectorAll('.avatar-face-letter').forEach(avatarLetter => {
            avatarLetter.textContent = firstLetter;
        });

        if (userAvatarUrl || xearlyurl) {

            var avatarCircle = document.querySelector('.avatar-face-circle');
            // Clear existing content
            avatarCircle.innerHTML = '';
            // Create image element and set the URL
            var img = document.createElement('img');
            img.src = xearlyurl || userAvatarUrl;
            img.style.width = '100%'; // Ensure the image covers the circle
            img.style.height = '100%';
            img.style.borderRadius = '50%'; // Make the image round
            img.style.objectFit = 'cover'; // Ensure the image covers the area without distortion
            avatarCircle.appendChild(img);
        }

        if (awardHistory) {
            populateAwards(awardHistory, thisUser.award);
        }
    }
}

async function suggestIdeas() {

    //    if (thisUser.anonymous) { return };

    // Retrieve and parse existing ideas from the cookie
    const existingIdeasString = getCookie("ideas");
    let existingIdeas = existingIdeasString ? JSON.parse(existingIdeasString) : [];

    // Check the length of the existingIdeas array
    // If we're running out of ideas, get some more. Otherwise, show what we have.
    if (existingIdeas.length < 3) {

        var lastTopics = getLastNTopics(10);

        if (!lastTopics) { return; }

        ideaConversation = [{
            role: 'user',
            content: `Considering the general themes of ${lastTopics.join(", ")}, suggest ${topicIdeas} new distinct quiz topics. Each must be brief (4 words maximum) and relate broadly to the themes, focusing on well-established and widely recognized areas suitable for a trivia quiz. The topics can be interesting, mainstream, quirky or super serious, in a random mix. Try to understand the personality and mood of a person providing the given topics, then capture that in the suggestions while letting the original themes be seeds only, not restrictions. Do not make the topics funny titles. Provide the topics in a simple JSON array format without labels, like this: \`\`\`json["topic 1", "topic 2"]\`\`\`. Never repeat topics.  
            `
        }];

        const response = await fetchIdeas(ideaConversation);

        // Assuming response is an array of ideas
        if (response && Array.isArray(response)) {

            // Merge existingIdeas and response, then shuffle
            existingIdeas = shuffleArray([...existingIdeas, ...response]);

            const responseString = JSON.stringify(existingIdeas);

            // Save the string in a cookie, for example, with the name "ideas"
            // Set an expiration for the cookie as needed
            setCookie("ideas", responseString, 365); // Expires in 7 days
        }

        ideaConversation.push({ role: 'assistant', content: JSON.stringify(response) });


    }

    populateQuizIdeas(existingIdeas.slice(0, 10));
    existingIdeas = existingIdeas.slice(2); // Remove the first two elements

    // Update the cookie with the remaining ideas
    setCookie("ideas", JSON.stringify(existingIdeas), 365);

}


function calcCurrentCredit() {

    let cQuizType = document.getElementById('quiz-type').value == "async" ? 1 : 0;
    cQuizType = document.getElementById('quiz-type').value == "voice" ? 5 : cQuizType;

    let cGameTotalQuestions = document.getElementById('question-count').value > 12 ? 1 : 0;

    extractedTextTokens = extractedText.length / 4;
    extractedTextCredits = Math.floor(extractedTextTokens / 20000) + (extractedText.length ? 1 : 0);

    let cTextLength = extractedTextCredits;

    changeCreditCost(creditsBase + cQuizType + cGameTotalQuestions + cTextLength);

    return creditsBase + cQuizType + cGameTotalQuestions + cTextLength;
}

// function to start the quiz.
// Requests quiz questions from chatgtp then keeps going until we've got
// enough questions
async function createQuiz() {

    topic = document.getElementById('topic-input').value;

    if (topic.trim() === '' & !extractedText.length) {
        document.getElementById('topic-input').style.boxShadow = '0px 0px 8px red';
        Popup('Enter a topic or upload a valid file');
        return;
    }

    if (quizChannel) {
        await leaveChannel(quizChannel);
    }

    present = [];
    currentDetails = [];
    is_owner = 1;
    fetchRetry = fetchRetryCount;
    question_id = 1;
    hiscore = {};

    quizCache = [];
    lastDocument = null; // Also reset the last document
    allQuizzesLoaded = 0;

    quizType = document.getElementById('quiz-type').value;
    var makePublic = 0; // document.getElementById('make-public').value;

    // Check if the checkbox is checked
    is_public = (makePublic == 'public') ? 1 : 0; // checkbox.checked;

    roomCode = (quizType == 'async' ? 'A' : '') + generateSemiUniqueString();
    roomCode = quizType == 'voice' ? 'V' + generateSemiUniqueString() : roomCode;

//    enableStartQuiz();

    // Is this too early to start this?
    if (roomCode.startsWith('V')) {
        launchVoiceController();
    }

    const progressElement = document.getElementById('progress-bar');
    progressElement.innerHTML = '<span class="pulsate">Creating questions ..</span>';

    questions = [];
    gameTotalQuestions = gameDefaultTotalQuestions;

    sendEngage(`${username} creates a new quiz..`);

    difficulty = document.getElementById('difficulty-level').value || 'normal';
    difficulty = quizType == 'voice' ? 'voice' : difficulty;
    gameTotalQuestions = document.getElementById('question-count').value || 10;

    async.questions = [];
    async.questions = questions;
    async.meta = {
        topic: topic,
        difficulty: difficulty,
        owner_name: username,
        uid: thisUser.uid,
        public: is_public,
        room: familyRoomName || ''
    };

    let creditEstimate = calcCurrentCredit(); // Pre-calc the credit cost to show to users

    if (creditEstimate > thisUser.credits) {
        uploadFeedback("Insufficient credits");
        Popup("Insufficient credits");

        return;
    }

    showScreen('screen-own-quiz');
    disableButton('start-quiz');
    disableButton('start-quiz-guest');

    disableButton('start-async-quiz-guest');
    disableButton('start-async-quiz');

    disableButton('start-voice-quiz');
    disableButton('start-voice-quiz-guest');

    if (quizType == 'voice') {
        setupMicrophone();
    }

    if (extractedText.length < 1) {
        setTopic(topic);
        storeTopic(topic);
    }
    else {
        setTopic("Creating Quiz");
    }

    if (quizType == 'async') {

        document.getElementById('info-live-quiz').style.display = 'none';
        document.getElementById('info-async-quiz').style.display = 'block';

        document.getElementById('list-players').style.display = 'none';
        document.getElementById('list-scores').style.display = 'block';

    }
    else {
        document.getElementById('info-live-quiz').style.display = 'block';
        document.getElementById('list-players').style.display = 'block';

        //        document.getElementById('header-sound-control').style.display = 'block';

        chatConnect();

    }

    updateMetaTags("Join a Qaiz about " + topic);

    document.getElementById('topic-meta').textContent = (quizType == 'live' ? "Live! " : "") + gameTotalQuestions + ' ' + difficulty + ' questions';

    currentQuiz = { ...emptyQuiz };

    const offset = Math.floor(Math.random() * 1000);

    if (extractedFiletype && extractedFiletype.startsWith('image/')) {

        conversation = [{
            role: 'user',
            content: [
                {
                    "type": "image",
                    "source": {
                        "type": "base64",
                        "media_type": extractedFiletype,
                        "data": extractedText.split(',')[1],
                    }
                },
                {
                    "type": "text",
                    "text": generateQuestionTemplate(
                        'image-' + difficulty,
                        offset,
                        number + 1,
                        extractedText
                    )

                }]
        }];
    }
    else {
        // How to start the convo..
        conversation = [{
            role: 'user',
            content: generateQuestionTemplate(
                extractedText.length > 0 ? 'text-' + difficulty : difficulty,
                offset,
                number + 1,
                extractedText.length > 0 ? extractedText : topic,
            )

        }];
    }

    connectToQuizChannel(roomCode);

    const response = await fetchQuestions(conversation, roomCode);

    if (!response) {
        // Got a dated reply, it seems
        return;
    }

    conversation.push({ role: 'assistant', content: JSON.stringify(response) });

    // Assuming the response includes an array of questions
    if (response && response.questions) {

        if (!roomCode.startsWith('A')) {
            initQuizChannel(roomCode, topic, gameTotalQuestions, difficulty, is_public);
            //            sendRoomCode(roomCode, topic);

            document.getElementById('owner-room-code').innerHTML = `<b>Quiz Code:   </b>${roomCode} [&#128279; <span id="copyLinkContainer"><A href="#" id="owner-copy-link" style="cursor:pointer;text-decoration: underline; color: #0000EE">Copy Link</a></span>]`;

            document.getElementById('owner-copy-link').addEventListener("click", copyLinkToClipboard);

            history.pushState({ screen: 'screen-wait-quiz', roomCode: roomCode}, '', url.origin.toString()+'/q/' + roomCode);

        }

        if (difficulty !== 'snap poll') {
            response.questions = validQuestions(response.questions);
        }

        markQuestions(response.questions, roomCode);
        addQuestions(response.questions);

        if (!roomCode.startsWith('A')) {

            sendQuestions(response.questions);
        }

        progressElement.innerHTML = `<span class="pulsate">Creating questions ${questions.length}/${gameTotalQuestions}</span>`;


        fetchQuestionsInBackground(conversation, gameTotalQuestions, roomCode);

    } else {
        console.error("No questions received");
    }

}

async function updateAvatar(avatarUrl) {
    // Create a new Image object
    let imgLoadedResolve;
    const userLoaded = new Promise(resolve => {
        imgLoadedResolve = resolve;
    });

    const newImage = new Image();
    newImage.src = avatarUrl;
    newImage.alt = "Generated Avatar";
    newImage.classList.add('avatar-image');

    // Wait for the image to load
    newImage.onload = function () {
        // Get the current avatar container
        const avatarContainer = document.getElementById('avatar-preview');

        // Remove the old image
        const oldImage = avatarContainer.querySelector('.avatar-image');
        if (oldImage) {
            oldImage.remove();
        }

        // Add the new image to the container
        avatarContainer.appendChild(newImage);

        // Force a reflow to restart the animation
        newImage.offsetWidth; // This accesses the element's offsetWidth property which forces a reflow

        // Add the loaded class to trigger the transition
        newImage.classList.add('loaded');

        imgLoadedResolve();
    };

    // Handle image load error
    newImage.onerror = function () {
        console.error('Failed to load the image.');
        imgLoadedResolve();
    };

    return userLoaded;
}

// Example usage

function createLoader(duration) {
    // Create loader container
    const loaderContainer = document.createElement('div');
    loaderContainer.classList.add('loader-container');

    // Create SVG element for the loader
    const loaderSVG = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    loaderSVG.classList.add('loader');
    loaderSVG.setAttribute("viewBox", "0 0 100 100");

    // Create circle element for the loader
    const loaderCircle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    loaderCircle.classList.add('loader-circle');
    loaderCircle.setAttribute("cx", "50");
    loaderCircle.setAttribute("cy", "50");
    loaderCircle.setAttribute("r", "48");

    loaderSVG.appendChild(loaderCircle);
    loaderContainer.appendChild(loaderSVG);

    // Insert the loader into the avatar container
    const avatarContainer = document.getElementById('avatar-preview');
    avatarContainer.style.position = 'relative';
    avatarContainer.appendChild(loaderContainer);

    // Animate the loader
    loaderCircle.style.animation = `drawLoader ${duration}s linear forwards`;

    return loaderContainer;
}

function removeLoader(loaderContainer) {
    if (loaderContainer) {
        loaderContainer.remove();
    }
}

// Example usage

// To remove the loader after a certain time or on demand

// Or call removeLoader(loader) whenever needed


async function createAvatar() {
    const avatarType = document.getElementById('avatar-type').value;
    const avatarStyle = document.getElementById('avatar-style').value;

    gtag('event', 'generate-avatar', {});

    disableButton('create-the-avatar');

    const loader = createLoader(24); // Create a loader that runs for 12 seconds

    let avatarAwait;

    try {

        let creditEstimate = 5;

        if (creditEstimate > thisUser.credits) {
            Popup("Insufficient credits");

            throw new Error('Insufficient credits');
        }

        document.getElementById('avatar-feedback').textContent = '';
        document.getElementById('avatar-feedback').style.display = 'none';

        const response = await fetch('/generate-avatar', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'JWT': thisUser.accessToken,
                'x-uid': uid,
            },
            body: JSON.stringify({
                type: avatarType,
                style: avatarStyle,
                title: chosenAward,
                name: username
            })
        });

        if (!response.ok) {

            enableButton('create-the-avatar');

            gtag('event', 'generate-avatar-failed', {});

            throw new Error('Failed to generate avatar');
        }

        const data = await response.json();
        const avatarUrl = data.avatarUrl;
        userAvatarUrl = avatarUrl;
        useraward = chosenAward;
        thisUser.award = chosenAward;

        awardUrls[chosenAward] = userAvatarUrl;

        avatarAwait = updateAvatar(avatarUrl);
        updateDetails(1, username, chosenAward, avatarUrl);

        storeUserData({ set_award: chosenAward });
        setCookie('player-award', chosenAward, 365); // Expires in 365 days (adjust as needed)
        setCookie('player-url', avatarUrl, 365); // Expires in 365 days (adjust as needed)

        thisUser.credits = response.headers.get('CreditsLeft') || thisUser.credits;
        document.getElementById('credit-box').innerHTML = "&#128311; " + thisUser.credits;

        gtag('event', 'generate-avatar-success', {});

    } catch (error) {

        gtag('event', 'generate-avatar-crashed', {});

        console.error('Error generating avatar:', error);
        document.getElementById('avatar-feedback').textContent = error;
        document.getElementById('avatar-feedback').style.display = 'block';

    }

    await avatarAwait;
    removeLoader(loader);
    enableButton('create-the-avatar');


}



// Function that creates the event handler function with a closure over linkToCopy
// This is the actual event handler function
async function copyLinkToClipboard(event) {
    event.preventDefault(); // Prevent the default action (e.g., navigating to a link)
    try {
        // Use the Clipboard API to copy the text
        await navigator.clipboard.writeText("https://qaiz.app/?quizcode=" + roomCode);
        // Notify the user that the link has been copied
        alert("Link copied to clipboard!");

    } catch (err) {
        // Log or handle any errors
        console.error("Failed to copy: ", err);
    }
}

function injectCopyLink(linkToCopy) {
    // Create a span or anchor element for the "Copy Link" text
    var copyLinkElement = document.createElement("a");
    copyLinkElement.href = "#";
    copyLinkElement.textContent = "Copy Link";
    copyLinkElement.style.cursor = "pointer";
    copyLinkElement.style.textDecoration = "underline";
    copyLinkElement.style.color = "#0000EE"; // Standard link color

    // Attach the click event listener to the element
    copyLinkElement.addEventListener("click", copyLinkToClipboard);

    // Find a place in your document where the link should be injected
    document.getElementById("copyLinkContainer").appendChild(copyLinkElement);
    document.getElementById("copyJoinLinkContainer").appendChild(copyLinkElement);

}

function getDayOfYearUTC() {
    const now = new Date();
    // Create a new Date object for the start of the year in UTC
    const startOfYearUTC = Date.UTC(now.getUTCFullYear(), 0, 1);
    const nowUTC = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor((nowUTC - startOfYearUTC) / oneDay);

    return (dayOfYear + 1 - 80) % 366; // +1 because January 1st is day 1, 81 to start on March 21, mod 366 to keep going around the year

}

async function loadQotdData(qotdFile) {
    try {
        // Make a request to get the JSON content
        const response = await fetch(qotdFile);
        if (!response.ok) {
            // If the server response is not OK, throw an error
            throw new Error('Failed to load the quiz data');
        }
        // Parse the JSON content to a JavaScript object
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error creating quiz data:', error);
        return null; // or handle the error as needed
    }
}

// function to show the quiz screen and start the quiz
async function startAsyncQuiz(starter = 0) {

    showScreen('screen-quiz-question');
//    removeAllUrlParams();

    currentQuiz = { ...emptyQuiz };
    currentQuiz.name = username;
    currentQuiz.award = thisUser.award;
    currentQuiz.id = compositeUsername;

    currentUrl = 'qaiz.app/q/' + roomCode;
    questions = [];
    is_owner = 0;
    scoreTracker = { correct: 0, correctTime: 0 };
    present = [];
    roomCode = roomCode;
    difficulty = 'qotd';

    questions = async.questions;
    topic = async.meta.topic;
    gameTotalQuestions = async.questions.length;

    SurveyResultsTable = `
    <div style="width:100%; align-items:center;">
    <table class="qotd-score-table">
    <thead>
    <tr>
    <th colspan=3 class="qotd-th-answer"><span class="sum-title">Your Scores</span></th>
    </tr>
    </thead>
    `;

    gtag('event', 'async_start', {

    });

    displayQuestion(currentQuestionIndex = 0, 1);

    if (questions.length < gameTotalQuestions && is_owner) {
        const progBar = document.getElementById("generation-progress-bar");
        if (progBar) {
            progBar.classList.remove('hidden');
            progBar.style.display = 'flex';
        }
    }

    sendEngage(`${username} is playing "${topic}"`);

}

// function to show the voice quiz screen and start the quiz
async function startVoiceQuiz(starter = 0) {

    // This first logic should be unnecessary but adding it as a precursor
    // because clearly quizzes are started out of bounds all the time :(
    if (inQuiz) {
        return;
    }

    // Again I think maybe this should be unnecessary? But if a message arrives after the person has left the
    // waiting room, no quiz should start.
    if (!(currentScreen == "screen-own-quiz" || currentScreen == "screen-wait-quiz")) {
        return;
    }

    inQuiz = 1;

    showScreen('screen-quiz-question');
//    removeAllUrlParams();

    hideFeedback();
    hideElement('feedback');

    allQuestions = {}; 

    currentQuiz = { ...emptyQuiz };
    currentQuiz.name = username;
    currentQuiz.award = thisUser.award;
    currentQuiz.id = compositeUsername;
    currentQuiz.type = "voice";

    SurveyResultsTable = "";

    // Assuming the response includes an array of questions
    if (questions) {
        audioQuestion(currentQuestionIndex = 0,2);
        if (is_owner || starter) {
            sendStartQuiz('start_voice_quiz');
        }

    } else {
        console.error("quiz started with no questions?");
    }

}



// function to show the quiz screen and start the quiz
async function startQotdQuiz(starter = 0) {

    showScreen('screen-quiz-question');
//    removeAllUrlParams();
    history.pushState({ screen: 'qotd' }, '', url.origin.toString()+'/qotd');

    currentQuiz = { ...emptyQuiz };
    currentQuiz.name = username;
    currentQuiz.award = thisUser.award;
    currentQuiz.id = compositeUsername;

    currentUrl = 'qaiz.app/qotd';
    questions = [];
    is_owner = 0;
    scoreTracker = { correct: 0, correctTime: 0 };
    present = [];
    roomCode = 'qotd';
    difficulty = 'qotd';

    questions = qotd.questions;
    topic = qotd.meta.topic;
    gameTotalQuestions = qotd.questions.length;

    SurveyResultsTable = `
    <div style="width:100%; align-items:center;">
    <table class="qotd-score-table">
    <thead>
    <tr>
    <th colspan=3 class="qotd-th-answer"><span class="little-title">Quiz of the Day</span><br/><span class="big-title">${topic}</span></th>
    </tr>
    </thead>
    `;

    gtag('event', 'qotd_start', {});

    displayQuestion(currentQuestionIndex = 0, 1);

    if (questions.length < gameTotalQuestions && is_owner) {
        const progBar = document.getElementById("generation-progress-bar");
        if (progBar) {
            progBar.classList.remove('hidden');
            progBar.style.display = 'flex';
        }
    }
}

// function to show the quiz screen and start the quiz
async function startQotdNewsQuiz(starter = 0) {

    showScreen('screen-quiz-question');
//    removeAllUrlParams();
    history.pushState({ screen: 'qotd-news' }, '', url.origin.toString()+'/news');


    currentQuiz = { ...emptyQuiz };
    currentQuiz.name = username;
    currentQuiz.award = thisUser.award;
    currentQuiz.id = compositeUsername;

    currentUrl = 'qaiz.app/news';
    questions = [];
    is_owner = 0;
    scoreTracker = { correct: 0, correctTime: 0 };
    present = [];
    roomCode = 'qotd:news';
    difficulty = 'qotd';

    questions = qotdnews.questions;
    topic = "Yesterday's News";
    gameTotalQuestions = qotdnews.questions.length;

    SurveyResultsTable = `
    <div style="width:100%; align-items:center;">
    <table class="qotd-score-table">
    <thead>
    <tr>
    <th colspan=3 class="qotd-th-answer"><span class="little-title">Quiz of the Day</span><br/><span class="big-title">${topic}</span></th>
    </tr>
    </thead>
    `;

    gtag('event', 'qotdnews_start', {

    });

    displayQuestion(currentQuestionIndex = 0, 1);

    if (questions.length < gameTotalQuestions && is_owner) {
        const progBar = document.getElementById("generation-progress-bar");
        if (progBar) {
            progBar.classList.remove('hidden');
            progBar.style.display = 'flex';
        }
    }
}


// function to show the quiz screen and start the quiz
async function startQuiz(starter = 0) {

    // This first logic should be unnecessary but adding it as a precursor
    // because clearly quizzes are started out of bounds all the time :(
    if (inQuiz) {
        return;
    }

    // Again I think maybe this should be unnecessary? But if a message arrives after the person has left the
    // waiting room, no quiz should start.
    if (!(currentScreen == "screen-own-quiz" || currentScreen == "screen-wait-quiz")) {
        return;
    }

    inQuiz = 1;

    showScreen('screen-quiz-question');
//    removeAllUrlParams();

    allQuestions = {}; 

    currentQuiz = { ...emptyQuiz };
    currentQuiz.name = username;
    currentQuiz.award = thisUser.award;
    currentQuiz.id = compositeUsername;

    SurveyResultsTable = "";

    // Assuming the response includes an array of questions
    if (questions) {
        displayQuestion(currentQuestionIndex = 0);
        if (is_owner || starter) {
            sendStartQuiz();
        }

    } else {
        console.error("quiz started with no questions?");
    
    }

    if (questions.length < gameTotalQuestions && is_owner) {
        const progBar = document.getElementById("generation-progress-bar");
        if (progBar) {
            progBar.classList.remove('hidden');
            progBar.style.display = 'flex';
        }
    }
}

function generateHighScoreHTML(scores, uid) {
    // First, ensure the input is in the expected format and not empty
    if (!scores || Object.keys(scores).length === 0) {
        return '<p class="nomargin">No scores yet.</p>';
    }

    // Convert scores object to an array of objects, preserving each entry's key as an id
    const scoresArray = Object.entries(scores).filter(([key, value]) =>
        value.hasOwnProperty('summary') // Assumes every valid entry has a 'summary'
    ).map(([key, value]) => ({
        id: key,
        ...value
    }));

    // Sort the array based on summary.correct (descending) and summary.time (ascending)
    const sortedScores = scoresArray.sort((a, b) => {
        if (a.summary.correct !== b.summary.correct) {
            return b.summary.correct - a.summary.correct; // Sort by correct answers descending
        }
        return a.summary.time - b.summary.time; // If equal, sort by time ascending
    });

    // Start building the HTML string
    let html = '<ol class="high-score-list">';

    // Determine the cutoff for showing scores (top 10 plus any extra logic for highlighted user)
    let displayLimit = 10;
    const uidIndex = sortedScores.findIndex(score => score.uid === uid);
    const shouldShowUidOutsideTopTen = uidIndex >= displayLimit;

    // Process each score for displaying
    sortedScores.forEach((score, index) => {
        let additionalClass = score.uid === uid ? ' class="highlighted-user"' : '';
        additionalClass = '';
        let position = index + 1;

        if (index < displayLimit || index === sortedScores.length - 1 || shouldShowUidOutsideTopTen && index === uidIndex) {
            if (sortedScores.length > 10 && index === 9 && !shouldShowUidOutsideTopTen) {
                html += '<li>...</li>'; // Visual separator for more than 10 entries
            }

            let circleHTML = createCircle(score).outerHTML;


            html += `<li${additionalClass}>
            <span class="hiscore-position">${position}.</span>
            ${circleHTML}
            <span class="hiscore-player"><span class="part-list-title">${score.username}</span><br/><span class="part-list-info">${score.award}</span></span>
            <span class="hiscore-details">
                <span style="margin-left:2px; margin-right: 6px; color: green;">&#x2713;</span>${score.summary.correct}
                <br/>
                <span style="margin-right: 5px; size: 10px;">&#x231B;</span>${score.summary.time.toFixed(2)}s
            </span>
        </li>`;
        }
    });

    html += '</ol>';

    return html;
}

function reindexScores(data) {
    const indexedData = {};

    // Check if data is null or not an object
    if (typeof data !== 'object' || data === null) {
        console.log('Invalid input: data must be a non-null object');
        return {};
    }

    // Iterate over each key in the provided data object
    Object.keys(data).forEach(key => {
        // Check if the key points to a user data object and ignore keys like 'timestamp'
        if (data[key] && typeof data[key] === 'object' && data[key].hasOwnProperty('details')) {
            const user = data[key];

            // Ensure 'details' is an array and iterate over it
            if (Array.isArray(user.details)) {
                user.details.forEach((detail, index) => {
                    if (detail && 'answer' in detail) {
                        // Initialize the index if not already done
                        if (!indexedData[index]) {
                            indexedData[index] = {};
                        }

                        const answer = detail.answer;
                        // Initialize the answer key as an array if not already done
                        if (!indexedData[index][answer]) {
                            indexedData[index][answer] = [];
                        }

                        // Create a new object that includes the necessary user and detail info
                        const entry = {
                            username: user.username,
                            award: user.award,
                            time: detail.time,
                            result: detail.result,
                            avatarUrl: user.avatarUrl
                        };

                        // Append the new object to the corresponding answer array at the right index
                        indexedData[index][answer].push(entry);
                    }
                });
            }
        }
    });

    return indexedData;
}


async function fetchQuizzesAndParticipants(uid, limit, lastDoc = null) {


    const quizzesRef = db.collection('quizzes');
    let quizQuery = quizzesRef.where('uid', '==', uid)
        .orderBy('timestamp', 'desc')
        .limit(limit);

    if (lastDoc) {
        quizQuery = quizQuery.startAfter(lastDoc);
    }

    try {
        const quizSnapshot = await quizQuery.get();
        if (quizSnapshot.empty) {
            console.log('No matching quizzes.');
            return { quizzes: [], lastDoc: null };
        }

        const quizIds = quizSnapshot.docs.map(doc => doc.id);
        const participantsRef = db.collection('participants');
        const participantsPromises = quizIds.map(quizId => participantsRef.doc(quizId).get());

        const participantsDocs = await Promise.all(participantsPromises);
        const participants = {};
        participantsDocs.forEach((doc, index) => {
            const quizId = quizIds[index];
            participants[quizId] = doc.exists ? [{ id: doc.id, ...doc.data() }] : [];
        });

        const quizData = quizSnapshot.docs.map(doc => ({
            quizId: doc.id,
            quizInfo: doc.data(),
            participants: participants[doc.id]
        }));

        return { quizzes: quizData, lastDoc: quizSnapshot.docs[quizSnapshot.docs.length - 1] };
    } catch (err) {
        console.error('Error getting documents:', err);
        return { quizzes: [], lastDoc: null };
    }
}


function createQuizList(quizData) {
    const container = document.getElementById('my-quiz-list'); // Assuming you have a container in your HTML for these elements

    document.getElementById('help-info-list-sub').style.display = is_subscribed ? 'none' : 'block';
   
    quizData.forEach(quiz => {
        const quizElement = document.createElement('div');
        quizElement.id = quiz.quizInfo.roomcode;
        quizElement.className = "quiz-list";

        // Determine button text and class based on conditions
        let buttonText, buttonClass = "minor-button";
        if (quiz.quizInfo.roomcode.startsWith('A')) {
            buttonText = 'Play';
        }
        else if (quiz.participants.length > 0) {
            buttonText = 'Finished';
            buttonClass += " button-disabled"; // Add disabled class
        }
        else if (quiz.quizInfo.timestamp.seconds < (Date.now() / 1000 - 24 * 60 * 60)) {
            buttonText = 'Lapsed';
            buttonClass += " button-disabled"; // Add disabled class

        } else {
            buttonText = 'Join Live';
        }

        const editIconHTML = `<i class="fa-solid fa-pen-to-square fa-icon-margin edit-icon" id="edit-${safer(quiz.quizInfo.roomcode)}"></i>`;

        quizElement.innerHTML = `
        <div style="float:right">
            ${is_subscribed ? editIconHTML : ""}
            <button style="margin:0px;" class="${buttonClass}" id="join-${safer(quiz.quizInfo.roomcode)}">${buttonText}</button>
        </div>
        <div>
            <div class="quiz-list-title">${safer(quiz.quizInfo.meta.topic)}</div>
            <div class="quiz-list-info">
                <span class="quiz-list-snippet">${safer(quiz.quizInfo.meta.question_count_requested)} questions.</span>                
                <span class="quiz-list-snippet">${quiz.participants.length > 0 ? '<i class="fa-solid fa-people-group fa-color-blue"></i> ' + (Object.keys(quiz.participants[0]).length - 2) : ''}</span>
                <br/>
            </div>
        </div>
    `;

        // Add event listener for joining the quiz
        quizElement.querySelectorAll(`#join-${safer(quiz.quizInfo.roomcode)}`).forEach(joinButton => {
            joinButton.addEventListener('click', () => {
                joinQuiz(quiz.quizInfo.roomcode);
            });
        });

        // Add event listener for editing the quiz
        quizElement.querySelectorAll(`#edit-${safer(quiz.quizInfo.roomcode)}`).forEach(editButton => {
            editButton.addEventListener('click', () => {
                editQuiz(quiz.quizInfo.roomcode); // Assuming quiz ID is same as room code
            });
        });


        quizElement.style.cursor = 'pointer';

        // Append the newly created element to the container
        container.appendChild(quizElement);
    });
}

let lastDocument = null;
let isFetching = false;
let allQuizzesLoaded = false;
let quizCache = [];  // Cache to store the quizzes

function handleScroll() {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 20 && !isFetching && !allQuizzesLoaded) {
        listQuiz(true, true);  // Continue fetching beyond cache
    }
}

async function listQuiz(e, isSubsequentFetch = false) {
    if (isFetching) return; // Avoid multiple concurrent fetches
    isFetching = true;

    // Add the scroll event listener only once during the initial call
    if (!isSubsequentFetch) {
        console.log('injecting list into history');

        history.pushState({ screen: 'screen-quiz-list' }, '', url.origin.toString()+'/list');

        showScreen('screen-quiz-list');

        window.addEventListener('scroll', handleScroll);

    }

    // Show the loader
    showElement('my-quiz-list-loader');

    await userLoaded;

    if (!isSubsequentFetch) {


        // Use cached quizzes if available for the initial call
        if (quizCache.length > 0) {
            createQuizList(quizCache);
        } else {
            // Fetch the first batch if no cache
            const firstBatch = await fetchQuizzesAndParticipants(thisUser.uid, 10, lastDocument);

            if (firstBatch.quizzes.length === 0) {
                showElement('my-quiz-list-empty');
                hideElement('my-quiz-list-loader');
                isFetching = false;
                return;
            }

            handleFetchedQuizzes(firstBatch);
        }
        // Check if additional data needs to be loaded initially
        if (quizCache.length < 19 && !allQuizzesLoaded) {
            const additionalBatch = await fetchQuizzesAndParticipants(thisUser.uid, 10, lastDocument);
            handleFetchedQuizzes(additionalBatch);
        }
    } else {
        // Subsequent fetch beyond the cache
        const fetchedData = await fetchQuizzesAndParticipants(thisUser.uid, 10, lastDocument);
        handleFetchedQuizzes(fetchedData);
    }

    // Hide the loader
    hideElement('my-quiz-list-loader');
    isFetching = false;


}


function handleFetchedQuizzes({ quizzes, lastDoc }) {
    if (quizzes.length > 0) {
        lastDocument = lastDoc; // Update the last document
        createQuizList(quizzes);
        quizCache = quizCache.concat(quizzes); // Cache the new quizzes

    } else {
        allQuizzesLoaded = true; // Mark that all quizzes have been loaded
        const message = document.createElement('div');
        message.textContent = "All quizzes loaded.";
        message.className = "end-of-quiz-list";
        document.getElementById('my-quiz-list').appendChild(message);

    }
}
// Global variable to store the quiz data
let quizData = null;

// Function to handle checkbox selection (radio button behavior)
window.handleCheckboxSelection = function (questionId, answerIndex) {
    // Find the correct question in the local data structure
    const question = quizData.questions.find(q => q.id === questionId);
    if (!question) return;

    // Unselect all other checkboxes for this question
    question.answers.forEach((answer, index) => {
        if (index !== answerIndex) {
            document.getElementById(`answer-${questionId}-${index}`).checked = false;
        }
    });

    // Select the clicked checkbox
    question.correct = question.answers[answerIndex]; // Update the correct answer
}

function markAsSeen(quiz_id) {
    // Quiz document exists, proceed with updating the "seen" and "seen_date" fields
    const seenDate = firebase.firestore.FieldValue.serverTimestamp();

    return db.collection("quizzes").doc(quiz_id).update({
        seen: thisUser.uid,
        seen_date: seenDate
    });
}

// Global variable to store quiz data

function editQuiz(quiz_id) {
    showScreen('screen-quiz-edit');

    history.pushState({ screen: 'screen-quiz-list' }, '', url.origin.toString()+'/edit/'+quiz_id);

    // Retrieve quiz details from Firebase using quiz_id
    db.collection("quizzes").doc(quiz_id).get()
        .then(function (doc) {
            if (doc.exists) {
                quizData = doc.data(); // Store quiz data in the global variable

                markAsSeen(quiz_id);

                question_id = quizData.questions.length;

                // Populate meta section
                const titleElement = document.getElementById("edit-quiz-title");
                titleElement.innerText = quizData.meta.topic;
                titleElement.dataset.editType = 'title';

                document.querySelector(".player-name-value").innerText = quizData.meta.owner_name;
                document.querySelector(".quiz-roomcode").innerText = quizData.meta.roomcode;

                // Populate questions section
                const questionsList = document.getElementById("edit-questions-list");
                questionsList.innerHTML = ""; // Clear previous content

                // Local representation of the quiz document
                const localQuizData = JSON.parse(JSON.stringify(quizData)); // Deep clone

                localQuizData.questions.forEach((question, questionIndex) => {
                    renderQuestion(question, questionIndex, questionsList, localQuizData, quiz_id);
                });

                // Add event listener to the title
                addTitleEventListeners(titleElement, localQuizData, quiz_id);

                // Update the global variable with the modified data
                quizData = localQuizData;

                // Show the quiz edit screen
                document.getElementById("screen-quiz-edit").classList.remove("hidden");
            } else {
                console.log("No such document!");
            }

            // Add click event listener to the box
            document.getElementById("edit-add-a-question").addEventListener('click', addNewQuestion);

            document.getElementById("edit-republish").addEventListener('click', () => {

                // We shouldn't be here and be connected to a quiz channel, so just setting one up..
                quizChannel = realtime.channels.get('quiz:' + quizData.meta.roomcode);

                initQuizChannel(quizData.meta.roomcode, quizData.meta.topic, quizData.questions.length, quizData.meta.difficulty, is_public, 1);
                sendQuestions(quizData.questions, 1);

                leaveChannel(quizChannel);
            });


        })
        .catch(function (error) {
            console.log("Error getting document:", error);
        });
}

function renderQuestion(question, questionIndex, questionsList, localQuizData, quiz_id, shouldFadeIn = false) {
    const questionDiv = document.createElement("div");
    questionDiv.classList.add("main-question-box");
    if (shouldFadeIn) {
        questionDiv.classList.add("fade-in"); // Add fade-in class only if shouldFadeIn is true
    }
    questionDiv.dataset.questionId = question.id;

    let answerOptionsHTML = "<ul class='options'>";
    question.answers.forEach((answer, answerIndex) => {
        const isChecked = question.correct == answer ? "checked" : "";
        const showOrNot = quiz_id.startsWith('V') && question.correct !== answer ?  'hidden' : '';
        answerOptionsHTML += `
            <li class="edit-answer ${showOrNot}">
                <input type="checkbox" class="input-checkbox ${ quiz_id.startsWith('V') ? 'hidden':'' }" id="answer-${question.id}-${answerIndex}" ${isChecked}>
                <span contenteditable="true" class="editable-option" data-question-id="${question.id}" data-answer-index="${answerIndex}">${answer}</span>
            </li>
        `;
    });
    answerOptionsHTML += "</ul>";

    const questionHTML = `
        <span class="little-title question-little-title">
            Question ${questionIndex + 1}
            <i class="fas fa-trash-alt trash-icon" data-question-id="${question.id}"></i>
        </span>
        <h4 contenteditable="true" class="editable-question" data-question-id="${question.id}">${question.question}</h4>
        ${answerOptionsHTML}
        <p><i>"<span contenteditable="true" class="editable-paragraph" data-question-id="${question.id}" placeholder="Comment">${question.comment}</span>"</i></p>
    `;

    questionDiv.innerHTML = questionHTML;
    questionsList.appendChild(questionDiv);

    // Add event listeners to the trash icon
    const trashIcon = questionDiv.querySelector('.trash-icon');
    trashIcon.addEventListener('click', function () {
        const questionId = this.dataset.questionId;
        removeQuestion(questionId);
    });

    // Add event listeners to checkboxes
    addCheckboxEventListeners(questionDiv, question.id, localQuizData, quiz_id);

    // Add event listeners to editable elements
    addEditableEventListeners(questionDiv, localQuizData, quiz_id);
}



function addCheckboxEventListeners(questionDiv, questionId, localQuizData, quiz_id) {
    const checkboxes = questionDiv.querySelectorAll('.input-checkbox');
    checkboxes.forEach((checkbox, answerIndex) => {
        checkbox.addEventListener('change', function () {
            handleCheckboxSelection(questionId, answerIndex);
            // Update local representation
            const question = localQuizData.questions.find(q => q.id === questionId);
            if (question) {
                question.correct = question.answers[answerIndex];
                saveToFirestore(quiz_id, localQuizData);
            }
        });
    });
}


function addEditableEventListeners(questionDiv, localQuizData, quiz_id) {
    const editableElements = questionDiv.querySelectorAll('.editable-option, .editable-question, .editable-paragraph');
    editableElements.forEach((element) => {
        element.addEventListener('keydown', function (e) {
            if (e.key === 'Enter') {
                e.preventDefault();
                this.blur();
            }
        });

        element.addEventListener('blur', function () {
            const questionId = this.dataset.questionId;
            const answerIndex = this.dataset.answerIndex;
            const originalValue = getOriginalValue(localQuizData, questionId, answerIndex, element);
            if (this.textContent !== originalValue) {
                updateLocalQuizData(localQuizData, questionId, answerIndex, element);
                saveToFirestore(quiz_id, localQuizData);
            }
        });
    });
}


function addTitleEventListeners(titleElement, localQuizData, quiz_id) {
    titleElement.addEventListener('blur', function () {
        if (this.innerText !== localQuizData.meta.topic) {
            localQuizData.meta.topic = this.innerText;
            saveToFirestore(quiz_id, localQuizData);
        }
    });

    titleElement.addEventListener('keydown', function (e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.blur();
        }
    });
}

// Function to remove a question
function removeQuestion(questionId) {

    // Find the correct question index in the local data structure
    const questionIndex = quizData.questions.findIndex(question => question.id === questionId);
    if (questionIndex === -1) return; // Question not found


    // Remove from local data structure
    quizData.questions.splice(questionIndex, 1);

    // Find the correct element in the DOM
    const questionsList = document.getElementById("edit-questions-list");
    const questionDiv = questionsList.querySelector(`.main-question-box[data-question-id="${questionId}"]`);

    if (questionDiv) {
        // Apply fade-out class
        questionDiv.classList.add('fade-out');

        // Wait for the animation to finish before removing the element
        questionDiv.addEventListener('animationend', function () {
            questionsList.removeChild(questionDiv);

            // Save the updated quizData to Firestore
            saveToFirestore(quizData.meta.roomcode, quizData);
        });
    }
}

// Other existing functions...

// Other existing functions...

function getOriginalValue(localQuizData, questionId, answerIndex, element) {
    const question = localQuizData.questions.find(q => q.id === questionId);
    if (!question) return null;

    if (element.classList.contains('editable-question')) {
        return question.question;
    } else if (element.classList.contains('editable-paragraph')) {
        return question.comment;
    } else if (element.classList.contains('editable-option')) {
        return question.answers[answerIndex];
    }
    return null;
}

function updateLocalQuizData(localQuizData, questionId, answerIndex, element) {
    const question = localQuizData.questions.find(q => q.id === questionId);
    if (!question) return;

    if (element.classList.contains('editable-question')) {
        question.question = element.textContent;
    } else if (element.classList.contains('editable-paragraph')) {
        question.comment = element.textContent;
    } else if (element.classList.contains('editable-option')) {
        question.answers[answerIndex] = element.textContent;
    }
}


function showSavingMessage(message) {
    const savingLabel = document.getElementById('saving-label');
    savingLabel.textContent = message;
    savingLabel.classList.add('show');
    savingLabel.classList.remove('hide');
}

function hideSavingMessage() {
    const savingLabel = document.getElementById('saving-label');
    savingLabel.classList.add('hide');
    // Remove the show class after the fade-out transition
    setTimeout(() => {
        savingLabel.classList.remove('show');
    }, 1000); // Match the duration of the fade-out transition
}

function saveToFirestore(quizId, localQuizData) {
    showSavingMessage('Saving...');

    localQuizData.timestamp = firebase.firestore.Timestamp.now();;

    db.collection("quizzes").doc(quizId).set(localQuizData)
        .then(function () {
            console.log("Document successfully updated!");
            hideSavingMessage();
        })
        .catch(function (error) {
            console.error("Error updating document: ", error);
            showSavingMessage('Saving failed...');
            setTimeout(hideSavingMessage, 2000); // Keep the error message visible for 2 seconds
        });
}

function isValidQuestion(question) {
    if (!question) return false;
    const requiredFields = ['question', 'answers', 'correct', 'comment', 'id'];
    for (const field of requiredFields) {
        if (!(field in question)) {
            return false;
        }
    }
    if (!Array.isArray(question.answers) || question.answers.length === 0) {
        return false;
    }
    return true;
}

// Function to get the next higher ID
function getNextHigherId(objects, prefix) {
    // Extract the numeric part and find the max value
    let maxId = -1;
    for (let obj of objects) {
      if (obj.id.startsWith(prefix + '-')) {
        const currentId = parseInt(obj.id.slice(prefix.length + 1));
        if (currentId > maxId) {
          maxId = currentId;
        }
      }
    }
  
    // Return the next higher ID
    return maxId === -1 ? null : `${prefix}-${maxId + 1}`;
  }


// Function to add a new question
async function addNewQuestion() {
    const newQuestion = await fetchAQuestion(quizData, 'awards'); // Call fetchAQuestion

    newQuestion.id = getNextHigherId(quizData.questions, quizData.meta.roomcode);

    if (!isValidQuestion(newQuestion)) {
        console.error('Fetched question is invalid or incomplete.');
        return;
    }

    // Update the local data structure
    quizData.questions.push(newQuestion);

    // Update the webpage with the new question
    const questionsList = document.getElementById("edit-questions-list");
    const questionIndex = quizData.questions.length + 1; // Get the index of the new question
    renderQuestion(newQuestion, questionIndex, questionsList, quizData, quizData.meta.roomcode, true);

    // Save the updated quizData to Firestore
    saveToFirestore(quizData.meta.roomcode, quizData);
}



function launchVoiceController() {
    return;

    console.log('launching voice controller');
    // Call the Netlify function to start listening
    fetch('/voice-controller-background', {
        method: 'POST',
        headers: {
            'x-channel-name': 'quiz:' + roomCode
        }
    })
        .then(response => response.json())
        .then(data => console.log('Netlify function started:', data))
        .catch(error => console.error('Error starting Netlify function:', error));
}



// function to show the quiz screen and start the quiz
async function joinQuiz(myRoomCode) {

    if (quizChannel) {
        await leaveChannel(quizChannel);
    }

    if (myRoomCode) {
        roomCode = myRoomCode;

    } else {
        const roomCodeInput = document.getElementById('room-code-input');
        roomCodeInput.value = roomCodeInput.value.toUpperCase();
        roomCodeInput.value = roomCodeInput.value.replace(/[^A-Z0-9]/g, '');
        roomCode = roomCodeInput.value;
    }

    updateJoinQuizUrlParameter('quizcode', roomCode);

    if (!roomCode) {
        Popup('No quiz code provided');
        return;
    }

    questions = [];
    is_owner = 0;
    scoreTracker = {};
    present = [];
    currentDetails = [];
    hiscore = {};

    showScreen('screen-wait-quiz');

    hideElement('owner-gone');

    chatConnect();

    document.querySelectorAll('.joined-list').forEach(el => {
        el.innerHTML = '';
    })

    document.getElementById('player-room-code').innerHTML = `<b>Quiz Code:    </b>${safer(roomCode)} [&#128279; <span id="copyLinkContainer"><A href="#" id="player-copy-link" style="cursor:pointer;text-decoration: underline; color: #0000EE">Copy Link</a></span>]`;
    document.getElementById('player-copy-link').addEventListener("click", copyLinkToClipboard);

    disableStartQuiz()

    if (roomCode.startsWith('A')) { // is async, check database first

        async = await getQuizData(roomCode);
        hiscore = await getDoc("participants", roomCode);
        let hiscoreHTML = generateHighScoreHTML(hiscore, thisUser.uid); // hiscoreList(hiscore);
        oldScores = reindexScores(hiscore);

        if (async.meta) { async.meta.loaded = roomCode; }
        else { async.meta = { "loaded": roomCode } }

        setTopic(async.meta.topic);
        updateMetaTags("Join a Qaiz about " + async.meta.topic);
        setWaitingBox({
            data: {
                owner: async.meta.owner_name,
                questioncount: async.questions.length,
                difficulty: async.meta.difficulty
            }
        });
        enableAsyncQuiz();

        document.getElementById('list-scores-list').innerHTML = hiscoreHTML;
        document.getElementById('list-scores-guest-list').innerHTML = hiscoreHTML;

        document.getElementById('start-quiz-wait-message').style.display = 'none';

        document.getElementById('list-players-guest').style.display = 'none';
        document.getElementById('list-scores-guest').style.display = 'block';

    }
    else {

        if (roomCode.startsWith('V')) {
            setupMicrophone();

            launchVoiceController();
        }

        document.getElementById('list-players').style.display = 'block';
        document.getElementById('list-players-guest').style.display = 'block';
        document.getElementById('start-quiz-wait-message').style.display = 'block';

        //        document.getElementById('header-sound-control').style.display = 'block';

    }

    connectToQuizChannel(roomCode);
}


//
// Basically reset to beginning
//
async function newQuiz() {

    if (questionTimeout) {

        clearTimeoutWorker(questionTimeout);
        questionTimeout = null;

    }

//    removeAllUrlParams();
    history.pushState({ screen: 'screen-create-quiz'}, '', url.origin.toString()+'/create');

    questions = [];
    currentQuiz = { ...emptyQuiz };
    scoreTracker = {};
    currentQuestion = [];
    currentDetails = [];
    current_owner = null;
    is_owner = 0;


    //   hideElement('missing-owner')
    enableButton('create-quiz');
    // disableButton('start-quiz');
    disableStartQuiz();
    showScreen('screen-create-quiz');

    await leaveChannel(quizChannel);


}

function setupOptionSelectors(containerSelector, inputId) {

    document.querySelectorAll(containerSelector + ' .option').forEach(option => {

        option.addEventListener('click', function () {
            // Remove 'selected' class from options in the same container
            document.querySelectorAll(containerSelector + ' .option').forEach(opt => {
                opt.classList.remove('selected');
            });

            // Add 'selected' class to clicked option
            this.classList.add('selected');

            // Update the corresponding hidden input value
            document.getElementById(inputId).value = this.getAttribute('data-value');

            calcCurrentCredit();
        });
    });
}

function clearAllCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
}

function chatConnect() {

    return;

    chatroom = joinRoom(trystero_config, 'quiz-' + roomCode);

    [sendName, getName] = chatroom.makeAction('name')

    // listen for peers naming themselves
    getName((name, peerId) => {
        idsToNames[peerId] = name
    });

}



async function soundOn() {
    return;

    try {
        if (!chatroom) {
            chatConnect();
        }

        // get a local audio stream from the microphone
        selfStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: false
        })

        await chatroom.addStream(selfStream);

        await chatroom.onPeerJoin(peerId => {
            chatroom.addStream(selfStream, peerId);
            // Add more lines of code here

            sendName(username);

            // You can add more logic here as needed
        });

        // handle streams from other peers
        await chatroom.onPeerStream((stream, peerId) => {
            // create an audio instance and set the incoming stream
            const audio = new Audio()
            audio.srcObject = stream
            audio.autoplay = true

            // add the audio to peerAudio object if you want to address it for something
            // later (volume, etc.)
            peerAudios[peerId] = audio;

        });

        document.querySelectorAll('.sound-control').forEach(element => {
            element.innerHTML = '<i class="fa-solid fa-volume-high"></i>';
        });

        sendSoundOn();
        soundState = 1;
    }
    catch (error) {
        Popup("Can't connect to audio... check your mic settings.");
        console.log(error);
    }
}

async function soundOff() {
    return;

    try {
        if (chatroom) {
            await chatroom.leave();
            chatroom = undefined;
        }

        selfStream.getTracks().forEach(track => track.stop());

        peerAudios = {};

        document.querySelectorAll('.sound-control').forEach(element => {
            element.innerHTML = '<i class="fa-solid fa-volume-xmark"></i>';
        });

        soundState = 0;
        sendSoundOff();

    }
    catch {
        console.log('NOTE: Something went wrong turning off sound')
    }

}



async function flipSound() {
    return;

    if (soundState == 0) {

        soundOn();
    }

    else if (soundState == 1) {
        soundOff();

    }
}

async function setupButtons() {

    var questionCount = getCookie("question-count");
    if (questionCount) {
        //      document.getElementById("question-count").value = questionCount;
    }
    // Update the cookie when the dropdown value changes
    document.getElementById("question-count").addEventListener("change", function () {
        //        setCookie("question-count", this.value, 7); // Store for 7 days
    });

    var difficulty = getCookie("difficulty");
    if (difficulty) {
        //      document.getElementById("difficulty-level").value = difficulty;
    }
    // Update the cookie when the dropdown value changes
    document.getElementById("difficulty-level").addEventListener("change", function () {
        //      setCookie("difficulty", this.value, 7); // Store for 7 days
    });


    document.querySelectorAll('.new-quiz').forEach(button => {
        button.addEventListener('click', newQuiz);
    });

    /*
    document.querySelectorAll('.sound-control').forEach(button => {
        button.addEventListener('click', flipSound);
    });
*/

    setupOptionSelectors('#difficulty-options', 'difficulty-level');
    setupOptionSelectors('#question-count-options', 'question-count');
    // setupOptionSelectors('#public-options', 'make-public');
    setupOptionSelectors('#type-options', 'quiz-type');
    setupOptionSelectors('#style-options', 'avatar-style');
    setupOptionSelectors('#avatar-type-options', 'avatar-type');

    document.getElementById('q-up').addEventListener('click', engageUp);
    document.getElementById('q-bad').addEventListener('click', engageBad);
    document.getElementById('start-quiz').addEventListener('click', function () {
        // Check if the element does NOT have the 'button-disabled' class
        if (!this.classList.contains('button-disabled')) {
            startQuiz();
        }
    });


    document.getElementById('option-help-type').addEventListener('click', () => {
        const helpType = document.getElementById('help-type');
        helpType.style.display = getComputedStyle(helpType).display === 'none' ? 'block' : 'none';
    });

    document.getElementById('see-scoreboard').addEventListener('click', function () {
        showElementFlex('quiz-summary-modal');
    });

    document.getElementById('start-quiz-guest').addEventListener('click', function () {
        // Check if the element does NOT have the 'button-disabled' class
        if (!this.classList.contains('button-disabled')) {
            startQuiz(1);
        }
    });

    document.getElementById('start-async-quiz').addEventListener('click', function () {
        // Check if the element does NOT have the 'button-disabled' class
        if (!this.classList.contains('button-disabled')) {
            startAsyncQuiz();
        }
    });

    document.getElementById('start-voice-quiz').addEventListener('click', function () {
        // Check if the element does NOT have the 'button-disabled' class
        if (!this.classList.contains('button-disabled')) {
            startVoiceQuiz();
        }
    });
    document.getElementById('start-voice-quiz-guest').addEventListener('click', function () {
        // Check if the element does NOT have the 'button-disabled' class
        if (!this.classList.contains('button-disabled')) {
            startVoiceQuiz(1);
        }
    });

    document.getElementById('start-async-quiz-guest').addEventListener('click', function () {
        // Check if the element does NOT have the 'button-disabled' class
        if (!this.classList.contains('button-disabled')) {
            startAsyncQuiz(1);
        }
    });

    document.getElementById('start-voice-quiz-guest').addEventListener('click', function () {
        // Check if the element does NOT have the 'button-disabled' class
        if (!this.classList.contains('button-disabled')) {
            startVoiceQuiz(1);
        }
    });

    document.getElementById('qotd-play').addEventListener('click', startQotdQuiz);
    document.getElementById('qotd-news-play').addEventListener('click', startQotdNewsQuiz);

    document.getElementById('create-quiz-sub').addEventListener('click', function () {
        showScreen('screen-subscribe');
    });

    document.querySelectorAll('.footer-link-sub').forEach(function(element) {
        element.addEventListener('click', function() {
            showScreen('screen-subscribe');
        });
    });
    

    document.getElementById('ad-link-sub').addEventListener('click', function () {
        showScreen('screen-subscribe');
    });

    document.getElementById('create-quiz').addEventListener('click', createQuiz);

    document.getElementById('create-the-avatar').addEventListener('click', createAvatar);

    document.querySelectorAll('.manage-sub').forEach(element => {
        element.addEventListener('click', () => {
            document.getElementById('manage-sub-modal').style.display = 'none';

            window.open('https://billing.stripe.com/p/login/6oE4hW06Z9SogiQbII', '_blank');
        });
    });

    document.querySelectorAll('.auth-signout').forEach(element => {
        element.addEventListener('click', () => {
            document.getElementById('username-modal').style.display = 'none';
            document.getElementById('channel-modal').style.display = 'none';
            document.getElementById('login-block-modal').style.display = 'none';
            document.getElementById('anonymous-block-modal').style.display = 'none';
            clearAllCookies();

            showScreen('screen-create-quiz');

            // Sign out from Firebase Auth
            firebase.auth().signOut().then(() => {
                // Sign-out successful, reload the page
                window.location.reload();
            }).catch((error) => {
                // An error happened during logout
                console.error("Logout Error:", error);
            });
        });
    });

    document.getElementById('family-room-link').addEventListener('click', function () {
        event.preventDefault(); // Prevent the default anchor behavior
        document.getElementById('channel-modal').style.display = 'flex';
    });

    document.querySelectorAll('.the-enter-button').forEach(button => {
        button.addEventListener('click', () => {
            document.getElementById('username-modal').style.display = 'none';
            document.getElementById('login-block-modal').style.display = 'flex';
            gtag('event', 'login-attempt', {});
        });
    });

    document.getElementById('fileInput').addEventListener('change', function (event) {

        const file = event.target.files[0];
        uploadFile(file);

    });

    // Add click event to elements with class 'player-name-edit'
    document.querySelectorAll('.player-name-edit').forEach(element => {
        element.addEventListener('click', function () {
            document.getElementById('username-modal').style.display = 'flex';
        });
    });

    // Add click event to elements with class 'player-award-edit'
    document.querySelectorAll('.player-award-edit').forEach(element => {
        element.addEventListener('click', function () {
            chosenAward = thisUser.award;
            document.getElementById('award-modal').style.display = 'flex';
        });
    });

    // Add click event to elements with class 'player-name-edit'
    document.querySelectorAll('.click-quiz-list').forEach(element => {
        element.addEventListener('click', listQuiz);
    });


    document.querySelectorAll('.close').forEach(function (element) {
        element.addEventListener('click', closeAllModals);
    });

    const joinQuizButton = document.getElementById('join-quiz');
    joinQuizButton.addEventListener('click', () => joinQuiz(0));


    setupAnonymous();

    // quizLogo.addEventListener('click',  newQuiz);
}

///
//
// Document events
//
//

// File drag drop handling:

// Prevent default drag behaviors
['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
    document.addEventListener(eventName, preventDefaults, false);
})

function preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
}

// Show or hide the overlay
['dragenter', 'dragover'].forEach(eventName => {
    document.addEventListener(eventName, () => document.getElementById('upload-overlay').style.display = 'block', false);
});

['dragleave', 'drop'].forEach(eventName => {
    document.addEventListener(eventName, () => document.getElementById('upload-overlay').style.display = 'none', false);
});

// Handle dropped files
document.addEventListener('drop', handleDrop, false);

function handleDrop(e) {

    let dt = e.dataTransfer;
    let files = dt.files;

    handleFiles(files);
    document.getElementById('upload-overlay').style.display = 'none'; // Hide overlay when files are dropped
}

// Process files
function handleFiles(files) {

    ([...files]).forEach(uploadFile);
}

//
// This is the handling of the uplaoded file - it needs to handle a lot of stuff.
//
function compressImage(file, maxWidth, quality, callback) {
    if (!file.type.startsWith('image')) {
        console.error('The provided file is not an image.');
        return;
    }

    const reader = new FileReader();
    reader.onload = function (event) {
        const img = new Image();
        img.onload = function () {
            // Create a canvas and get its context
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Calculate the new dimensions based on maxWidth
            const scaleRatio = maxWidth / img.width;
            canvas.width = maxWidth;
            canvas.height = img.height * scaleRatio;

            // Draw the resized image
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Get the compressed image data
            const dataUrl = canvas.toDataURL('image/jpeg', quality);

            // Execute callback with the result
            callback(dataUrl);
        };
        img.src = event.target.result;
    };
    reader.onerror = function (error) {
        console.error('Error occurred reading the image file:', error);
    };
    reader.readAsDataURL(file);
}


function extractText(file) {
    return new Promise((resolve, reject) => {
        const fileType = file.type;
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (fileType === 'image/webp' || fileType === 'image/jpeg' || fileType === 'image/png' || fileType === 'image/gif') {
            // Compress the image before resolving
            compressImage(file, 1400, 0.3, (compressedDataUrl) => {
                resolve(compressedDataUrl);
            });
        }
        else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            fileType === 'application/msword' ||
            fileExtension === 'docx' ||
            fileExtension === 'odt') {
            mammoth.extractRawText({ arrayBuffer: file })
                .then(result => resolve(result.value))
                .catch(error => reject(new Error(error.message)));
        }
        else if (fileType === 'application/pdf') {
            const reader = new FileReader();
            reader.onload = function (e) {
                try {
                    const pdfData = new Uint8Array(e.target.result);
                    pdfjsLib.getDocument({ data: pdfData }).promise
                        .then(pdf => {
                            const numPages = pdf.numPages;
                            let extractedText = '';

                            const extractPageText = pageNum => pdf.getPage(pageNum).then(page =>
                                page.getTextContent().then(textContent =>
                                    textContent.items.map(item => item.str).join(' ')
                                )
                            );

                            const extractTextFromPages = async () => {
                                for (let i = 1; i <= numPages; i++) {
                                    extractedText += await extractPageText(i) + '\n';
                                }
                                resolve(extractedText);
                            };

                            extractTextFromPages();
                        })
                        .catch(error => reject(new Error(error.message)));
                } catch (error) {
                    reject(new Error(error.message));
                }
            };
            reader.onerror = () => reject(new Error("Error reading file"));
            reader.readAsArrayBuffer(file);
        } else if (fileType === 'text/plain' || fileExtension === 'txt') {
            const reader = new FileReader();
            reader.onload = function (e) {
                resolve(e.target.result);
            };
            reader.onerror = () => reject(new Error("Error reading text file"));
            reader.readAsText(file);
        } else if (fileType === 'application/rtf' || fileExtension === 'rtf') {
            const reader = new FileReader();
            reader.onload = function (e) {
                try {
                    const extractedText = extractTextFromRTF(e.target.result);
                    resolve(extractedText);
                } catch (error) {
                    reject(new Error(error.message));
                }
            };
            reader.onerror = () => reject(new Error("Error reading RTF file"));
            reader.readAsText(file);
        } else {
            const reader = new FileReader();
            reader.onload = function (e) {
                const content = e.target.result;
                if (isLikelyText(content)) {
                    resolve(content);
                } else {
                    reject(new Error("Unsupported file type. Qaiz supports .webp, .jpeg/jpg, .png, .gif, .docx, .pdf, .rtf, and plain text."));
                }
            };
            reader.onerror = () => reject(new Error("Error reading file"));
            reader.readAsText(file.slice(0, 1024));  // Check only the first 1KB
        }
    });
}

function isLikelyText(str) {
    // A simple heuristic to check if a string is likely text
    return !/[\x00-\x08\x0E-\x1F]/.test(str); // Regex to detect non-printable characters typically not found in text files
}

function extractTextFromRTF(rtfString) {
    // Remove RTF header, control words, and groups
    let plainText = rtfString.replace(/\\pard/g, ' ')  // Attempts to preserve paragraphs
        .replace(/{\\[^{}]+}|\\[a-z]+\s|-|\\'3f/gi, '')
        .replace(/{|}/g, '')
        .trim();

    return plainText;
}

function uploadFeedback(feedback) {
    const feedbackEl = document.getElementById('upload-feedback');
    feedbackEl.style.display = 'block';
    feedbackEl.textContent = feedback;

    const ideaEl = document.getElementById('quiz-ideas');
    ideaEl.style.display = 'none';

}

function uploadFeedbackReset() {
    const feedbackEl = document.getElementById('upload-feedback');
    feedbackEl.style.display = 'none';
    feedbackEl.textContent = '';

    const ideaEl = document.getElementById('quiz-ideas');
    ideaEl.style.display = 'block';
}

function bytesToKB(bytes) {
    const kb = bytes / 1024;
    return `${kb.toFixed(2)} KB`;  // Rounds to two decimal places and appends 'KB'
}

function uploadFile(file) {
    clearUpload();

    let reader = new FileReader();
    uploadFeedbackReset();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
        extractText(file)
            .then(text => {
                extractedText = text;

                if (text.length > 200000 & !file.type.startsWith('image/')) {
                    uploadFeedback('Pro-tip: upload a smaller file. Qaiz will only use the first 50,000 or so words.');
                    extractedText = extractedText.slice(0, 200000);

                }
                if (text.length > 230000) {
                    uploadFeedback(`Our size limit is currently 230 kb. Can you scale this down a bit?`);
                    extractedText = '';
                }
                if (text.length / 4 < 600) {
                    uploadFeedback('Pro-tip: This document might be too short for a good quiz.');
                }

                extractedFilename = file.name;

                if (file.type.startsWith('image')) {
                    extractedFiletype = 'image/jpeg'; // file.type; // Extracted file is always jpg. IF IMAGE!!
                }

                calcCurrentCredit();
            })
            .catch(error => {
                console.log(error);
                uploadFeedback(error);
            });

        // Update the UI after a file is uploaded
        document.getElementById('topic-input').style.display = 'none';

        const filenameDisplay = document.getElementById('filename-display');
        filenameDisplay.style.display = 'block';

        const filenameDisplayInside = document.getElementById('filename-display-inside');
        filenameDisplayInside.innerHTML = '<span class="binder">&#128206; </span>'; // Set emoji in a span
        filenameDisplayInside.appendChild(document.createTextNode(file.name)); // Append filename as text node

        // Handle toggling back to the input on click
        document.getElementById('filename-display').addEventListener('click', function () {
            clearUpload();

        });

    }
}

//
// Closes all elements that end with -modal
//

function closeAllModals() {
    document.querySelectorAll('[id$="-modal"]').forEach(function (modal) {
        modal.style.display = 'none';

    });
}

function setDefaultScreens() {
    const divsStartingWithScreen = document.querySelectorAll("div[id^='screen-']");

    divsStartingWithScreen.forEach(function (div) {
        const originalContent = div.innerHTML;
        div.dataset.originalContent = originalContent;
    });
}


//
// DOMContentLoaded
//
document.addEventListener('DOMContentLoaded', async () => {

    qotdDataPromise.then(data => {
        qotd = data;

        document.getElementById('qotd-topic').textContent = qotd.meta.topic;
    });

    qotdNewsDataPromise.then(data => {
        qotdnews = data;

        document.getElementById('qotd-news-date').textContent = qotdnews.meta.generated;
    });

    if (queryParams.has('qotd') || window.location.pathname === '/qotd') {
        const qotdCard = document.getElementById('qotd-card');
        const quizContainer = document.getElementById('screen-create-quiz');

        // Move the qotdCard to the top of quizContainer
        if (quizContainer && qotdCard) {
            quizContainer.insertBefore(qotdCard, quizContainer.firstChild);
        }
    }

    if (queryParams.has('news') || window.location.pathname === '/news') {
        const qotdCard = document.getElementById('qotd-news-card');
        const quizContainer = document.getElementById('screen-create-quiz');

        // Move the qotdCard to the top of quizContainer
        if (quizContainer && qotdCard) {
            quizContainer.insertBefore(qotdCard, quizContainer.firstChild);
        }
    }



    // Store the original content for all divs with IDs starting with "screen-" when the document is loaded
    const divsStartingWithScreen = document.querySelectorAll("div[id^='screen-']");

    loaderOff();

    divsStartingWithScreen.forEach(function (div) {
        const originalContent = div.innerHTML;
        div.dataset.originalContent = originalContent;
    });

    if (loadCode) {
        showScreen('screen-wait-quiz');
        history.pushState({ screen: 'screen-wait-quiz', roomCode: loadCode}, '', url.origin.toString()+'/q/'+loadCode);

    }
    else {
        showScreen('screen-create-quiz');
        history.pushState({ screen: 'screen-create-quiz'}, '', url.origin.toString()+'/create');

    }

    loaderOn();

    document.getElementById('credit-box').addEventListener('click', function () {
        document.getElementById('manage-sub-modal').style.display = 'flex';
    });


    document.getElementById('subscribe-box').addEventListener('click', function () {
        showScreen('screen-subscribe');
    });

    document.getElementById('link-faq').addEventListener('click', function () {
        showScreen('screen-faq');
    });



    document.getElementById('topic-input').addEventListener('focus', function () {
        // Remove the border style by setting it to 'none' or resetting it
        this.style.boxShadow = 'none'; // or use '' to reset to default
    });

//    document.getElementById('exit-intro').addEventListener('click', async function () {
//
  //      document.body.classList.remove('loading'); // Remove 'loading' class to hide overlay
    // });

//    document.getElementById('loadScreen').addEventListener('click', async function () {

  //      document.body.classList.remove('loading'); // Remove 'loading' class to hide overlay
    //});

    document.getElementById('save-details').addEventListener('click', async function () {
        var newUsername = document.getElementById('new-playername').value;

        gtag('event', 'save_details', {});

        let relogin = newUsername == username ? 0 : 1;

        if (newUsername) {
            document.getElementById('username-modal').style.display = 'none';
            sendEngage(`${username} is now ${newUsername}`);
            username = newUsername;

            setCookie('player-name', username, 365); // Expires in 365 days (adjust as needed)

            if (!thisUser.anonymous) {
                var user = firebase.auth().currentUser;
                user.updateProfile({
                    displayName: username
                });
            }

            if (quizChannel) {
                quizChannel.presence.leave({ nametag: username, awardtag: thisUser.award, award: thisUser.award, avatarUrl: userAvatarUrl });
                quizChannel.presence.enter({ nametag: username, awardtag: thisUser.award, award: thisUser.award, avatarUrl: userAvatarUrl });
            }
            if (livingRoomChannel) {
                livingRoomChannel.presence.leave({ nametag: username, awardtag: thisUser.award, award: thisUser.award, avatarUrl: userAvatarUrl });
                livingRoomChannel.presence.enter({ nametag: username, awardtag: thisUser.award, award: thisUser.award, avatarUrl: userAvatarUrl });
            }

            if (relogin) {
                //   window.location.reload();
            }

            updateDetails();

        } else {
            alert("Please enter a user name.");
        }

    });

    document.getElementById('save-channel').addEventListener('click', async function () {

        let newFamilyRoomName = document.getElementById('new-familyroom').value;

        if (familyRoomName != newFamilyRoomName) {

            familyRoomName = newFamilyRoomName.trim();

            // This could/shoudl be done in updatePublic but I want to control the timing and
            // avoid flicker

            document.getElementById('family-rooms').innerHTML = '';
            document.getElementById('family-rooms-none').style.display = 'block';


            if (thisUser.anonymous) {
                setCookie('family-room', familyRoomName, 365); // Expires in 365 days (adjust as needed)
            } else {
                storeUserData({ FamilyRoom: familyRoomName });
            }

            familyHistory = { "meta": { "listId": "family-rooms" } };
            updateFamilyRoomName();

            await leaveChannel(livingRoomChannel);
            livingRoomChannel = await connectToChannel('room:' + familyRoomName, familyHistory);

            updatePublic(familyHistory);

        }

        document.getElementById('channel-modal').style.display = 'none';
        document.getElementById('username-modal').style.display = 'none';
        document.getElementById('login-block-modal').style.display = 'none';
        document.getElementById('anonymous-block-modal').style.display = 'none';

    });

    document.querySelectorAll('.channel-link').forEach(link => {
        link.addEventListener('click', function (event) {
            event.preventDefault(); // Prevent the default link behavior
            const suggestion = this.textContent;
            document.getElementById('new-familyroom').value = suggestion; // Set the input field value
        });
    });

});

document.addEventListener('DOMContentLoaded', function () {
    const quizIdeas = document.getElementById('quiz-ideas');

    let isDown = false;
    let startX;
    let scrollLeft;

    quizIdeas.addEventListener('mousedown', (e) => {
        isDown = true;
        quizIdeas.classList.add('active');
        startX = e.pageX - quizIdeas.offsetLeft;
        scrollLeft = quizIdeas.scrollLeft;
    });

    quizIdeas.addEventListener('mouseleave', () => {
        isDown = false;
        quizIdeas.classList.remove('active');
    });

    quizIdeas.addEventListener('mouseup', () => {
        isDown = false;
        quizIdeas.classList.remove('active');
    });

    quizIdeas.addEventListener('mousemove', (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - quizIdeas.offsetLeft;
        const walk = (x - startX) * 2; // Adjust the scroll speed
        quizIdeas.scrollLeft = scrollLeft - walk;
    });
});

